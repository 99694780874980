angular.module('config-service', []).service('configService', [
  '$http',
  '$q',
  function ($http, $q) {
    var configDeferred = null;

    function getConfig() {
      if (!configDeferred) {
        configDeferred = $q.defer();

        $http
          .get('/config/config.json')
          .success(function (data, status, headers, config) {
            configDeferred.resolve(data);
          })
          .error(function (data, status, headers, config) {
            configDeferred.reject('Failed to get config. Response: ' + JSON.stringify(data));
          });
      }

      return configDeferred.promise;
    }

    function reset() {
      configDeferred = null;
    }

    return {
      getConfig: getConfig,
      reset: reset,
    };
  },
]);
