import React from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import CmsItemEditSection from '../../shared/cmsPage/components/CmsItemEditSection';
import { useEditPageTitle } from '../../shared/cmsPage/hooks/useEditPageTitle';
import { CollectionEditorConstants } from '../definitions';
import CollectionItemEditor from './CollectionItemEditor';

interface CollectionItemEditorPageProps {
  collectionId: string;
  itemId: string;
}

const CollectionItemEditorPage: React.FC<CollectionItemEditorPageProps> = (
  props: CollectionItemEditorPageProps
) => {
  const { editorPageId } = CollectionEditorConstants;
  const { collectionId, itemId } = props;

  const { pageTitle, onItemChange } = useEditPageTitle(itemId, 'Collection Item', 'label');
  return (
    <CmsPage title={pageTitle}>
      <CmsItemEditSection pageId={editorPageId}>
        <CollectionItemEditor
          collectionId={collectionId}
          itemId={itemId}
          onItemChange={onItemChange}
          growlId={editorPageId}
        />
      </CmsItemEditSection>
    </CmsPage>
  );
};

export default CollectionItemEditorPage;
