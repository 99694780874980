import { savePageState, setRestoreOnTransition } from '../shared/cmsPage/cmsPageUtils';

export class CmsNavigator {
  constructor(private navigateToState: (state: string, params: Object) => void) {}

  navigateToParentPage(
    pluginName: string,
    pageName: string,
    parentPageId: string,
    ...args: string[]
  ) {
    setRestoreOnTransition(parentPageId);
    this.navigateToPluginPage(pluginName, pageName, ...args);
  }

  navigateToChildPage<T extends object>(
    pluginName: string,
    pageName: string,
    parentPageId: string,
    savedParams: T,
    ...args: string[]
  ) {
    savePageState(parentPageId, savedParams);
    this.navigateToPluginPage(pluginName, pageName, ...args);
  }

  navigateToPluginPage(pluginName: string, pageName: string, ...args: string[]) {
    this.navigateToState('main.plugin', {
      pluginName,
      pageName,
      params: args && args.join(','),
    });
  }
}
