import { useEffect } from 'react';
import { SpinnerProvider } from '../definitions';

export const useSpinner = (spinnerProvider: SpinnerProvider, showSpinner: boolean) => {
  useEffect(() => {
    if (showSpinner) {
      const { add, dismiss } = spinnerProvider;
      const id = '' + Date.now();

      add(id);
      return () => dismiss(id);
    }
  }, [spinnerProvider, showSpinner]);
};
