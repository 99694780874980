import { useCallback, useState } from 'react';
import { PaginationState, EMPTY_SORT, SortState } from '../../itemTable/definitions';
import { ListLoader, ListLoaderParams, ListLoaderResult } from '../definitions';
import { Item } from '../../form/definitions';

export const useListLoader = <T extends Item>(listLoader: ListLoader<T>) => {
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([] as T[]);
  const [pagination, setPagination] = useState({ pageCount: 1, currentPage: 1 } as PaginationState);
  const [sort, setSort] = useState(EMPTY_SORT as SortState<T>);

  const loadItems = useCallback(
    async (params: ListLoaderParams<T>) => {
      const { limit } = params;
      setLoading(true);

      try {
        const { records, offset, numRecords }: ListLoaderResult<T> = await listLoader(params);
        const pageCount = Math.ceil(numRecords / limit);

        setRecords(records);
        setPagination({ pageCount, currentPage: offset / limit + 1 });
        setSort(params.sort);
      } catch (e) {
        setRecords([]);
        setPagination({ pageCount: 1, currentPage: 1 });
        setSort(EMPTY_SORT);

        throw e;
      } finally {
        setLoading(false);
      }
    },
    [listLoader, setLoading, setRecords, setPagination, setSort]
  );

  return {
    loadItems,
    loading,
    records,
    pagination,
    sort,
  };
};
