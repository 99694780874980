import React from 'react';
import Alert from 'react-bootstrap/Alert';
import { ConditionalAlertProps } from './definitions';

export default function ConditionalAlert(props: ConditionalAlertProps) {
  const { condition, message, alertType } = props;
  return (
    condition && (
      <Alert style={{ opacity: 1 }} variant={alertType}>
        {message}
      </Alert>
    )
  );
}
