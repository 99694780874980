(function () {
  angular
    .module('create-user', ['user-api-service', 'angularjs-dropdown-multiselect'])
    .controller('EditUserCtrl', [
      '$scope',
      '$log',
      '$timeout',
      'growl',
      'userApiService',
      'confirmDialog',
      'messageService',
      function ($scope, $log, $timeout, growl, userApiService, confirmDialog, messageService) {
        $scope.user = $scope.user ? angular.copy($scope.user) : {};
        $scope.modeLabel = $scope.replaceMode ? 'Edit' : 'Add';

        $scope.originalSubtenantId = $scope.user.subtenantId;

        $scope.groupSelectSettings = {
          displayProp: 'name',
          enableSearch: true,
          searchField: 'name',
          template:
            '<span ng-class="{disabled: !option.canAssign, fromDefault: option.isDefault}">{{option.name}}</span>',
        };

        $scope.selectionData = {
          selectedGroups: [],
        };

        $scope.hiddenUserGroups = [];

        messageService.getMessage('cms.subtenant.singular').then(function (subtenantLabel) {
          $scope.subtenantLabel = subtenantLabel;
        });

        $scope.subtenants = _.values($scope.selectedTenant.subtenants);

        $scope.showSubtenants =
          $scope.subtenants && $scope.subtenants.length > 1 && !!$scope.user.subtenantId;

        userApiService.getUserConfig().then(function (userConfig) {
          $scope.types = getVisibleTypes(userConfig.userTypes, $scope.selectedSubtenant);
          $scope.showAccessCode = !!userConfig.editableAccessCode || $scope.replaceMode;
          $scope.accessCodeIsReadOnly = !userConfig.editableAccessCode;
          $scope.showEmail = !!userConfig.captureUserEmail;
          $scope.showSendInviteOption =
            $scope.showEmail && !!userConfig.inviteEmailEnabled && !$scope.replaceMode;
          $scope.sendInvite = { selected: $scope.showSendInviteOption };
          $scope.groupsChanged = false;
          $scope.userGroups = (userConfig.userGroups || [])
            .filter(function (userGroup) {
              return userGroup.canAssign;
            })
            .map(function (userGroup) {
              return _.assign({}, userGroup, {
                disabled: !userGroup.canAssign,
              });
            })
            .sort(function (group1, group2) {
              if (group1.name < group2.name) {
                return -1;
              } else if (group1.name > group2.name) {
                return 1;
              } else {
                return 0;
              }
            });

          $scope.userGroupMap = {};
          _.forEach($scope.userGroups, function (userGroup) {
            $scope.userGroupMap[userGroup.id] = userGroup;
          });

          if ($scope.replaceMode) {
            setSelectedUserGroups();
          } else {
            if ($scope.types.length > 0) {
              var defaultUserType = getFirstSelectableUserType($scope.types) || $scope.types[0];
              $scope.user.userType = defaultUserType.value;
            }
          }

          $scope.showUserTypes = $scope.types.length > 1;
        });

        $scope.cancel = function () {
          $scope.$dismiss();
        };

        $scope.save = function () {
          if (changingSubtenant()) {
            confirmDialog({
              title: 'Changing ' + $scope.subtenantLabel,
              body:
                "Are you sure you want to change this user's assigned " +
                $scope.subtenantLabel +
                '?  This change may affect the groups to which the user belongs as well as the content available to him/her within the app.',
              confirmText: 'Yes',
              cancelText: 'No',
            }).result.then(function (result) {
              doSave();
            });
          } else {
            doSave();
          }

          function doSave() {
            $scope.user.userGroups = $scope.selectionData.selectedGroups.concat(
              $scope.hiddenUserGroups
            );

            userApiService
              .saveUser($scope.user)
              .success(function (data, status, headers, config) {
                if (data.status === 'error') {
                  growl.error(data.error);
                } else {
                  // This logic should be done server side, not in the browser.
                  if ($scope.sendInvite.selected && !$scope.replaceMode) {
                    $scope.user = data.appUser;
                    $scope.sendInviteEmail();
                  }

                  $scope.$close(data);
                }
              })
              .error(function (err, status, headers, config) {
                growl.error(err.error || 'User upload failed.  Please try again later.');
              });
          }
        };

        function changingSubtenant() {
          return (
            !!$scope.originalSubtenantId && $scope.user.subtenantId !== $scope.originalSubtenantId
          );
        }

        $scope.onGroupsChanged = function () {
          $scope.groupsChanged = true;
        };

        $scope.sendInviteEmail = function () {
          userApiService
            .sendInviteEmail($scope.user)
            .success(function (data, status, headers, config) {
              growl.success('Invitation email sent to ' + $scope.user.email);
            })
            .error(function (err, status, headers, config) {
              var errMsg =
                err && err.error ? 'Error details: ' + err.error : 'Status code: ' + status;
              growl.error('Failed to send invitation email to ' + $scope.user.email);
            });
        };

        function setSelectedUserGroups() {
          var user = $scope.user;

          _.forEach(user.userGroups, function (userGroup) {
            if (!$scope.userGroupMap[userGroup.id]) {
              $scope.hiddenUserGroups.push(userGroup);
            } else {
              $scope.selectionData.selectedGroups.push(userGroup);
            }
          });
        }

        function getFirstSelectableUserType(userTypes) {
          for (var i = 0; i < userTypes.length; i++) {
            var userType = userTypes[i];
            if (userType.selectable !== false) {
              return userType;
            }
          }

          return null;
        }

        function getVisibleTypes(userTypes, selectedSubtenant) {
          var result = [];

          if (userTypes) {
            for (var i = 0; i < userTypes.length; i++) {
              var type = userTypes[i];
              var subtenantAvailability = type.subtenantAvailability || 'default';
              var available =
                subtenantAvailability === 'both' ||
                (subtenantAvailability === 'default' && selectedSubtenant.isDefault) ||
                (subtenantAvailability === 'nonDefault' && !selectedSubtenant.isDefault);
              if (type.selectable !== false && available) {
                result.push(type);
              }
            }
          }

          return result;
        }
      },
    ]);
})();
