import React, { useCallback } from 'react';
import Select from '../../shared/form/components/Select';
import { FieldPath, UseFormReturn } from 'react-hook-form';
import { Item } from '../../shared/form/definitions';
import menuItemApi from '../api/menuItemApi';

interface IconSelectorProps<T extends Item> {
  form: UseFormReturn<T>;
  label: string;
  fieldName: FieldPath<T>;
  required?: boolean;
}

const IconSelector = <T extends Item>(props: IconSelectorProps<T>) => {
  const { form, label, required, fieldName } = props;

  const menuIconsLoader = useCallback(async () => {
    const iconAssets = await menuItemApi.loadMenuIcons();
    return iconAssets.map((asset) => ({ label: asset.id, value: asset.id }));
  }, [menuItemApi]);

  const fieldOptions = required ? { required: 'This field is required.' } : undefined;

  return (
    <Select
      label={label}
      fieldName={fieldName}
      fieldWidth="short"
      formContext={form}
      formFieldOptions={fieldOptions}
      optionsLoader={menuIconsLoader}
      placeholder="Select an icon"
    />
  );
};

export default IconSelector;
