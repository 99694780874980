import { useCallback, useState } from 'react';
import { Item } from '../../form/definitions';
import { SubItemApi } from '../definitions';

export const useSubItemPersistence = <I extends Item, R = I>(itemApi: SubItemApi<I, R>) => {
  const [subItemRequestPending, setSubItemRequestPending] = useState(false);
  const [item, setItem] = useState(null as R);

  const { publishRecords } = itemApi;

  const publishItems = useCallback(
    async (itemIds: string[]) => {
      setSubItemRequestPending(true);

      try {
        const item = await publishRecords(itemIds);
        setItem(item);
      } finally {
        setSubItemRequestPending(false);
      }
    },
    [publishRecords]
  );

  return {
    subItemRequestPending,
    item,
    publishItems,
  };
};
