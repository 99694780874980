import { ItemTableColumn, SortState, EMPTY_SORT } from './definitions';
import { Item } from '../form/definitions';

export const findDefaultSort = <I extends Item>(columns: ItemTableColumn<I>[]): SortState<I> => {
  let result: SortState<I> = EMPTY_SORT;
  if (columns.length > 0) {
    const defaultColumn = columns.find((c) => !!c.initialSort);
    if (defaultColumn) {
      result = {
        sortField: defaultColumn.valueField,
        order: defaultColumn.initialSort,
      };
    }
  }

  return result;
};

export const getColumnSortOrder = <I extends Item>(
  column: ItemTableColumn<I>,
  sort: SortState<I>
) => {
  const { valueField } = column;
  const { sortField, order } = sort;

  return valueField === sortField ? order : 'NONE';
};
