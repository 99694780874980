import React, { PropsWithChildren, useMemo } from 'react';
import { CmsPageContext } from '../definitions';
import MultiSpinner from '../../spinner/components/MultiSpinner';
import { useSpinnerProvider } from '../../spinner/hooks/useSpinnerProvider';
import { useSpinner } from '../../spinner/hooks/useSpinner';

interface CmsPageProps extends PropsWithChildren<any> {
  title: string;
  requestPending?: boolean;
}

const CmsPage: React.FC<CmsPageProps> = (props: CmsPageProps) => {
  const { title, children, requestPending } = props;

  const { spinnerProvider, spinners } = useSpinnerProvider();
  useSpinner(spinnerProvider, requestPending);

  const pageContext = useMemo(
    () => ({
      spinnerProvider,
    }),
    [spinnerProvider]
  );

  return (
    <>
      <div className="header row">
        <h1 className="page-title">
          {title}
          <span className="page-spinner">
            <MultiSpinner spinners={spinners} />
          </span>
        </h1>
      </div>
      <CmsPageContext.Provider value={pageContext}>{children}</CmsPageContext.Provider>
    </>
  );
};

export default CmsPage;
