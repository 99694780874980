import { useItemPersistence } from '../../cms/hooks/useItemPersistence';
import { useContext, useMemo } from 'react';
import { Item } from '../../form/definitions';
import { SubItemApi } from '../../cms/definitions';
import { CmsContext } from '../../../context/definitions';
import { Action, ContextAction } from '../../definitions';
import { PageConstants, WrappedActionParams } from '../definitions';

const useSubItemListActions = <T extends Item, R = T>(
  subItemApi: SubItemApi<T, R>,
  parentItemId: string,
  pageConstants: PageConstants
) => {
  const { typeName, pluginId, defaultPageId, editorPageName } = pageConstants;

  const { navigator, growlProvider, confirm } = useContext(CmsContext);
  const { success, error } = growlProvider;

  const { removeItem, restoreItem, requestPending } = useItemPersistence(subItemApi);

  const add = useMemo(
    (): Action<WrappedActionParams<T>> => ({
      label: `+ Add ${typeName}`,
      onClick: () => {
        navigator.navigateToPluginPage(pluginId, editorPageName, parentItemId, 'new');
      },
    }),
    [typeName, navigator, pluginId, defaultPageId, editorPageName, parentItemId]
  );

  const edit = useMemo(
    (): ContextAction<T, WrappedActionParams<T>> => ({
      icon: 'fa-solid fa-pencil',
      label: 'Edit',
      disabled: (context: T) => context.status === 'removed',
      onClick: (context: T) => {
        navigator.navigateToPluginPage(pluginId, editorPageName, parentItemId, context.id);
      },
    }),
    [navigator, pluginId, defaultPageId, editorPageName, parentItemId]
  );

  const remove = useMemo(
    (): ContextAction<T, WrappedActionParams<T>> => ({
      icon: 'fa-solid fa-trash',
      label: 'Remove',
      disabled: (context: T) => context.status === 'removed',
      onClick: async (context: T, { reload }) => {
        const confirmation = await confirm({
          title: `Remove ${typeName}`,
          message: `Are you sure you want to remove this ${typeName}?`,
        });

        if (confirmation) {
          try {
            await removeItem(context);
            success(defaultPageId, `The ${typeName} has been successfully removed!`);
            await reload();
          } catch (e) {
            error(defaultPageId, e.message);
          }
        }
      },
    }),
    [removeItem, typeName, success, error]
  );

  const restore = useMemo(
    (): ContextAction<T, WrappedActionParams<T>> => ({
      icon: 'fa-solid fa-trash-arrow-up',
      label: 'Restore',
      disabled: (context: T) => context.status === 'unpublished' || context.status === 'published',
      onClick: async (context: T, { reload }) => {
        try {
          await restoreItem(context);
          success(defaultPageId, `The ${typeName} has been successfully restored!`);
          await reload();
        } catch (e) {
          error(defaultPageId, e.message);
        }
      },
    }),
    [restoreItem, typeName, success, error]
  );

  return {
    add,
    edit,
    remove,
    restore,
    requestPending,
  };
};

export default useSubItemListActions;
