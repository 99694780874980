import { useContext, useMemo, useState } from 'react';
import { CmsContext } from '../../context/definitions';
import { PageConstants, WrappedActionParams } from '../../shared/cmsPage/definitions';
import { Action, ContextAction } from '../../shared/definitions';
import { Item } from '../../shared/form/definitions';
import useItemListActions from '../../shared/cmsPage/hooks/useItemListActions';
import { TargetAudience, TargetAudienceConstants } from '../definitions';
import targetAudienceApiWrapped from '../api/targetAudienceApi';

export default function useTargetAudienceActions<T extends Item>(pageConstants: PageConstants) {
  const [includeArchived, setIncludeArchived] = useState(false);
  const targetAudienceApi = targetAudienceApiWrapped(includeArchived);
  const {
    navigator,
    growlProvider: { success, error },
    confirm,
  } = useContext(CmsContext);

  const { edit, remove, restore, publish, requestPending } = useItemListActions(
    targetAudienceApi,
    TargetAudienceConstants
  );

  const archiveCheckbox = useMemo(
    (): Action<WrappedActionParams<T>> => ({
      icon: 'fa-solid fa-pencil',
      label: includeArchived ? 'Hide Archived' : 'Show Archived',
      onClick: async () => void setIncludeArchived((current) => !current),
    }),
    [includeArchived, setIncludeArchived]
  );

  const { typeName, pluginId, defaultPageId, editorPageName } = pageConstants;
  const addOverride = useMemo(
    (): Action<WrappedActionParams<T>> => ({
      label: `+ Add Audience`,
      onClick: async (loaderParams: WrappedActionParams<TargetAudience>) => {
        try {
          const newTargetAudience = await targetAudienceApi.loadRecord('new');
          navigator.navigateToPluginPage(
            TargetAudienceConstants.pluginId,
            TargetAudienceConstants.editorPageName,
            newTargetAudience.id || 'new'
          );
        } catch (e) {
          console.error(e);
          error(defaultPageId, 'Failed to add.');
        }
      },
    }),
    [typeName, navigator, pluginId, defaultPageId, editorPageName]
  );

  const archive = useMemo(
    (): ContextAction<T, WrappedActionParams<T>> => ({
      label: (item: Item) => (item.isArchived ? 'Unarchive' : 'Archive'),
      icon: 'fa-solid fa-box-archive',
      onClick: async (context: Item, { reload }) => {
        try {
          if (!context.isArchived) {
            const confirmResponse = await confirm({
              title: 'Archive Target Audience',
              message: `Are you sure you want to archive ${context.name}`,
            });
            if (confirmResponse) {
              await targetAudienceApi.archiveRecord(context.id);
            }
          } else {
            const confirmResponse = await confirm({
              title: 'Unarchive Target Audience',
              message: `Are you sure you want to unarchive ${context.name}`,
            });
            if (confirmResponse) {
              await targetAudienceApi.unarchiveRecord(context.id);
            }
          }
          await reload();
          success(defaultPageId, 'Successfully updated targetAudience.');
        } catch (e) {
          console.error(e);
          error(defaultPageId, 'Failed to update targetAudience.');
        }
      },
    }),
    [typeName, navigator, pluginId, defaultPageId, editorPageName, confirm]
  );

  return {
    add: addOverride,
    archive,
    archiveCheckbox,
    edit,
    remove,
    restore,
    publish,
    requestPending,
    includeArchived,
  };
}
