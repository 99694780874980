import { ConditionExpr, ItemApi, PublishStatus } from '../shared/cms/definitions';
import { PageConstants } from '../shared/cmsPage/definitions';
import { Item } from '../shared/form/definitions';
import createPageConstants from '../shared/util/createPageConstants';

const pluginId = 'menu-manager';

export const MenuConstants: PageConstants = createPageConstants(
  pluginId,
  'Menu',
  {
    defaultDefault: 'menuListPage',
    editor: 'menuEditorPage',
    publish: 'menuItemPublishPage',
  },
  'Navigation'
);

export const MenuEditorConstants: PageConstants = createPageConstants(pluginId, 'Menu Item', {
  default: 'menuEditorPage',
  editor: 'menuItemEditorPage',
  parentDefault: 'menuListPage',
  publish: 'menuItemPublishPage',
});

export const MenuPublishPageConstants: any = createPageConstants(pluginId, 'Menu', {
  default: 'menuItemPublishPage',
});

export interface MenuRevision extends Item {
  id?: string;
  menu_id?: string;
  key?: string;
  name: string;
  label: string;
}

export interface Menu extends Item {
  id: string;
  name: string;
  label: string;
  key: string;
  lastUpdated: Date;
  status: PublishStatus;
  sandboxRevision?: MenuRevision;
  liveRevision?: MenuRevision;
}

export const EMPTY_MENU_REVISION: MenuRevision = {
  name: '',
  label: '',
};

export interface MenuItemRevision extends Item {
  menu_id?: string;
  id?: string;
  menu_item_id?: string;
  label: string;
  image_asset_id?: string;
  objectReference?: string;
  condition?: ConditionExpr;
  referenceLabel?: string;
  minBuild?: string;
  maxBuild?: string;
  isEvergreen?: boolean;
}

export interface MenuItem extends Item {
  id: string;
  lastUpdated: Date;
  label: string;
  image_asset_id: string;
  objectReference: string;
  status: PublishStatus;
  position: number;
  condition?: ConditionExpr;
  referenceLabel?: string;
  sandboxRevisionId?: string;
  liveRevisionId?: string;
}

export const EMPTY_MENU_ITEM_REVISION: MenuItemRevision = {
  label: '',
};

export interface MenuApi extends ItemApi<Menu, MenuRevision> {
  migrate: () => Promise<void>;
  checkCanMigrate: () => Promise<boolean>;
}
