import { PageConstants } from '../shared/cmsPage/definitions';
import { Item } from '../shared/form/definitions';

export const CohortConstants: PageConstants = {
  pageTitle: 'Cohorts',
  pluginId: 'cohorts',
  defaultPageName: 'default',
  defaultPageId: 'cohort-list',
  editorPageName: 'cohortEditor',
  editorPageId: 'cohort-editor',
  itemsPageName: 'cohortMatchesPage',
  itemsPageId: 'cohort-matches-page',
  typeName: 'Cohort',
};

export interface Cohort extends Item {
  id: string;
  name: string;
  credentialsPromptText: string;
}

export interface CohortMember extends Item {
  id: string;
  cohort_id: string;
  name: string;
}

export const EMPTY_COHORT = {
  name: '',
};
