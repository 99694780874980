(function () {
  angular.module('message-service', ['config-service']).service('messageService', [
    '$q',
    'configService',
    function ($q, configService) {
      function getAllMessages() {
        return configService.getConfig().then(function (config) {
          return config.messages;
        });
      }

      function getMessage(key) {
        return configService.getConfig().then(function (config) {
          return config.messages[key];
        });
      }

      return {
        getAllMessages: getAllMessages,
        getMessage: getMessage,
      };
    },
  ]);
})();
