import get from 'lodash/get';
import React, { useCallback } from 'react';
import { Item } from '../../definitions';
import { validationIncludesRequired } from '../../formUtils';
import { FormImageProps } from './definitions';
import FormImageComponent from './FormImageComponent';
import Store from './store';

export default function FormImage<T extends Item>(props: FormImageProps<T>) {
  const {
    acceptedFileTypes = [],
    dropzoneSettings = {},
    formContext,
    formFieldOptions,
    fieldName,
    existingAssetIdField,
    defaultAssetId,
    useCropper = false,
    croppedRatio,
    ...restProps
  } = props;
  formContext.register(fieldName, formFieldOptions);

  const setFormValue = useCallback(
    (value, shouldTouch = true) => {
      formContext.setValue(fieldName, value, {
        shouldValidate: shouldTouch,
        shouldDirty: shouldTouch,
        shouldTouch: shouldTouch,
      });
    },
    [formContext, fieldName]
  );

  const componentProps = {
    ...restProps,
    croppedRatio,
    fieldError: get(formContext, `formState.errors.${fieldName}`),
    isRequired: validationIncludesRequired(formFieldOptions),
    useCropper,
  };

  const storeProps = {
    acceptedFileTypes,
    currentAssetId: get(formContext.getValues(), existingAssetIdField, null),
    defaultAssetId,
    dropzoneSettings,
    setFormValue,
    useCropper,
  };

  return (
    <Store {...storeProps}>
      <FormImageComponent {...componentProps} />
    </Store>
  );
}
