import React, { useCallback, useContext } from 'react';
import { Action } from '../../definitions';
import ActionList from '../../itemTable/components/ActionList';
import { CmsContext } from '../../../context/definitions';

interface CmsSubItemListSectionToolbarProps<P> {
  pluginId: string;
  parentPageTitle: string;
  parentPageName: string;
  parentPageId: string;
  actions: Action<P>[];
  parentPageArgs?: string[];
}

const CmsSubItemListSectionToolbar = <P extends object>(
  props: CmsSubItemListSectionToolbarProps<P>
) => {
  const { actions, pluginId, parentPageId, parentPageName, parentPageTitle, parentPageArgs } =
    props;

  const { navigator } = useContext(CmsContext);

  const navigateToParent = useCallback(() => {
    navigator.navigateToParentPage(
      pluginId,
      parentPageName,
      parentPageId,
      ...(parentPageArgs || [])
    );
  }, [navigator, parentPageId, parentPageName, parentPageArgs]);

  return (
    <div className="section-toolbar">
      <span className="header-link">
        <a onClick={navigateToParent}>&lt; Back to {parentPageTitle}</a>
      </span>
      <ActionList actions={actions} />
    </div>
  );
};

export default CmsSubItemListSectionToolbar;
