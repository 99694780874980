import { ListLoaderParams } from '../../cms/definitions';
import { Item } from '../../form/definitions';
import { useCallback, useMemo } from 'react';
import { AnyItemAction } from '../definitions';

const useWrapPageActions = <T extends Item>(
  pageId: string,
  actions: AnyItemAction<T>[],
  reload: (loaderParams: ListLoaderParams<T>) => void,
  loaderParams?: ListLoaderParams<T>
) => {
  const wrapHandler = useCallback(
    (handler) => {
      return (...args: any[]) => {
        handler(...args, {
          loaderParams,
          reload: () => reload(loaderParams),
        });
      };
    },
    [pageId, reload, loaderParams]
  );

  return useMemo(() => {
    return actions.map((a) => ({
      ...a,
      onClick: a.onClick ? wrapHandler(a.onClick) : undefined,
    }));
  }, [actions, wrapHandler]);
};

export default useWrapPageActions;
