//Reducer to Handle Actions
import assign from 'lodash/assign';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { v4 as uuid } from 'uuid';
import { FILE_KEYS } from '../definitions';
//eslint-disable-next-line
//@ts-ignore
import DefaultImage from '../../../../../../../img/DefaultTile.png';

export const updateFile = (file: any, objectProps: any = {}, isNew: any = false) => {
  const { name, type, path } = objectProps;
  const updatedFile = new File([file], name, { type: type || get(file, 'type', 'image/*') });
  return assign(updatedFile, {
    preview: isNew
      ? URL.createObjectURL(updatedFile)
      : get(file, 'preview', URL.createObjectURL(updatedFile)),
    path: path || get(file, 'path', name),
  });
};

export const getLocalDefaultTileImage = () => {
  const ext = DefaultImage.split('.').pop();
  let file = new File([DefaultImage], `DEFAULT_FILE`, { type: `image/${ext}` });
  file = assign(file, {
    name: 'DEFAULT_FILE',
    preview: '/img/DefaultTile.png',
    path: `DEFAULT_FILE.${ext}`,
  });
  return file;
};

export const removePreview = (file: any) => {
  try {
    URL.revokeObjectURL(get(file, 'preview'));
  } catch (e) {
    console.log('unsuccessful attempt to revoke URL', e);
  }
};

export const removeFileMapPreviews = (availableFilesMap: any = {}) => {
  forEach(omit(availableFilesMap, FILE_KEYS), removePreview);
};

export const getRadianAngle = (degreeValue: any): any => (degreeValue * Math.PI) / 180;

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width: any, height: any, rotation: any): any {
  const rotRad = getRadianAngle(rotation);

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

export const createImage = (url: any): any =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export const getCroppedImg = async (
  imageSrc: any,
  pixelCrop: any,
  rotation: any = 0,
  flip: any = { horizontal: false, vertical: false }
) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx: any = canvas.getContext('2d');
  if (!ctx) return null;
  const rotRad = getRadianAngle(rotation);
  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);
  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;
  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);
  // draw rotated image
  ctx.drawImage(image, 0, 0);
  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);
  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);
  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      file = new File([file], `${uuid()}.jpg`, { type: 'image/jpeg' });
      resolve(file);
    }, 'image/jpeg');
  });
};
