import React from 'react';
import { MenuRevision } from '../definitions';
import FormInput from '../../shared/form/components/FormInput';
import { ItemEditorForm, ItemEditorFormProps } from '../../shared/cmsPage/definitions';

type MenuEditorFormProps = ItemEditorFormProps<MenuRevision>;

const MenuEditorForm: ItemEditorForm<MenuRevision> = (props: MenuEditorFormProps) => {
  const { form } = props;

  return (
    <div className="row">
      <form role="form">
        <FormInput
          label="Name"
          fieldName="name"
          formContext={form}
          formFieldOptions={{ required: 'This field is required.' }}
        />
        <FormInput label="Display Label" fieldName="label" formContext={form} />
      </form>
    </div>
  );
};

export default MenuEditorForm;
