(function () {
  angular.module('edit-admin', ['admin-api-service']).controller('EditAdminCtrl', [
    '$scope',
    '$log',
    '$timeout',
    'growl',
    'adminApiService',
    'confirmDialog',
    'configService',
    '$q',
    function ($scope, $log, $timeout, growl, adminApiService, confirmDialog, configService, $q) {
      $scope.admin = $scope.admin ? angular.copy($scope.admin) : {};
      $scope.modeLabel = $scope.replaceMode ? 'edit' : 'add';

      $scope.disableEmail = !!$scope.replaceMode;

      $scope.admin.originalEmail = $scope.admin.user_email;
      $scope.originalRole = $scope.admin.role;

      configService.getConfig().then(function (config) {
        $scope.multitenant = config.accessibleTenants.length > 1;
      });

      $scope.rolePrompt = !$scope.admin.role;

      $scope.roleList = _($scope.roles)
        .values()
        .filter(canAssign)
        .map(setSortPriority)
        .sort(compareRoles)
        .value();

      $scope.cancel = function () {
        $scope.$dismiss();
      };

      $scope.save = function () {
        confirmSave().then(function (proceed) {
          if (proceed) {
            adminApiService
              .saveAdmin($scope.admin)
              .success(function (data, status, headers, config) {
                if (data.status === 'error') {
                  growl.error(data.error, { referenceId: 'adminGrowl' });
                } else {
                  $scope.$close(data);
                }
              })
              .error(function (err, status, headers, config) {
                growl.error(err.error || 'Admin update failed.  Please try again later.', {
                  referenceId: 'adminGrowl',
                });
              });
          }
        });
      };

      function confirmSave() {
        var deferred = $q.defer();
        var warning;

        if ($scope.admin.role !== $scope.originalRole) {
          if ($scope.multitenant) {
            if ($scope.originalRole === 'super_admin') {
              warning =
                'Removing System Administrator privilege will limit the instances that are visible to this user.  Continue?';
            } else if ($scope.admin.role === 'super_admin') {
              warning =
                'Granting System Administrator privilege will give this user access to all instances.  Continue?';
            }
          }
        }

        if (warning) {
          confirmDialog({
            title: 'Confirm save?',
            body: warning,
            confirmText: 'Save',
            cancelText: 'Cancel',
          }).result.then(
            function () {
              deferred.resolve(true);
            },
            function () {
              // Cancelled.
              deferred.resolve(false);
            }
          );
        } else {
          deferred.resolve(true);
        }

        return deferred.promise;
      }

      function canAssign(role) {
        return role.canAssign;
      }

      function setSortPriority(role) {
        if (typeof role.sortPriority === 'undefined') {
          role.sortPriority = 9999;
        }

        return role;
      }

      function compareRoles(role1, role2) {
        var result = role1.sortPriority - role2.sortPriority;
        if (result === 0) {
          result = role1.label.localeCompare(role2.label);
        }

        return result;
      }
    },
  ]);
})();
