(function () {
  angular.module('install-content', ['content-api-service']).controller('InstallContentCtrl', [
    '$scope',
    '$log',
    'growl',
    'contentApiService',
    function ($scope, $log, growl, contentApiService) {
      $scope.content = {
        branch: 'master',
      };

      $scope.uploadProgress = 0;

      $scope.install = function () {
        $scope.uploadProgress = 0;
        contentApiService
          .installDefaultContent($scope.files[0])
          .progress(function (evt) {
            $scope.uploadProgress = evt.total > 0 ? (100.0 * evt.loaded) / evt.total : 0;
          })
          .success(function (data, status, headers, config) {
            if (data.status == 'error') {
              growl.error(data.error);
            } else {
              $scope.$close(data);
            }
          })
          .error(function (err, status, headers, config) {
            growl.error('Content installation failed.  Please try again later.');
          });
      };

      $scope.cancel = function () {
        $scope.$dismiss();
      };
    },
  ]);
})();
