/* eslint-disable @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from 'react';
import { CmsContext } from '../../../context/definitions';
import { getWysiwygOptions } from '../../../../common/getWysiwygOptions';
import { WysiwygListener } from '../../../../common/froalaEvents';

const wysiwygListener: WysiwygListener = {
  onCodeViewChanged(active: any, editor: any): void {},
  onImageInserted(e: any, editor: any): void {},
  onInitialized(e: any, editor: any): void {},
  onVideoInserted(e: any, editor: any): void {},
};

const useWysiwygOptions = () => {
  const [error, setError] = useState(undefined);
  const [wysiwygOptions, setWysiwygOptions] = useState();

  const { cmsConfig } = useContext(CmsContext);

  useEffect(() => {
    const loadOptions = async () => {
      try {
        const wysiwygOptions = await getWysiwygOptions(cmsConfig, wysiwygListener);
        setWysiwygOptions(wysiwygOptions);
      } catch (error) {
        setError('Failed to load wysiwyg options');
      }
    };

    (async () => loadOptions())();
  }, []);

  return {
    wysiwygOptions,
    error,
  };
};

export default useWysiwygOptions;
