import { useContext, useMemo } from 'react';
import { CmsContext } from '../../context/definitions';
import useConfirmPublish from '../../shared/cmsPage/hooks/useConfirmPublish';
import { Action } from '../../shared/definitions';
import { MenuEditorConstants, MenuPublishPageConstants } from '../definitions';
import useArray from '../../shared/util/hooks/useArray';
interface UseMenuItemPublishActionsProps {
  item: any;
  menuId: string;
  publishItem: any;
  returnPageName: string;
  selectedItems: any[];
  successGrowlId: string;
}

export default function useMenuItemPublishActions(props: UseMenuItemPublishActionsProps) {
  const { selectedItems, item, publishItem, menuId, returnPageName, successGrowlId } = props;

  const { navigator, growlProvider, confirm } = useContext(CmsContext);
  const { success, error } = growlProvider;
  const confirmPublish = useConfirmPublish('menu');

  const publish: Action = useMemo(
    () => ({
      label: `Publish Now`,
      onClick: async () => {
        const confirmation = await confirmPublish();

        if (confirmation) {
          try {
            await publishItem(menuId, selectedItems);
            navigator.navigateToPluginPage(MenuEditorConstants.pluginId, returnPageName, menuId);
            success(successGrowlId, `The menu has been successfully published!`);
          } catch (e) {
            error(MenuEditorConstants.defaultPageId, e.message);
          }
        }
      },
    }),
    [
      item,
      selectedItems,
      publishItem,
      confirmPublish,
      success,
      error,
      confirm,
      navigator,
      MenuEditorConstants,
      menuId,
      successGrowlId,
      returnPageName,
    ]
  );

  const cancel: Action = useMemo(
    () => ({
      label: `Cancel`,
      onClick: () => {
        navigator.navigateToPluginPage(MenuPublishPageConstants.pluginId, returnPageName, menuId);
      },
    }),
    [navigator, MenuEditorConstants, menuId, returnPageName]
  );
  const toolbarActions = useArray(publish, cancel);

  return { toolbarActions };
}
