import React from 'react';
import { CmsSubtenant, CmsTenant, ConditionExpr, ImportConfig } from '../shared/cms/definitions';
import { CmsNavigator } from './CmsNavigator';
import { GrowlMap, GrowlProvider } from '../shared/growl/definitions';
import { ConfirmArgs } from '../shared/modal/components/ConfirmModal';
import { CmsConfig } from '../../common/cmsDefinitions';

export interface CmsContextProps {
  cmsConfig?: CmsConfig;
  selectedTenant?: CmsTenant;
  selectedSubtenant?: CmsSubtenant;
}

export interface AudienceModalOptions {
  showPreview?: boolean;
  includePushOptionForPreview?: boolean;
}

export interface ICmsContext extends CmsContextProps {
  navigator: CmsNavigator;
  growlProvider: GrowlProvider;
  growlMap: GrowlMap;
  confirm: (args: ConfirmArgs) => Promise<boolean>;
  showLinkSearch: (currentLink: string, allowEmbeddedMenus: boolean) => Promise<string>;
  showAudienceModal: (
    currentAudienceExpr: ConditionExpr,
    options?: AudienceModalOptions
  ) => Promise<ConditionExpr>;
  showImportDialog: (config: ImportConfig) => Promise<string>;
  notifyContentUpdated: () => void;
}

export const CmsContext = React.createContext({} as ICmsContext);
