import { useCallback, useMemo, useState } from 'react';
import { ListLoaderParams } from '../definitions';
import { EMPTY_SORT, ItemTableColumn, SortState } from '../../itemTable/definitions';
import { Item } from '../../form/definitions';
import useSavedPageState from '../../cmsPage/hooks/useSavedPageState';
import { getRestoreOnTransition } from '../../cmsPage/cmsPageUtils';
import { findDefaultSort } from '../../itemTable/itemTableUtils';

export const useListLoaderParams = <T extends Item>(
  pageId: string,
  pageSize: number = 25,
  columns: ItemTableColumn<T>[]
) => {
  const savedLoaderParams = useSavedPageState<ListLoaderParams<T>>(pageId);

  const restoreState = useMemo(() => {
    return getRestoreOnTransition(pageId);
  }, []);

  const defaultSort = useMemo(() => findDefaultSort(columns), [columns, findDefaultSort]);

  const defaultLoaderParams = useMemo(() => {
    let result;
    if (restoreState) {
      result = savedLoaderParams;
    }
    return result;
  }, [restoreState, savedLoaderParams]);

  const [loaderParams, setLoaderParams] = useState(
    defaultLoaderParams ||
      ({
        limit: pageSize,
        offset: 0,
        search: '',
        sort: defaultSort,
      } as ListLoaderParams<T>)
  );

  const onSearchChange = useCallback(
    (search: string) => {
      if (search !== loaderParams.search) {
        setLoaderParams({ ...loaderParams, search, offset: 0 });
      }
    },
    [loaderParams, setLoaderParams]
  );

  const onPageSelected = useCallback(
    (page) => {
      const currentPage = loaderParams.offset / loaderParams.limit;
      if (page !== currentPage + 1) {
        const offset = (page - 1) * loaderParams.limit;

        setLoaderParams({ ...loaderParams, offset });
      }
    },
    [loaderParams, setLoaderParams]
  );

  const onSortChange = useCallback(
    (sort: SortState<T>) => {
      if (sort !== loaderParams.sort) {
        setLoaderParams({ ...loaderParams, offset: 0, sort });
      }
    },
    [loaderParams, setLoaderParams]
  );

  return useMemo(() => {
    return { loaderParams, onSearchChange, onPageSelected, onSortChange };
  }, [loaderParams, onSearchChange, onPageSelected, onSortChange]);
};
