import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { FileError } from 'react-dropzone';
import { ImageFile } from '../definitions';

interface ImageAlertProps {
  file: ImageFile;
  errors?: [FileError];
  variant: string;
}

function ImageAlert(props: ImageAlertProps) {
  let isMounted = true;
  const [show, setShow] = useState(true);
  const { errors, file, variant } = props;
  useEffect(() => {
    if (file) {
      setTimeout(() => {
        if (isMounted) setShow(false);
      }, 3000);
    }
    return () => {
      isMounted = false;
    };
  }, [file]);
  return (
    show && (
      <Alert style={{ opacity: 1, padding: 5 }} variant={variant} key={file.path}>
        <div style={{ fontSize: 12 }}>
          <strong>{`${variant === 'danger' ? 'Rejcted' : 'Accepted'}`}</strong> {file.path}
        </div>
        {variant === 'danger' && <p>{`${errors.map((e: FileError) => e.message).join(', ')}`}</p>}
      </Alert>
    )
  );
}

export default ImageAlert;
