import React, { useEffect, useMemo } from 'react';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import { useSubItemPersistence } from '../../shared/cms/hooks/useSubItemPersistence';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import CmsSubItemPublishSection from '../../shared/cmsPage/components/CmsSubItemPublishSection';
import { usePublishPageTitle } from '../../shared/cmsPage/hooks/usePublishPageTitle';
import useMultiSelect from '../../shared/util/hooks/useMultiSelect';
import collectionApi from '../api/collectionApi';
import collectionItemApi from '../api/collectionItemApi';
import { CollectionPublishPageConstants } from '../definitions';
import useCollectionItemPublishActions from '../hooks/useCollectionItemPublishActions';

interface CollectionItemPublishPageProps {
  collectionId: string;
  successGrowlId: string;
  returnPageId: string;
  returnPageName: string;
  returnPageLabel: string;
}

const CollectionItemPublishPage: React.FC<CollectionItemPublishPageProps> = (
  props: CollectionItemPublishPageProps
) => {
  const { collectionId, successGrowlId, returnPageId, returnPageName, returnPageLabel } = props;

  const { item, loadItem, publishItem, requestPending } = useItemPersistence(collectionApi);

  const { subItemRequestPending } = useSubItemPersistence(collectionItemApi);

  useEffect(() => {
    if (collectionId) {
      (async () => await loadItem(collectionId))();
    }
  }, [collectionId, loadItem]);

  const { pageTitle, onItemChange } = usePublishPageTitle(collectionId, 'Collection', 'name');
  useEffect(() => onItemChange(item), [item, onItemChange]);

  const pending = requestPending || subItemRequestPending;

  const emptyListMessage = useMemo(() => {
    return item
      ? `There are currently no collection items for '${item.name}.'`
      : `There are currently no collection items.`;
  }, [item]);

  const selectActions = useMultiSelect();

  const { toolbarActions } = useCollectionItemPublishActions({
    selectedItems: selectActions.selectedItems,
    item,
    publishItem,
    collectionId,
    returnPageName,
    successGrowlId,
  });

  return (
    <CmsPage title={pageTitle} requestPending={pending}>
      <CmsSubItemPublishSection
        parentItemId={collectionId}
        parentPageId={returnPageId}
        parentPageName={returnPageName}
        parentPageTitle={returnPageLabel}
        parentPageArgs={[collectionId]}
        className="nav-manager"
        toolbarActions={toolbarActions}
        emptyListMessage={emptyListMessage}
        subItemApi={collectionItemApi}
        pageConstants={CollectionPublishPageConstants}
        selectActions={selectActions}
      />
    </CmsPage>
  );
};

export default CollectionItemPublishPage;
