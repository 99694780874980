import { useCallback, useContext, useMemo } from 'react';
import { Item } from '../../form/definitions';
import { UseFormReturn } from 'react-hook-form';
import { CmsContext } from '../../../context/definitions';
import { PageConstants } from '../definitions';
import { Action } from '../../definitions';

const useItemEditActions = <I extends Item, R extends object = I>(
  saveItem: (item: R) => Promise<R>,
  form: UseFormReturn<R>,
  pageConstants: PageConstants,
  ...navigateBackArgs: string[]
) => {
  const { defaultPageId, defaultPageName, typeName, pluginId, editorPageId } = pageConstants;

  const { navigator, growlProvider } = useContext(CmsContext);
  const { success, error } = growlProvider;

  const { formState, handleSubmit } = form;
  const { isDirty, isValid } = formState;

  const navigateBack = useCallback(() => {
    navigator.navigateToParentPage(pluginId, defaultPageName, defaultPageId, ...navigateBackArgs);
  }, [navigator, pluginId, defaultPageName, defaultPageId, navigateBackArgs]);

  const save = useCallback(
    async (draftItem: R, close = false) => {
      try {
        await saveItem(draftItem);

        let targetGrowlId = editorPageId;
        if (close) {
          targetGrowlId = defaultPageId;
          navigateBack();
        }

        success(targetGrowlId, `The ${typeName} has been successfully updated!`);
      } catch (e) {
        error(editorPageId, e.message);
      }
    },
    [saveItem, navigateBack, error, success, editorPageId, defaultPageId]
  );

  const cancel = useCallback(async () => {
    navigateBack();
  }, [navigateBack]);

  return useMemo((): Action[] => {
    const canSave = isDirty && isValid;
    return [
      {
        label: 'Save Changes',
        disabled: !canSave,
        onClick: handleSubmit((item) => {
          save(item as R, true);
        }),
      },
      { label: 'Cancel', disabled: false, variant: 'default', onClick: cancel },
    ];
  }, [save, cancel, isDirty, isValid]);
};

export default useItemEditActions;
