import React, { useCallback, useEffect, useMemo } from 'react';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import ItemEditorModal from '../../shared/cmsPage/components/ItemEditorModal';
import { useEditPageTitle } from '../../shared/cmsPage/hooks/useEditPageTitle';
import useItemEditorModal from '../../shared/cmsPage/hooks/useItemEditorModal';
import { ItemTableColumn } from '../../shared/itemTable/definitions';
import useArray from '../../shared/util/hooks/useArray';
import cohortApi from '../api/cohortApi';
import { Cohort, CohortConstants, CohortMember } from '../definitions';
import useCohortEditorActions from '../hooks/useCohortEditorActions';
import CohortEditorForm from './CohortEditorForm';
import CmsListSectionToolbar from '../../shared/cmsPage/components/CmsListSectionToolbar';
import GrowlContainer from '../../shared/growl/components/GrowlContainer';
import CmsPageSection from '../../shared/cmsPage/components/CmsPageSection';
import useWrapPageActions from '../../shared/cmsPage/hooks/useWrapPageActions';
import { ItemAction } from '../../shared/cmsPage/definitions';
import { useListLoaderParams } from '../../shared/cms/hooks/useListLoaderParams';
import ItemTable from '../../shared/itemTable/components/ItemTable';
import { useListLoader } from '../../shared/cms/hooks/useListLoader';
import useLoadListItems from '../../shared/cmsPage/hooks/useLoadListItems';
import generateCohortMembersApi from '../api/cohortMembersApi';
import classNames from 'classnames';
import ItemPageLink from '../../shared/itemTable/components/ItemPageLink';

interface CohortEditorPageProps {
  cohortId: string;
}

const CohortMemberColumns: ItemTableColumn<CohortMember>[] = [
  {
    label: '',
    valueField: '',
    sortable: false,
    className: 'cohort-member-status',
    renderer: (cohortMember) => (
      <>
        <i
          className={classNames('fa-regular', 'fa-envelope', {
            enabled: cohortMember.hasImplicitUser,
          })}
        />
        <i
          className={classNames('fa-regular', 'fa-ticket', {
            enabled: cohortMember.hasExplicitUsers,
          })}
        />
      </>
    ),
  },
  {
    label: 'Credential Code',
    valueField: 'credentialCode',
    className: 'cohort-credential-code',
    sortable: true,
    renderer: (cohortMember) => (
      <>
        {cohortMember.hasExplicitUsers ? (
          <ItemPageLink
            linkText={cohortMember.credentialCode}
            pluginId={CohortConstants.pluginId}
            defaultPageName={CohortConstants.itemsPageName}
            params={[cohortMember.cohortId, cohortMember.credentialCode]}
          />
        ) : (
          <>{cohortMember.credentialCode}</>
        )}
      </>
    ),
  },
  {
    label: 'Name',
    valueField: 'name',
    sortable: true,
  },
  {
    label: 'Invitee Email Address',
    valueField: 'email',
    sortable: true,
  },
];

const CohortEditorPage: React.FC<CohortEditorPageProps> = (props: CohortEditorPageProps) => {
  const { cohortId } = props;

  const cohortMembersApi = useMemo(() => generateCohortMembersApi(cohortId), [cohortId]);

  const { item, loadItem, requestPending: loadItemPending } = useItemPersistence(cohortApi);
  const {
    loadItems: loadMembers,
    loading: loadMembersPending,
    records: members,
    pagination,
    sort,
  } = useListLoader(cohortMembersApi.loadRecords);

  const requestPending = loadItemPending || loadMembersPending;

  const { loaderParams, onPageSelected, onSearchChange, onSortChange } = useListLoaderParams(
    CohortConstants.editorPageId,
    10,
    CohortMemberColumns
  );

  const reloadMembers = useLoadListItems<CohortMember>(
    CohortConstants.editorPageId,
    loadMembers,
    loaderParams
  );

  const reloadPageData = useCallback(
    async (loaderParams) => {
      await Promise.all([loadItem(cohortId), reloadMembers(loaderParams)]);
    },
    [cohortId, reloadMembers]
  );

  useEffect(() => {
    if (cohortId) {
      (async () => await loadItem(cohortId))();
    }
  }, [cohortId, loadItem]);

  const { showItemEditorModal, editorModalProps } = useItemEditorModal(
    CohortConstants.typeName,
    'name',
    cohortApi,
    CohortEditorForm
  );

  const { pageTitle, onItemChange } = useEditPageTitle(cohortId, 'Cohort', 'name');
  useEffect(() => onItemChange(item), [item, onItemChange]);

  const { back, editSettings, importMembers } = useCohortEditorActions({
    pluginId: CohortConstants.pluginId,
    item,
    loadItem,
    cohortId,
    parentPageTitle: CohortConstants.pageTitle,
    showItemEditorModal,
  });

  const toolbarActions = useArray(editSettings, importMembers);
  const wrappedToolbarActions = useWrapPageActions(
    CohortConstants.defaultPageId,
    toolbarActions,
    reloadPageData,
    loaderParams
  ) as ItemAction<Cohort>[];

  return (
    <CmsPage title={pageTitle} requestPending={requestPending}>
      <CmsPageSection className="cohort-editor">
        <div className="row">
          <CmsListSectionToolbar
            actions={wrappedToolbarActions}
            searchExpr={loaderParams.search}
            onSearchChange={onSearchChange}
            backPageAction={back}
          />
        </div>
        <GrowlContainer groupId={CohortConstants.editorPageId} />
        <div className="row">
          <ItemTable<CohortMember>
            columns={CohortMemberColumns}
            items={members}
            itemActions={[]}
            pagination={pagination}
            sort={sort}
            onPageSelected={onPageSelected}
            onSortChange={onSortChange}
          />
        </div>
        <div className="cohort-members-legend">
          <div className="legend-item">
            <i className="fa-regular fa-envelope" /> - authenticated via Email Address
          </div>
          <div className="legend-item">
            <i className="fa-regular fa-ticket" /> - authenticated via Credential Code
          </div>
        </div>
        <div className="cohort-footer">
          <a
            download="cohort-import-template.csv"
            href="/files/cohort-import-template.csv"
            target="_self"
          >
            Download Import Template
          </a>
        </div>
      </CmsPageSection>
      <ItemEditorModal {...editorModalProps} />
    </CmsPage>
  );
};

export default CohortEditorPage;
