import { useMemo } from 'react';
import { GrowlMap } from '../definitions';

const useGrowls = (groupId: string, growlMap: GrowlMap) => {
  return useMemo(() => {
    return growlMap[groupId] || [];
  }, [groupId, growlMap]);
};

export default useGrowls;
