(function () {
  angular
    .module('manage-admin-list', ['smart-table', 'admin-api-service', 'confirm-dialog'])
    .controller('ManageAdminsCtrl', [
      '$scope',
      '$modal',
      '$log',
      '$state',
      '$location',
      '$timeout',
      'growl',
      'adminApiService',
      'confirmDialog',
      'configService',
      'messageService',
      function (
        $scope,
        $modal,
        $log,
        $state,
        $location,
        $timeout,
        growl,
        adminApiService,
        confirmDialog,
        configService,
        messageService
      ) {
        /************* Initialization ************/
        $scope.PAGE_SIZE = 10;

        $scope.admins = [];
        $scope.displayedAdmins = [];
        $scope.numPages = 0;

        $scope.roles = {};

        configService.getConfig().then(function (config) {
          $scope.loggedInUser = config.loggedInUser;
        });

        messageService.getMessage('cms.manageAdmins.searchGhostText').then(function (message) {
          $scope.searchGhostText = message;
        });

        adminApiService
          .getRoles()
          .then(function (roles) {
            $scope.roles = roles;
          })
          .catch(function (error) {
            $log.error(error);
            $scope.roles = [];
            growl.error('Failed to load admin roles.', { referenceId: 'adminGrowl' });
          });

        $scope.callServer = function (tableState) {
          $scope.isLoading = true;
          $timeout(function () {
            $scope.admins = [];
            $scope.displayedAdmins = [];
          });

          $scope.tableState = tableState;

          var pagination = tableState.pagination;

          var start = pagination.start || 0; // This is NOT the page number, but the index of item in the list that you want to use to display the table.
          var number = pagination.number || $scope.PAGE_SIZE; // Number of entries showed per page.

          getAdmins(start, number, tableState);
        };

        $scope.getRoleLabel = function (admin) {
          var role = $scope.roles[admin.role];
          return role ? role.label : admin.role;
        };

        $scope.addOrEditAdmin = function (adminToReplace) {
          var options = {
            templateUrl: 'modules/manageAdmins/editAdminModal.html',
            controller: 'EditAdminCtrl',
            scope: $scope.$new(),
          };

          var originalEmail;

          if (adminToReplace) {
            originalEmail = adminToReplace.user_email;
            options.scope.admin = adminToReplace;
            options.scope.replaceMode = true;
          }

          var modalInstance = $modal.open(options);
          modalInstance.result.then(
            function (result) {
              growl.success('Admin saved successfully');
              refreshTable();
            },
            function () {}
          );
        };

        $scope.removeAdmin = function (admin) {
          confirmDialog({
            title: 'Confirm remove',
            body: 'Are you sure you want to remove this admin user?',
            confirmText: 'Remove',
            cancelText: 'Cancel',
          }).result.then(function (result) {
            adminApiService.removeAdmin(admin).then(
              function (result) {
                growl.success('User was successfully removed.');
                refreshTable(true);
              },
              function () {
                growl.error('Failed to remove admin');
              }
            );
          });
        };

        $scope.resendInvite = function (admin) {
          confirmDialog({
            title: 'Confirm resend',
            body: 'Are you sure you want to resend invitation email to this user?',
            confirmText: 'Resend',
            cancelText: 'Cancel',
          }).result.then(function (result) {
            adminApiService.resendInvite(admin).then(
              function (result) {
                refreshTable(true);
                growl.success('Invitation was sent successfully.');
              },
              function () {
                growl.error('Failed to resend invite');
              }
            );
          });
        };

        $scope.canEditAdmin = function (admin) {
          return (
            $scope.loggedInUser &&
            admin.user_email !== $scope.loggedInUser.email &&
            ($scope.loggedInUser.role === 'super_admin' || admin.role !== 'super_admin')
          );
        };

        $scope.showResendInvite = function (admin) {
          return admin.invitePending && $scope.canEditAdmin(admin);
        };

        function refreshTable(reload) {
          if (reload) {
            getAdmins(0, $scope.PAGE_SIZE);
          } else {
            getAdmins(
              $scope.tableState.pagination.start,
              $scope.tableState.pagination.number,
              $scope.tableState
            );
          }
        }

        function getAdmins(start, number, tableState) {
          adminApiService
            .getTenantAdmins(start, number, tableState)
            .success(function (data) {
              $scope.admins = data.admins;
              $scope.displayedAdmins = [].concat($scope.admins);
              setNumberOfPages(data.count);
              $scope.isLoading = false;
            })
            .error(function (data, status, headers, config) {
              growl.error('Failed to load admins.');
              $log.debug('Failed to get all admins. Response: ', data);
            });
        }

        function setNumberOfPages(count) {
          var numberOfPages = Math.ceil(count / $scope.PAGE_SIZE);
          if ($scope.tableState) {
            $scope.tableState.pagination.numberOfPages = numberOfPages || 1; //set the number of pages so the pagination can update
          }
        }
      },
    ]);
})();
