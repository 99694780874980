import React, { useContext } from 'react';
import { SubItemApi } from '../../cms/definitions';
import { ItemTableColumn } from '../../itemTable/definitions';
import CmsPageSection from './CmsPageSection';
import { Item } from '../../form/definitions';
import {
  CmsPageContext,
  ItemAction,
  ItemContextAction,
  ItemReorderInitiator,
  PageConstants,
} from '../definitions';
import { useSpinner } from '../../spinner/hooks/useSpinner';
import GrowlContainer from '../../growl/components/GrowlContainer';
import useWrapPageActions from '../hooks/useWrapPageActions';
import CmsSubItemListSectionToolbar from './CmsSubItemListSectionToolbar';
import OrderedItemTable from '../../itemTable/components/OrderedItemTable';
import useLoadSubListItems from '../hooks/useLoadSubListItems';
import { useSubItemsLoader } from '../../cms/hooks/useSubItemsLoader';
import ConidtionalAlert from '../../generalComponents/ConditionalAlert';
import { ConditionalAlertProps } from '../../generalComponents/definitions';

interface CmsSubItemListSectionProps<I extends Item, R = I> {
  parentItemId: string;
  parentPageTitle: string;
  emptyListMessage: string;
  toolbarActions: ItemAction<I>[];
  itemActions: ItemContextAction<I>[];
  reorder: ItemReorderInitiator;
  columns: ItemTableColumn<I>[];
  subItemApi: SubItemApi<I, R>;
  pageConstants: PageConstants;
  className?: string;
  alert?: ConditionalAlertProps;
}

const CmsSubItemListSection = <T extends Item, R = T>(props: CmsSubItemListSectionProps<T, R>) => {
  const {
    alert,
    className,
    columns,
    emptyListMessage,
    itemActions,
    pageConstants,
    parentItemId,
    parentPageTitle,
    reorder,
    subItemApi,
    toolbarActions,
  } = props;

  const { pluginId, parentPageId, parentPageName, defaultPageId } = pageConstants;

  const { spinnerProvider } = useContext(CmsPageContext);

  const { loadRecords } = subItemApi;
  const { loadItems, loading, records } = useSubItemsLoader(loadRecords);
  useSpinner(spinnerProvider, loading);

  const reload = useLoadSubListItems(defaultPageId, parentItemId, loadItems);

  const wrappedToolbarActions = useWrapPageActions(
    defaultPageId,
    toolbarActions,
    reload
  ) as ItemAction<T>[];
  const wrappedItemActions = useWrapPageActions(
    defaultPageId,
    itemActions,
    reload
  ) as ItemContextAction<T>[];

  return (
    <>
      <CmsPageSection className={className}>
        <div className="row">
          <CmsSubItemListSectionToolbar
            pluginId={pluginId}
            parentPageTitle={parentPageTitle}
            parentPageId={parentPageId}
            parentPageName={parentPageName}
            actions={wrappedToolbarActions}
          />
        </div>
        <GrowlContainer groupId={defaultPageId} />
        {alert && <ConidtionalAlert {...alert} />}
        <div className="row">
          <OrderedItemTable<T>
            columns={columns}
            items={records}
            itemActions={wrappedItemActions}
            emptyListMessage={emptyListMessage}
            reorder={reorder}
            parentItemId={parentItemId}
            growlId={defaultPageId}
          />
        </div>
      </CmsPageSection>
    </>
  );
};

export default CmsSubItemListSection;
