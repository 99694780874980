angular
  .module('input-directives', ['common-filters', 'message-service'])
  .directive('photo-upload', [
    '$http',
    function ($http) {
      return {
        restrict: 'A',
        link: function (scope, element, attrs) {
          element.on('click', function (e) {
            e.preventDefault();
            window.location = attrs.href;
          });
        },
      };
    },
  ])
  .directive('required', [
    '$compile',
    function ($compile) {
      return {
        restrict: 'C',
        link: function (scope, element, attrs) {
          var span = $('<span class="asterick">*</span>');
          var tagName = element[0].tagName;
          if (element[0].tagName === 'LABEL') {
            $(element).append(span);
          } else if (tagName === 'INPUT' || tagName === 'SELECT') {
            $(element).after(span);
          }
        },
      };
    },
  ])
  .directive('phoneInput', [
    '$filter',
    '$browser',
    function ($filter, $browser) {
      return {
        require: 'ngModel',
        link: function ($scope, $element, $attrs, ngModelCtrl) {
          var listener = function () {
            var value = $element.val().replace(/[^0-9]/g, '');
            $element.val($filter('tel')(value, false));
          };

          // This runs when we update the text field
          ngModelCtrl.$parsers.push(function (viewValue) {
            return viewValue.replace(/[^0-9]/g, '').slice(0, 10);
          });

          // This runs when the model gets updated on the scope directly and keeps our view in sync
          ngModelCtrl.$render = function () {
            $element.val($filter('tel')(ngModelCtrl.$viewValue, false));
          };

          $element.bind('change', listener);
          $element.bind('keydown', function (event) {
            var key = event.keyCode;
            // If the keys include the CTRL, SHIFT, ALT, or META keys, or the arrow keys, do nothing.
            // This lets us support copy and paste too
            if (key == 91 || (15 < key && key < 19) || (37 <= key && key <= 40)) {
              return;
            }
            $browser.defer(listener); // Have to do this or changes don't get picked up properly
          });

          $element.bind('paste cut', function () {
            $browser.defer(listener);
          });
        },
      };
    },
  ])
  .directive('removeImg', [
    '$http',
    function ($http) {
      return {
        restrict: 'A',
        transclude: true,
        scope: { removeImg: '&' },
        link: function (scope, element, attrs) {
          $(element).addClass('remove-img-container');
        },
        template:
          '<ng-transclude></ng-transclude>' +
          '<span class="glyphicon glyphicon-minus remove-img" ng-click="removeImg()"></span>',
      };
    },
  ]);
