(function () {
  angular.module('edit-logic', []).controller('EditLogicCtrl', [
    '$scope',
    '$log',
    '$timeout',
    'growl',
    function ($scope, $log, $timeout, growl) {
      $scope.modalContext = $scope.modalContext || 'Save';
      $scope.logic = $scope.logic ? angular.copy($scope.logic) : {};
      $scope.fields = $scope.fields ? angular.copy($scope.fields) : [];
      $scope.preview = $scope.preview ? $scope.preview : null;

      $scope.cancel = function () {
        $scope.$dismiss();
      };

      $scope.save = function () {
        if ($scope.isValidAudienceLogic($scope.logic)) {
          $scope.$close($scope.logic);
        } else {
          growl.error(
            'All the query fields must be filled out, and at least one condition must be added.'
          );
          return;
        }
      };

      $scope.previewLogic = function () {
        if ($scope.preview) {
          if ($scope.isValidAudienceLogic($scope.logic)) {
            $scope.preview($scope.logic);
          } else {
            growl.error(
              'All the query fields must be filled out, and every subexpression must have at least one condition.'
            );
            return;
          }
        }
      };

      $scope.clear = function () {
        $scope.logic = {
          operator: 'AND',
          rules: [],
        };
      };

      $scope.hasNoRules = function (logic) {
        return !logic || !logic.rules || !logic.rules.length;
      };

      $scope.isValidAudienceLogic = function (logic) {
        if (!logic || !logic.rules || !logic.operator) return false;
        if (!logic.rules.length) return true;
        return computeLogic(logic);
      };

      function computeLogic(logic) {
        if (!logic || !logic.operator || !logic.rules || !logic.rules.length) return false;
        var valid = true;
        var i = 0;
        while (i < logic.rules.length && valid) {
          if (logic.rules[i].logic) {
            if (!computeLogic(logic.rules[i].logic)) {
              valid = false;
            }
          } else {
            if (
              !(
                logic.rules[i].field &&
                logic.rules[i].condition &&
                logic.rules[i].data &&
                logic.rules[i].data.length &&
                logic.rules[i].data[0].id
              )
            ) {
              valid = false;
            }
          }
          i++;
        }
        return valid;
      }
    },
  ]);
})();
