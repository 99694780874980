import { useMemo } from 'react';
import { ItemTableColumn } from '../../shared/itemTable/definitions';
import generateSurveyInstanceApi from '../api/surveyInstanceApi';
import { SurveyInstance } from '../definitions';
const SurveyInstanceColumns: ItemTableColumn<SurveyInstance>[] = [
  { label: 'Name', valueField: 'name', sortable: true, initialSort: 'ASC' },
];
export default function useSurveyInstanceConfigurations(surveyId: string) {
  const columns = useMemo(() => SurveyInstanceColumns, [SurveyInstanceColumns]);
  const api = useMemo(
    () => generateSurveyInstanceApi(surveyId),
    [surveyId, generateSurveyInstanceApi]
  );

  return { columns, api };
}
