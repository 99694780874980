import React from 'react';
import { Action } from '../../definitions';
import ActionButton from '../../actionButton/components/ActionButton';

interface CmsPageSectionFooterProps {
  actions: Action[];
}

const CmsPageSectionFooter: React.FC<CmsPageSectionFooterProps> = (
  props: CmsPageSectionFooterProps
) => {
  const { actions } = props;

  return (
    <div className="col-xs-12 action-buttons">
      {actions &&
        actions.map((action, index) => (
          <ActionButton
            key={index}
            label={action.label}
            variant={action.variant}
            disabled={action.disabled}
            onClick={action.onClick}
          />
        ))}
    </div>
  );
};

export default CmsPageSectionFooter;
