import React from 'react';
import { TargetAudienceEditorForm, TargetAudienceEditorFormProps } from '../definitions';
import FormInput from '../../shared/form/components/FormInput';
import AudienceSelector from '../../shared/form/components/AudienceSelector';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';

const TargetAudienceEditorForm: TargetAudienceEditorForm = (
  props: TargetAudienceEditorFormProps
) => {
  const { form, growlId, actions } = props;

  return (
    <div className="row">
      <form role="form">
        <FormInput
          label="Name"
          fieldName="name"
          formContext={form}
          formFieldOptions={{ required: 'This field is required.' }}
        />

        <AudienceSelector
          formContext={form}
          label="Available To"
          fieldName="condition"
          growlId={growlId}
          isRequired={true}
        />
      </form>
      <div className="row module-footer">
        <CmsPageSectionFooter actions={actions} />
      </div>
    </div>
  );
};

export default TargetAudienceEditorForm;
