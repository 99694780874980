import { CmsAsset } from '../../shared/cms/definitions';
import { EMPTY_MENU_ITEM_REVISION, MenuItem, MenuItemRevision } from '../definitions';
import axios from 'axios';

const baseUrl = '/plugins/menu-manager/api';

const loadRecords = async (menuId: string) => {
  let result: MenuItem[] = [];
  try {
    if (menuId) {
      const { data } = await axios.get(`${baseUrl}/getMenuItems`, {
        params: {
          menuId,
        },
        timeout: 10000,
      });

      const { menuItems } = data;
      result = menuItems;
    }
  } catch (e) {
    console.error(e);
    throw new Error('Failed to load menu items');
  }

  return result;
};

const loadRecord = async (id: string, parentItemId: string) => {
  if (id === 'new') {
    return { ...EMPTY_MENU_ITEM_REVISION, menu_id: parentItemId };
  } else {
    try {
      const { data } = await axios.get(`${baseUrl}/getMenuItem`, {
        params: {
          id,
        },
        timeout: 10000,
      });

      return { ...data.menuItem, menu_id: parentItemId };
    } catch (e) {
      console.error(e);
      throw new Error('Failed to load menu item.');
    }
  }
};

const saveRecord = async (item: MenuItemRevision) => {
  try {
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    const { id, ...revisionProps } = item;
    const { data } = await axios.post(
      `${baseUrl}/saveMenuItem`,
      {
        id: item.menu_item_id,
        ...revisionProps,
      },
      {
        timeout: 10000,
      }
    );

    return data.menu;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to save menu item.');
  }
};

const removeRecord = async (item: MenuItem) => {
  try {
    const { id } = item;

    await axios.post(
      `${baseUrl}/removeMenuItem`,
      {
        id,
      },
      {
        timeout: 10000,
      }
    );
  } catch (e) {
    console.error(e);
    throw new Error('Failed to remove menu item.');
  }
};

const restoreRecord = async (item: MenuItem) => {
  try {
    const { id } = item;

    await axios.post(
      `${baseUrl}/restoreMenuItem`,
      {
        id,
      },
      {
        timeout: 10000,
      }
    );
  } catch (e) {
    console.error(e);
    throw new Error('Failed to restore menu.');
  }
};

const publishRecords = async (itemIds: string[]) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/publishMenuItems`,
      {
        itemIds,
      },
      {
        timeout: 10000,
      }
    );

    const { menuItems } = data;
    return menuItems;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to publish menu items.');
  }
};

const loadMenuIcons = async (): Promise<CmsAsset[]> => {
  try {
    const { data } = await axios.get(`${baseUrl}/getMenuIcons`, {
      timeout: 10000,
    });
    const { iconAssets } = data;
    return iconAssets;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to get menu icons.');
  }
};

const menuItemApi = {
  loadRecords,
  loadRecord,
  saveRecord,
  removeRecord,
  restoreRecord,
  publishRecords,
  loadMenuIcons,
};

export default menuItemApi;
