angular.module('user-api-service', []).service('userApiService', [
  '$q',
  '$log',
  'Upload',
  '$http',
  '$rootScope',
  'configService',
  'pluginService',
  function ($q, $log, Upload, $http, $rootScope, configService, pluginService) {
    var appUserMap = {};

    function getAllUsers() {
      var promise = $http
        .get('/user-api/getAllUsers')
        .error(function (data, status, headers, config) {
          $log.debug('Failed to get all appUsers. Response: ', data);
        });

      return promise;
    }

    function getUsers(start, number, params) {
      var sortPredicate;
      var sortReverse;
      var searchPredicate;
      if (params) {
        sortPredicate = params.sort.predicate;
        sortReverse = params.sort.reverse;
        searchPredicate = params.search.predicateObject;
        if (searchPredicate) {
          searchPredicate = searchPredicate.lastName;
        }
      }

      var httpParams = {
        params: {
          start: start,
          number: number,
          sortPredicate: sortPredicate,
          sortReverse: sortReverse,
          searchPredicate: searchPredicate,
        },
      };

      var promise = $http
        .get('/user-api/getUsers', httpParams)
        .success(function (data, status, headers, config) {
          rebuildUserMap(data.appUsers);
        })
        .error(function (data, status, headers, config) {
          $log.debug('Failed to get all appUsers. Response: ', data);
        });

      return promise;
    }

    function rebuildUserMap(appUsers) {
      appUserMap = {};
      for (var i = 0; i < appUsers.length; i++) {
        var appUser = appUsers[i];
        appUserMap[appUser.id] = appUser;
      }
    }

    function getUserMetadata(id) {
      return appUserMap[id];
    }

    function saveUser(user) {
      var data = {
        id: user.id,
        accessCode: user.accessCode,
        userType: user.userType,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        userGroups: user.userGroups,
        subtenantId: user.subtenantId,
      };

      var promise = $http.post('/user-api/upsertUser', data);

      promise.then(function (result) {
        appUserMap[user.id] = result.data.appUser;
        broadcastUserUpdated(result.data.appUser);
      });

      return promise;
    }

    function sendInviteEmail(user) {
      var data = {
        id: user.id,
      };

      return $http.post('/user-api/sendInviteEmail', data);
    }

    function removeUser(appUser) {
      var data = {
        id: appUser.id,
      };

      var promise = $http.post('/user-api/deleteUser', data);

      promise.then(function (result) {
        delete appUserMap[appUser.id];

        broadcastUserUpdated(appUser);
      });

      return promise;
    }

    function getUserGroups() {
      return $http.get('/user-api/getAllGroups').then(function (result) {
        return result.data.groups;
      });
    }

    async function getCohorts() {
      const hasCohorts = await pluginService.pluginExists('cohorts');
      if (hasCohorts) {
        const result = await $http.get('/plugins/cohorts/api/getCohorts?limit=0');
        return result.data.cohorts;
      } else {
        return undefined;
      }
    }

    function getUsersFromLogic(audienceLogic, start, number, params, limitToPushEndpoints) {
      var searchPredicate;
      if (params) {
        searchPredicate = params.search.predicateObject;
        if (searchPredicate) {
          searchPredicate = searchPredicate.lastName;
        }
      }

      var data = {
        logic: audienceLogic,
        start: start,
        number: number,
        searchPredicate: searchPredicate,
        withPushEndpoints: limitToPushEndpoints,
      };

      var promise = $http
        .post('/user-api/getAllUsersFromLogic', data)
        .success(function (data, status, headers, config) {})
        .error(function (data, status, headers, config) {
          $log.debug('Failed to get all appUsers from logic. Response: ', data);
        });

      return promise;
    }

    function broadcastUserUpdated(appUser) {
      $rootScope.$broadcast('userUpdated', appUser.id);
    }

    function getUserConfig() {
      return $q
        .all([configService.getConfig(), getUserGroups(), getCohorts()])

        .then(function ([config, userGroups, cohorts]) {
          config.userConfig.userGroups = userGroups;
          config.userConfig.cohorts = cohorts;

          return config.userConfig;
        });
    }

    return {
      removeUser: removeUser,
      saveUser: saveUser,
      getUserMetadata: getUserMetadata,
      getUsers: getUsers,
      getUserConfig: getUserConfig,
      sendInviteEmail: sendInviteEmail,
      getUsersFromLogic: getUsersFromLogic,
      getAllUsers: getAllUsers,
    };
  },
]);
