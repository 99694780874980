(function () {
  angular
    .module('manage-assets', [
      'smart-table',
      'content-api-service',
      'instance-service',
      'confirm-dialog',
    ])
    .filter('filterAssets', [
      'CMS_INSTANCES',
      'ASSET_FILTER_OPTIONS',
      function (CMS_INSTANCES, ASSET_FILTER_OPTIONS) {
        return function (assets, activeInstance, selectedFilterOption, showPluginAssets) {
          var filtered = [];

          if (assets) {
            for (var k = 0; k < assets.length; k++) {
              var asset = assets[k];
              var group = asset.latestRevision ? asset.latestRevision.group : asset.group;
              group = group ? group : '';

              var isPluginAsset = isPluginGroup(group);
              var isAssetMetadataLookup = asset.id === 'assetMetaDataLookup.json';

              let skipAsset = isAssetMetadataLookup || (isPluginAsset && !showPluginAssets);
              if (!skipAsset) {
                switch (selectedFilterOption) {
                  case ASSET_FILTER_OPTIONS.ALL:
                    filtered.push(asset);
                    break;
                  case ASSET_FILTER_OPTIONS.CHANGED:
                    if (asset.isModified) {
                      filtered.push(asset);
                    }
                    break;
                  default:
                    if (asset.isActiveInInstance) {
                      filtered.push(asset);
                    }
                    break;
                }
              }
            }
          }

          return filtered;
        };
      },
    ])
    .controller('ManageAssetsCtrl', [
      '$scope',
      '$modal',
      '$log',
      '$state',
      '$location',
      '$timeout',
      'growl',
      'CMS_INSTANCES',
      'ASSET_FILTER_OPTIONS',
      'contentApiService',
      'instanceService',
      'confirmDialog',
      '$filter',
      function (
        $scope,
        $modal,
        $log,
        $state,
        $location,
        $timeout,
        growl,
        CMS_INSTANCES,
        ASSET_FILTER_OPTIONS,
        contentApiService,
        instanceService,
        confirmDialog,
        $filter
      ) {
        /************* Initialization ************/
        $scope.records = [];
        $scope.originalRecords = [];
        $scope.showPluginAssets = localStorage.showPluginAssets === 'true';

        $scope.updatesFrozen = true;

        contentApiService.getUpdatesFrozen().then(function (updatesFrozen) {
          $scope.updatesFrozen = updatesFrozen;
        });

        $scope.groups = contentApiService.getGroups();

        $scope.instanceService = instanceService;

        loadRecords();
        $scope.$on('contentUpdated', loadRecords);
        $scope.$on('defaultContentUpdated', loadRecords);
        $scope.$on('cmsInstanceChanged', function () {
          loadRecords();
          if (!$scope.allowFilterOption($scope.selectedFilterOption)) {
            $scope.setFilterOption(ASSET_FILTER_OPTIONS.DEFAULT);
          }
        });

        function loadRecords() {
          $timeout(function () {
            $scope.originalRecords = [];
            updateAllSelected();

            contentApiService
              .getAllAssets()
              .success(function (data, status, headers, config) {
                $log.debug('Successfully got all assets. Response: ', data);
                $scope.originalRecords = data.assets;

                for (var i = 0; i < $scope.originalRecords.length; i++) {
                  var record = $scope.originalRecords[i];

                  setRecordStatus(record);
                }
                $scope.records = angular.copy($scope.originalRecords);
                $scope.runFilter();
                updateCanPublish();
              })
              .error(function (data, status, headers, config) {
                growl.error('Failed to load assets.');
                $log.debug('Failed to get all assets. Response: ', data);
              });
          });
        }

        function setRecordStatus(record) {
          var isModified = false;
          var isOverriddenInSubtenant = false;
          var activeInstance = instanceService.getActiveInstance();

          var activeRevision = getActiveRevision(record);
          var liveRevision = contentApiService.getRevisionForInstance(
            record,
            CMS_INSTANCES.live.id
          );

          var isDefaultSubtenant = $scope.defaultSubtenantIsSelected;

          var activeInstanceMapping = record.instanceMappings[activeInstance.id];
          var overriddenInSubtenant =
            activeInstanceMapping &&
            activeInstanceMapping.subtenantId !== $scope.defaultSubtenant.id;

          if (activeInstance !== CMS_INSTANCES.live) {
            if (activeRevision && liveRevision) {
              // Different ids == something changed
              isModified = activeRevision.id !== liveRevision.id;
            } else {
              // At least one exists but not the other == something changed
              isModified = activeRevision || liveRevision;
            }
          }

          record.isModified = isModified;
          record.isOverriddenInSubtenant = overriddenInSubtenant;
          record.isActiveInInstance = !!activeRevision;
          record.isHidden = !record.isModified && !record.isActiveInInstance;
        }

        /********** Content Filtering ************/
        $scope.filterOptions = [];
        for (var key in ASSET_FILTER_OPTIONS) {
          $scope.filterOptions.push(ASSET_FILTER_OPTIONS[key]);
        }

        $scope.setFilterOption = function (option) {
          $scope.selectedFilterOption = option;
          localStorage.assetFilterOption = option.id;
          $scope.runFilter();
        };
        $scope.allowFilterOption = function (option) {
          return (
            instanceService.getActiveInstance() == CMS_INSTANCES.sandbox || !option.sandboxOnly
          );
        };
        $scope.toggleShowPluginAssets = function () {
          $scope.showPluginAssets = !$scope.showPluginAssets;
          localStorage.showPluginAssets = $scope.showPluginAssets;
          $scope.runFilter();
        };

        $scope.runFilter = function () {
          $scope.records = $filter('filterAssets')(
            $scope.originalRecords,
            $scope.instanceService.getActiveInstance(),
            $scope.selectedFilterOption,
            $scope.showPluginAssets
          );

          if ($scope.selection) {
            setAllSelected(false);
          }
        };

        var initialFilterOption = localStorage.assetFilterOption
          ? ASSET_FILTER_OPTIONS[localStorage.assetFilterOption]
          : null;
        if (!initialFilterOption || !$scope.allowFilterOption(initialFilterOption)) {
          initialFilterOption = ASSET_FILTER_OPTIONS.DEFAULT;
        }

        $scope.setFilterOption(initialFilterOption);

        $scope.canToggleFreezeUpdates = function () {
          return $scope.defaultSubtenantIsSelected;
        };

        $scope.toggleFreezeUpdates = function () {
          $scope.updatesFrozen = !$scope.updatesFrozen;
          confirmDialog({
            title: $scope.updatesFrozen ? 'Disable Updates' : 'Enable Updates',
            body: $scope.updatesFrozen
              ? 'No further changes will be sent to devices until updates are unfrozen.'
              : 'Devices will resume synchronization with published content.',
            confirmText: $scope.updatesFrozen ? 'Disable' : 'Enable',
            cancelText: 'Cancel',
          }).result.then(
            function () {
              contentApiService.setUpdatesFrozen($scope.updatesFrozen).error(function () {
                growl.error(
                  $scope.updatesFrozen ? 'Failed to disable updates.' : 'Failed to enable updates.'
                );
                resetToggle();
              });
            },
            function () {
              // Cancelled.
              resetToggle();
            }
          );

          function resetToggle() {
            // Need two timeouts.  One to register the change above and one to register the change back.
            $timeout(function () {
              $timeout(function () {
                $scope.updatesFrozen = !$scope.updatesFrozen;
              });
            });
          }
        };

        /********** Table Helpers ***********/
        $scope.getters = {
          status: function (value) {
            var activeRevision = getActiveRevision(value);
            if (activeRevision) {
              return $scope.isDefaultRevision(value) ? 'Default' : 'Overridden';
            } else {
              return 'Removed';
            }
          },
          group: function (value) {
            var activeRevision = getActiveRevision(value, true);
            var groupLabel = $scope.groups['default'].label;

            if (activeRevision) {
              if (isPluginGroup(activeRevision.group)) {
                var pos = activeRevision.group.indexOf('/');
                groupLabel = 'Plugin: ' + activeRevision.group.substring(pos + 1);
              } else {
                var group = $scope.groups[activeRevision.group];
                if (group) {
                  groupLabel = group.label;
                }
              }
            }

            return groupLabel;
          },
        };

        $scope.isDefaultRevision = function (record) {
          var activeRevision = getActiveRevision(record);

          if ($scope.selectedSubtenant.isDefault) {
            return !activeRevision || activeRevision.id === -1;
          } else {
            return !record.isOverriddenInSubtenant;
          }
        };

        $scope.getRevertLabel = function (record) {
          return record.hasDefault ? 'Revert to default' : 'Remove';
        };

        function getActiveRevision(record, useLatestAsDefault) {
          return contentApiService.getRevisionForInstance(
            record,
            instanceService.getActiveInstance().id,
            useLatestAsDefault
          );
        }

        /************ Content Editing *************/
        $scope.openFile = function openEditor(record) {
          if ($scope.hasEditor(record)) {
            $scope.getEditor(record);
          } else {
            window.open(getActiveRevision(record, true).previewPath);
          }
        };

        $scope.hasEditor = function hasEditor(record) {
          var editor = contentApiService.getEditor(record.type);
          return !!editor;
        };

        $scope.getEditor = function getEditor(record) {
          var editor = contentApiService.getEditor(record.type);
          if (editor) {
            var editable = !record.isHidden && instanceService.getActiveInstance().editable;
            $state.go(editor, { assetId: record.id, editable: editable ? 1 : 0 });
          }
          return false;
        };

        /************ Upsert Asset ************/
        $scope.upsertFile = function (assetToReplace) {
          var options = {
            templateUrl: 'modules/manageContent/editFileModal.html',
            controller: 'EditFileCtrl',
          };

          if (assetToReplace) {
            options.scope = $scope.$new();
            options.scope.asset = assetToReplace;
            options.scope.replaceMode = true;
          }

          var modalInstance = $modal.open(options);
          modalInstance.result.then(
            function (result) {
              growl.success('Asset uploaded successfully');
              addOrReplaceAsset(result.asset);
            },
            function () {}
          );
        };

        //add to the real data holder
        function addOrReplaceAsset(newAsset) {
          if (!newAsset.id) {
            return false;
          }

          setRecordStatus(newAsset);

          var assetIndex = getAssetIndex(newAsset);
          if (assetIndex !== -1) {
            angular.extend($scope.originalRecords[assetIndex], newAsset);
          } else {
            $scope.originalRecords.unshift(newAsset);
          }
          $scope.runFilter();

          updateCanPublish();
        }

        /*********** Remove override ***********/
        $scope.removeOverride = function (record) {
          confirmDialog({
            title: record.hasDefault ? 'Confirm revert' : 'Confirm remove',
            body: record.hasDefault
              ? 'Are you sure you want to revert to the default revision of this asset?'
              : 'Are you sure you want to remove this asset?',
            confirmText: record.hasDefault ? 'Revert' : 'Remove',
            cancelText: 'Cancel',
          }).result.then(function (result) {
            contentApiService.removeOverride(record).then(
              function (result) {
                if (result.data.asset) {
                  addOrReplaceAsset(result.data.asset);
                } else {
                  removeAsset(record);
                }
              },
              function () {
                growl.error('Failed to remove override');
              }
            );
          });

          //remove to the real data holder
          function removeAsset(assetToRemove) {
            delete assetToRemove.instanceMappings[instanceService.getActiveInstance().id];
            setRecordStatus(assetToRemove);
            updateCanPublish();
          }
        };

        /*********** Recover Asset **************/
        $scope.recoverAsset = function (record) {
          contentApiService.restoreRevision(record, record.latestRevision.id).then(
            function (result) {
              addOrReplaceAsset(result.data.asset);
            },
            function () {
              growl.error('Failed to recover asset');
            }
          );
        };

        /********** Publishing Content ************/
        function updateCanPublish() {
          $scope.canPublish = false;
          for (var i = 0; i < $scope.originalRecords.length; i++) {
            var record = $scope.originalRecords[i];
            if (record.isModified && record.selected) {
              $scope.canPublish = true;
              break;
            }
          }
        }

        $scope.publishContent = function () {
          confirmDialog({
            title: 'Publish to Live Instance',
            body: 'Are you sure you want to publish all selected items to the Live instance? All users will receive updated content the next time they visit the app.',
            confirmText: 'Publish',
            cancelText: 'Cancel',
          }).result.then(function () {
            contentApiService.publishContent(getSelectedAssetIds()).then(
              function () {
                growl.success('Content published successfully.');
              },
              function () {
                growl.error('Failed to publish content.');
              }
            );
          });
        };

        /*********** Record lookup/management ***********/
        function getAssetIndex(asset) {
          if (!asset.id) {
            return -1;
          }
          var index = -1;
          angular.forEach($scope.originalRecords, function (record, key) {
            if (record.id == asset.id) {
              index = key;
            }
          });
          return index;
        }

        /************ Selection Stuff ************/
        $scope.selection = {
          all: false,
          any: false,
        };

        $scope.toggleAllSelected = () => {
          $timeout(toggleAllSelected);
        };
        $scope.updateAllSelected = () => {
          $timeout(updateAllSelected);
        };

        function setAllSelected(select) {
          var recordsToSelect = select ? $scope.records : $scope.originalRecords;

          angular.forEach(recordsToSelect, function (record) {
            if (record.isModified) {
              record.selected = select;
            }
          });
          updateAllSelected();
        }

        function toggleAllSelected() {
          setAllSelected($scope.selection.all);
        }

        function updateAllSelected() {
          var allSelected = $scope.records.length > 0;
          var anySelected = false;

          angular.forEach($scope.records, function (record) {
            if (record.isModified) {
              if (!record.selected) {
                allSelected = false;
              } else {
                anySelected = true;
              }
            }
          });

          $scope.selection.all = anySelected && allSelected;
          $scope.selection.any = anySelected;

          updateCanPublish();
        }

        function getSelectedAssetIds() {
          var selectedItems = [];

          angular.forEach($scope.originalRecords, function (record) {
            if (record.selected) {
              selectedItems.push(record.id);
            }
          });

          return selectedItems;
        }
      },
    ]);

  function isPluginGroup(group) {
    return group.indexOf('plugins') === 0 || group.indexOf('pluginData') === 0;
  }
})();
