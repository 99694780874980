import React, { useCallback, useMemo } from 'react';
import { ItemTableColumn, PaginationState, SortOrder, SortState } from '../definitions';
import PaginationControl from './PaginationControl';
import ItemTableHeaderCell from './ItemTableHeaderCell';
import ItemTableRow from './ItemTableRow';
import { getColumnSortOrder } from '../itemTableUtils';
import { Item } from '../../form/definitions';
import { ItemContextAction } from '../../cmsPage/definitions';

interface ItemTableProps<I extends Item> {
  items: I[];
  columns: ItemTableColumn<I>[];
  itemActions?: ItemContextAction<I>[];
  pagination?: PaginationState;
  sort?: SortState<I>;
  onPageSelected?: (page: number) => void;
  onSortChange?: (sort: SortState<I>) => void;
}

const ItemTable = <I extends Item>(props: ItemTableProps<I>) => {
  const { items, columns, itemActions, pagination, sort, onPageSelected, onSortChange } = props;

  const columnCount = useMemo(() => {
    return columns.length + (itemActions ? 1 : 0);
  }, [columns, itemActions]);

  const onColumnClick = useCallback(
    (column, sort) => {
      const sortField = column.valueField;

      const order = getColumnSortOrder(column, sort);
      const newOrder: SortOrder = order === 'ASC' ? 'DESC' : 'ASC';

      onSortChange({ sortField: sortField, order: newOrder });
    },
    [getColumnSortOrder, onSortChange]
  );

  return (
    <table className="table table-striped">
      <thead>
        <tr>
          {columns.map((column, index) => (
            <ItemTableHeaderCell
              key={index}
              column={column}
              onClick={() => onColumnClick(column, sort)}
              sortOrder={getColumnSortOrder(column, sort)}
            />
          ))}
          {itemActions?.length > 0 && <th />}
        </tr>
      </thead>
      {items.length > 0 ? (
        <>
          <tbody>
            {items.map((item, itemIndex) => (
              <tr key={itemIndex}>
                <ItemTableRow
                  key={itemIndex}
                  item={item}
                  columns={columns}
                  itemActions={itemActions}
                />
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={columnCount} className="text-center small-footer">
                <PaginationControl {...pagination} onPageSelected={onPageSelected} />
              </td>
            </tr>
          </tfoot>
        </>
      ) : (
        <tbody>
          <tr>
            <td className="table-message" colSpan={columns.length}>
              There are no items that match your criteria.
            </td>
            {itemActions && <td />}
          </tr>
        </tbody>
      )}
    </table>
  );
};

export default ItemTable;
