import { useContext, useMemo } from 'react';
import { CmsContext } from '../../context/definitions';
import { WrappedActionParams } from '../../shared/cmsPage/definitions';
import useItemListActions from '../../shared/cmsPage/hooks/useItemListActions';
import collectionApi from '../api/collectionApi';
import { Collection, CollectionConstants, CollectionRevision } from '../definitions';

type ShowCollectionModalCallback = (collection: CollectionRevision) => Promise<CollectionRevision>;

const useCollectionListActions = (showCollectionEditorModal: ShowCollectionModalCallback) => {
  const { navigator } = useContext(CmsContext);

  const { add, edit, remove, restore, publish, requestPending } = useItemListActions(
    collectionApi,
    CollectionConstants
  );

  const addOverride = useMemo(() => {
    return {
      ...add,
      onClick: async ({ loaderParams }: WrappedActionParams<Collection>) => {
        const newCollection = await collectionApi.loadRecord('new');
        const savedItem = await showCollectionEditorModal(newCollection);
        if (savedItem) {
          navigator.navigateToChildPage(
            CollectionConstants.pluginId,
            CollectionConstants.editorPageName,
            CollectionConstants.defaultPageId,
            loaderParams,
            savedItem.collection_id
          );
        }
      },
    };
  }, [add, collectionApi, navigator, showCollectionEditorModal]);

  return useMemo(
    () => ({
      add: addOverride,
      edit,
      remove,
      restore,
      publish,
      requestPending,
    }),
    [addOverride, edit, remove, restore, publish, requestPending]
  );
};

export default useCollectionListActions;
