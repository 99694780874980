import angular from 'angular';
import get from 'lodash/get';
import { react2angular } from 'react2angular';
import createSharedContext from 'react2angular-shared-context';
import CohortListPage from './cohort/components/CohortListPage';
import CohortEditorPage from './cohort/components/CohortEditorPage';
import CohortMatchesPage from './cohort/components/CohortMatchesPage';
import CollectionEditorPage from './collection/components/CollectionEditorPage';
import CollectionItemEditorPage from './collection/components/CollectionItemEditorPage';
import CollectionItemPublishPage from './collection/components/CollectionItemPublishPage';
import CollectionListPage from './collection/components/CollectionListPage';
import CmsReactContext from './context/components/CmsReactContext';
import MeetsEditorPage from './meets/components/MeetsEditorPage';
import MeetsListPage from './meets/components/MeetsListPage';
import MenuEditorPage from './menu/components/MenuEditorPage';
import MenuItemEditorPage from './menu/components/MenuItemEditorPage';
import MenuItemPublishPage from './menu/components/MenuItemPublishPage';
import MenuListPage from './menu/components/MenuListPage';
import QuizInstanceEditorPage from './quizzes/components/QuizInstanceEditorPage';
import QuizInstancesPage from './quizzes/components/QuizInstancesPage';
import QuizzesListPage from './quizzes/components/QuizzesListPage';
import SampleComponent from './sample/components/SampleComponent';
import SurveyInstanceEditorPage from './surveys/components/SurveyInstanceEditorPage';
import SurveyInstancesPage from './surveys/components/SurveyInstancesPage';
import SurveysListPage from './surveys/components/SurveysListPage';
import TargetAudienceListPage from './target-audience/components/TargetAudienceListPage';
import TargetAudienceEditorPage from './target-audience/components/TargetAudienceEditorPage';
import PrivacyPage from './privacy/components/PrivacyPage';

const cmsReactContext = createSharedContext(CmsReactContext);
const module = angular
  .module('react-components', [])
  .component(
    'cmsReactContext',
    react2angular(cmsReactContext.component, [
      'cmsConfig',
      'selectedTenant',
      'selectedSubtenant',
      'navigateToState',
      'showLinkSearch',
      'showAudienceModal',
      'showImportDialog',
      'notifyContentUpdated',
      'children',
    ])
  );

const components = [
  ['collectionEditorPage', CollectionEditorPage, ['collectionId']],
  ['collectionItemEditorPage', CollectionItemEditorPage, ['collectionId', 'itemId']],
  ['collectionListPage', CollectionListPage],
  [
    'collectionItemPublishPage',
    CollectionItemPublishPage,
    ['collectionId', 'successGrowlId', 'returnPageId', 'returnPageName', 'returnPageLabel'],
  ],
  ['cohortListPage', CohortListPage],
  ['cohortEditorPage', CohortEditorPage, ['cohortId']],
  ['cohortMatchesPage', CohortMatchesPage, ['cohortId', 'credentialCode']],
  ['meetsEditorPage', MeetsEditorPage, ['meetId', 'meetType']],
  ['meetsListPage', MeetsListPage, ['meetType']],
  ['menuEditorPage', MenuEditorPage, ['menuId']],
  ['menuItemEditorPage', MenuItemEditorPage, ['menuId', 'itemId']],
  [
    'menuItemPublishPage',
    MenuItemPublishPage,
    ['menuId', 'successGrowlId', 'returnPageId', 'returnPageName', 'returnPageLabel'],
  ],
  ['menuListPage', MenuListPage],
  ['quizInstanceEditorPage', QuizInstanceEditorPage, ['quizId', 'quizInstanceId']],
  ['quizInstancesPage', QuizInstancesPage, ['quizId']],
  ['quizzesListPage', QuizzesListPage],
  ['sampleComponent', SampleComponent],
  ['surveyInstanceEditorPage', SurveyInstanceEditorPage, ['surveyId', 'surveyInstanceId']],
  ['surveyInstancesPage', SurveyInstancesPage, ['surveyId']],
  ['surveysListPage', SurveysListPage],
  ['targetAudienceListPage', TargetAudienceListPage],
  ['targetAudienceEditorPage', TargetAudienceEditorPage, ['targetAudienceId']],
  ['privacyPage', PrivacyPage, []],
];

components.forEach((componentConfig) => {
  const pageName = get(componentConfig, '[0]');
  const PageComponent = get(componentConfig, '[1]', componentConfig);
  const pageParams = get(componentConfig, '[2]', []);
  module.component(pageName, react2angular(cmsReactContext.use(PageComponent), pageParams));
});
