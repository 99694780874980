import { SelectHTMLAttributes } from 'react';
import { FileError, FileWithPath } from 'react-dropzone';
import { RegisterOptions, UseFormReturn } from 'react-hook-form';
import { Item } from '../../definitions';
import { PropsWithChildren } from 'react';

export interface FormImageProps<T extends Item> extends SelectHTMLAttributes<HTMLSelectElement> {
  label: string;
  fieldName: any;
  formFieldOptions?: RegisterOptions<T>;
  formContext: UseFormReturn<T>;
  acceptedFileTypes?: any;
  dropzoneSettings?: object;
  existingAssetIdField?: string;
  uploadButtonText?: string;
  defaultAssetId?: string;
  useCropper?: boolean;
  croppedRatio?: any;
}

export interface ImageFile extends FileWithPath {
  preview?: any;
  isCurrent?: boolean;
}
export interface RejectedFile {
  file: ImageFile;
  errors: [FileError];
}
export type ImageContextProviderProps = PropsWithChildren<{
  dropzoneSettings?: object;
  acceptedFileTypes?: [string];
  setFormValue?: any;
  currentAssetId?: string;
  defaultAssetId?: string;
  useCropper: boolean;
}>;

export const FILE_KEYS = [
  'CROPPED_CURRENT_FILE',
  'CROPPED_DEFAULT_FILE',
  'CURRENT_FILE',
  'DEFAULT_FILE',
];
