import React from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import CmsItemEditSection from '../../shared/cmsPage/components/CmsItemEditSection';
import { useEditPageTitle } from '../../shared/cmsPage/hooks/useEditPageTitle';
import { MenuEditorConstants } from '../definitions';
import MenuItemEditor from './MenuItemEditor';

interface MenuItemEditorPageProps {
  menuId: string;
  itemId: string;
}

const MenuItemEditorPage: React.FC<MenuItemEditorPageProps> = (props: MenuItemEditorPageProps) => {
  const { editorPageId } = MenuEditorConstants;
  const { menuId, itemId } = props;

  const { pageTitle, onItemChange } = useEditPageTitle(itemId, 'Menu Item', 'label');
  return (
    <CmsPage title={pageTitle}>
      <CmsItemEditSection pageId={editorPageId}>
        <MenuItemEditor
          menuId={menuId}
          itemId={itemId}
          onItemChange={onItemChange}
          growlId={editorPageId}
        />
      </CmsItemEditSection>
    </CmsPage>
  );
};

export default MenuItemEditorPage;
