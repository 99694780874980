import React, { PropsWithChildren, useMemo } from 'react';
import { CmsNavigator } from '../CmsNavigator';
import { CmsContext, CmsContextProps } from '../definitions';
import useGrowlProvider from '../../shared/growl/hooks/useGrowlProvider';
import ConfirmModal from '../../shared/modal/components/ConfirmModal';
import useConfirmModal from '../../shared/modal/hooks/useConfirmModal';
import { ConditionExpr, ImportConfig } from '../../shared/cms/definitions';

type CmsReactContext = CmsContextProps &
  PropsWithChildren<{
    navigateToState: (state: string, params: object) => void;
    showLinkSearch: (currentHref: string, allowEmbeddedMenus: boolean) => Promise<string>;
    showAudienceModal: (currentAudienceExpr?: ConditionExpr) => Promise<ConditionExpr>;
    notifyContentUpdated: () => void;
    showImportDialog: (config: ImportConfig) => Promise<string>;
  }>;

const CmsReactContext: React.FC<CmsReactContext> = (props: CmsReactContext) => {
  const {
    children,
    navigateToState,
    showLinkSearch,
    showAudienceModal,
    showImportDialog,
    notifyContentUpdated,
    cmsConfig,
    selectedSubtenant,
    selectedTenant,
  } = props;

  const { growlProvider, growlMap } = useGrowlProvider();
  const { confirm, confirmProps } = useConfirmModal();

  const cmsContext = useMemo(() => {
    return {
      cmsConfig,
      selectedSubtenant,
      selectedTenant,
      growlProvider,
      growlMap,
      confirm,
      navigator: new CmsNavigator(navigateToState),
      showLinkSearch,
      showAudienceModal,
      notifyContentUpdated,
      showImportDialog,
    };
  }, [
    growlProvider,
    growlMap,
    navigateToState,
    cmsConfig,
    selectedSubtenant,
    selectedTenant,
    showLinkSearch,
    showAudienceModal,
    showImportDialog,
  ]);

  return (
    <CmsContext.Provider value={cmsContext}>
      {children}

      <ConfirmModal {...confirmProps} />
    </CmsContext.Provider>
  );
};

export default CmsReactContext;
