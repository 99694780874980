import React, { useCallback } from 'react';
import { Action } from '../../definitions';
import Button from 'react-bootstrap/Button';

type ActionButtonProps = Action;

const ActionButton: React.FC<ActionButtonProps> = (props: ActionButtonProps) => {
  const { icon, label, disabled, onClick, variant } = props;

  const clickHandler = useCallback(() => {
    return onClick();
  }, [onClick]);

  return (
    <Button variant={variant} disabled={disabled} onClick={clickHandler}>
      {icon && <i className={icon}></i>}
      {label}
    </Button>
  );
};

export default ActionButton;
