import React from 'react';
import { Action } from '../../definitions';
import ActionButton from '../../actionButton/components/ActionButton';
import { checkFlagOrFunction } from '../../util/checkFlagOrFunction';

interface ActionListProps {
  className?: string;
  actions: Action[];
}

const ActionList = (props: ActionListProps) => {
  const { actions, className } = props;

  return (
    <div className={`action-buttons ${className || ''}`}>
      {actions.map((action, index) => {
        const { disabled, visible } = action;
        const itemDisabled = checkFlagOrFunction(disabled);
        const itemVisible = typeof visible === 'undefined' || checkFlagOrFunction(visible);

        return (
          itemVisible && (
            <ActionButton
              icon={action.icon}
              key={index}
              label={action.label}
              disabled={itemDisabled}
              onClick={() => action.onClick()}
            />
          )
        );
      })}
    </div>
  );
};

export default ActionList;
