import { useContext, useMemo } from 'react';
import { CmsContext } from '../../context/definitions';
import { Action } from '../../shared/definitions';
import { Cohort, CohortConstants, CohortMember } from '../definitions';
import {
  BackPageAction,
  ItemLoadInitiator,
  WrappedActionParams,
} from '../../shared/cmsPage/definitions';

interface UseCohortEditorActionsProps {
  pluginId: string;
  item: Cohort;
  loadItem: ItemLoadInitiator;
  cohortId: string;
  parentPageTitle: string;
  showItemEditorModal: (cohort: Cohort) => Promise<Cohort>;
}

export default function useCohortEditorActions(props: UseCohortEditorActionsProps) {
  const { pluginId, item, loadItem, cohortId, parentPageTitle, showItemEditorModal } = props;

  const {
    growlProvider: { success },
    navigator,
    showImportDialog,
  } = useContext(CmsContext);

  const editSettings: Action = useMemo(
    () => ({
      label: 'Cohort Settings',
      onClick: async () => {
        const saved = await showItemEditorModal(item);
        if (saved) {
          success(CohortConstants.editorPageId, 'Cohort settings saved successfully');
          await loadItem(cohortId);
        }
      },
      visible: true,
    }),
    [success, showItemEditorModal, item]
  );

  const back = useMemo(
    (): BackPageAction => ({
      pageName: 'Cohorts',
      onClick: () => {
        navigator.navigateToChildPage(pluginId, 'default', 'surveys-list', {
          search: '',
          limit: 25,
          offset: 0,
          sort: { sortField: 'title', order: 'ASC' },
        });
      },
    }),
    [navigator, parentPageTitle, pluginId]
  );

  const importMembers = useMemo(
    () => ({
      label: 'Import Members',
      onClick: async ({ reload }: WrappedActionParams<CohortMember>) => {
        await showImportDialog({
          title: 'Import Cohort Members',
          importType: 'cohortMemberImport',
          importParams: {
            cohortId,
          },
        });
        reload();
      },
    }),
    [cohortId, showImportDialog, success]
  );

  return { back, editSettings, importMembers };
}
