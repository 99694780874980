import { useRef, useState, useEffect, MouseEvent  } from 'react';

export default function useItemActionListVisible(listVisible: boolean) {

  const [isVisible, setIsVisible] = useState(listVisible);
  const ref = useRef(null);

  const clickHandler = (ev: any) => {
    if(!ref?.current?.contains(ev.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', clickHandler, true);
    return () => document.removeEventListener('click', clickHandler, true);
  });

  return { ref, isVisible, setIsVisible };
}