import axios from 'axios';
import forEach from 'lodash/forEach';
import omit from 'lodash/omit';
import { CmsAsset } from '../../shared/cms/definitions';
import {
  CollectionItem,
  CollectionItemRevision,
  EMPTY_COLLECTION_ITEM_REVISION,
} from '../definitions';

const baseUrl = '/plugins/collections/api';

const loadRecords = async (collectionId: string) => {
  let result: CollectionItem[] = [];
  try {
    if (collectionId) {
      const { data } = await axios.get(`${baseUrl}/getCollectionItems`, {
        params: {
          collectionId,
        },
        timeout: 10000,
      });

      const { collectionItems } = data;
      result = collectionItems;
    }
  } catch (e) {
    console.error(e);
    throw new Error('Failed to load collection items');
  }

  return result;
};

const loadRecord = async (id: string, parentItemId: string) => {
  if (id === 'new') {
    return { ...EMPTY_COLLECTION_ITEM_REVISION, collection_id: parentItemId };
  } else {
    try {
      const {
        data: { collectionItem: item },
      } = await axios.get(`${baseUrl}/getCollectionItem`, {
        params: {
          id,
        },
        timeout: 10000,
      });
      return {
        ...item,
        collection_id: parentItemId,
        actionButtonSelected: !!item.actionButtonText,
        infoButtonSelected: !!item.infoButtonText,
      };
    } catch (e) {
      console.error(e);
      throw new Error('Failed to load collection item.');
    }
  }
};

const saveRecord = async (item: CollectionItemRevision) => {
  try {
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    const formData = new FormData();
    forEach({ ...omit(item, 'id'), id: item.collection_item_id }, (v, k) => {
      if (k === 'tileAssetUpload') {
        formData.append(k, v);
      } else if (v && typeof v === 'object') {
        const value = JSON.stringify(v);
        formData.append(k, value);
      } else if (v) {
        formData.append(k, v);
      }
    });
    const { data } = await axios.post(`${baseUrl}/saveCollectionItem`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 10000,
    });

    return data.collectionItem;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to save collection item.');
  }
};

const removeRecord = async (item: CollectionItem) => {
  try {
    const { id } = item;

    await axios.post(
      `${baseUrl}/removeCollectionItem`,
      {
        id,
      },
      {
        timeout: 10000,
      }
    );
  } catch (e) {
    console.error(e);
    throw new Error('Failed to remove collection item.');
  }
};

const restoreRecord = async (item: CollectionItem) => {
  try {
    const { id } = item;

    await axios.post(
      `${baseUrl}/restoreCollectionItem`,
      {
        id,
      },
      {
        timeout: 10000,
      }
    );
  } catch (e) {
    console.error(e);
    throw new Error('Failed to restore collection.');
  }
};

const loadCollectionIcons = async (): Promise<CmsAsset[]> => {
  try {
    const { data } = await axios.get(`${baseUrl}/getCollectionIcons`, {
      timeout: 10000,
    });
    const { iconAssets } = data;
    return iconAssets;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to get collection icons.');
  }
};

const collectionItemApi = {
  loadRecords,
  loadRecord,
  saveRecord,
  removeRecord,
  restoreRecord,
  loadCollectionIcons,
};

export default collectionItemApi;
