import { useContext, useMemo } from 'react';
import { CmsContext } from '../../context/definitions';
import useLoadSubListItems from '../../shared/cmsPage/hooks/useLoadSubListItems';
import usePublishActionHandler from '../../shared/cmsPage/hooks/usePublishActionHandler';
import { Action } from '../../shared/definitions';
import collectionApi from '../api/collectionApi';
import { CollectionConstants, CollectionEditorConstants } from '../definitions';

interface UseCollectionEditorActionsProps {
  hasItemsPendingPublish: boolean;
  item: any;
  loadItem: any;
  loadItems: any;
  collectionId: string;
  pageTitle: string;
  publishDisabled: boolean;
  setHasReordered: any;
  showItemEditorModal: any;
}

export default function useCollectionEditorActions(props: UseCollectionEditorActionsProps) {
  const {
    hasItemsPendingPublish,
    item,
    loadItem,
    loadItems,
    collectionId,
    pageTitle,
    publishDisabled,
    setHasReordered,
    showItemEditorModal,
  } = props;

  const {
    growlProvider: { success },
  } = useContext(CmsContext);

  const editSettings: Action = useMemo(
    () => ({
      label: 'Collection Settings',
      onClick: async () => {
        const saved = await showItemEditorModal(item);
        if (saved) {
          success(
            CollectionEditorConstants.defaultPageId,
            'Collection settings saved successfully'
          );
          await loadItem(collectionId);
        }
      },
      visible: true,
    }),
    [success, showItemEditorModal, item]
  );

  const reload = useLoadSubListItems(
    CollectionEditorConstants.defaultPageId,
    collectionId,
    loadItems
  );

  const publishHandler = usePublishActionHandler(
    CollectionConstants.pluginId,
    CollectionConstants.typeName,
    CollectionConstants.publishPageName,
    CollectionEditorConstants.defaultPageId,
    CollectionEditorConstants.defaultPageName,
    pageTitle,
    CollectionEditorConstants.defaultPageId,
    collectionApi
  );

  const publish: Action = useMemo(
    () => ({
      label: 'Publish',
      disabled: publishDisabled,
      onClick: async () => {
        await publishHandler(collectionId, hasItemsPendingPublish, async () => {
          setHasReordered(false);
          await Promise.all([loadItem(collectionId), reload()]);
        });
      },
    }),
    [
      hasItemsPendingPublish,
      publishDisabled,
      publishHandler,
      reload,
      loadItem,
      collectionId,
      setHasReordered,
    ]
  );

  return { publish, editSettings };
}
