import { PageConstants } from '../shared/cmsPage/definitions';
import { Item } from '../shared/form/definitions';
import { ConditionExpr, ItemApi, PublishStatus } from '../shared/cms/definitions';
import { ItemEditorFormProps } from '../shared/cmsPage/definitions';

export const TargetAudienceConstants: PageConstants = {
  pluginId: 'target-audience',
  defaultPageName: 'default',
  defaultPageId: 'target-audience-list-page',
  typeName: 'TargetAudience',
  editorPageName: 'targetAudienceEditorPage',
};

export interface TargetAudienceRevision extends Item {
  id?: string;
  target_audience_id?: string;
  name: string;
  label?: string;
  condition?: ConditionExpr;
}

export interface TargetAudience extends Item {
  id?: string;
  updated?: Date;
  name?: string;
  condition?: ConditionExpr;
  editable?: boolean;
  isArchived?: boolean;
  status?: PublishStatus;
  sandboxRevision?: TargetAudienceRevision;
  liveRevision?: TargetAudienceRevision;
}

export interface TargetAudienceApi extends ItemApi<TargetAudience, TargetAudienceRevision> {
  archiveRecord?: (id: string) => Promise<void>;
  unarchiveRecord?: (id: string) => Promise<void>;
}

export const EMPTY_TARGET_AUDIENCE_REVISION: TargetAudienceRevision = {
  label: '',
  name: '',
  isArchived: false,
  condition: null,
};

export interface TargetAudienceEditorFormProps extends ItemEditorFormProps<TargetAudienceRevision> {
  growlId: string;
  actions: any;
}

export type TargetAudienceEditorForm = React.FC<TargetAudienceEditorFormProps>;
