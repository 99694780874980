angular
  .module('query-builder-directives', [])
  .directive('queryBuilder', [
    '$compile',
    function ($compile) {
      return {
        restrict: 'E',
        scope: {
          logic: '=',
          fields: '=',
        },
        templateUrl: 'shared/query-builder-directive-template.html',
        compile: function (element, attrs) {
          var content, directive;
          content = element.contents().remove();
          return function (scope, element, attrs) {
            scope.selectSettings = {
              selectionLimit: 1,
              enableSearch: true,
              selectedToTop: true,
              closeOnSelect: true,
              clearSearchOnClose: true,
              idProperty: 'id',
              template: '{{option.label}}',
              buttonDefaultText: 'Select Groups',
            };

            scope.selectEvents = {
              onSelectionChanged: function () {
                massageRules(scope.logic.rules);

                function massageRules(rules) {
                  rules.forEach(function (ruleItem) {
                    if (ruleItem.data) {
                      ruleItem.data.forEach(function (exprItem) {
                        var resolvedItem = scope.optionsById[ruleItem.field][exprItem.id];
                        if (resolvedItem) {
                          angular.copy(resolvedItem, exprItem);
                        }
                      });
                    } else if (ruleItem.logic) {
                      massageRules(ruleItem.logic.rules);
                    }
                  });
                }
              },
            };

            scope.operators = [{ name: 'AND' }, { name: 'OR' }];

            scope.conditions = [
              { name: 'IN', value: '=' },
              { name: 'NOT IN', value: '!=' },
            ];

            if (!scope.logic || !('rules' in scope.logic)) {
              scope.logic = {
                operator: scope.operators[0].name,
                rules: [
                  {
                    condition: scope.conditions[0].value,
                    field: scope.fields[0].name,
                    data: [],
                  },
                ],
              };
            }

            scope.optionsById = {};
            if (scope.fields && scope.fields[0] && scope.fields[0].options) {
              scope.options = {};
              angular.forEach(scope.fields, function (field) {
                scope.options[field.name] = field.options;
                scope.optionsById[field.name] = {};
                angular.forEach(field.options, function (option) {
                  scope.optionsById[field.name][option.id] = option;
                });
              });
            }

            scope.addCondition = function () {
              scope.logic.rules.push({
                condition: scope.conditions[0].value,
                field: scope.fields[0].name,
                data: [],
              });
            };

            scope.removeCondition = function (index) {
              scope.logic.rules.splice(index, 1);
            };

            scope.addSet = function () {
              scope.logic.rules.push({
                logic: {
                  operator: scope.operators[0].name,
                  rules: [],
                },
              });
            };

            scope.removeSet = function () {
              if ('logic' in scope.$parent) {
                scope.$parent.logic.rules.splice(scope.$parent.$index, 1);
              }
            };

            scope.updateOptions = function (rule) {
              rule.data = [];
            };

            if (!directive) {
              directive = $compile(content);
            }

            element.append(
              directive(scope, function ($compile) {
                return $compile;
              })
            );
          };
        },
      };
    },
  ])
  .service('queryExprToString', [
    function () {
      return function (queryExpr) {
        if (queryExpr.accessCodes || queryExpr.appUserIds) {
          return 'Custom';
        } else if (!queryExpr.rules) {
          return 'All Users';
        } else {
          return rulesToString(queryExpr.operator, queryExpr.rules);
        }

        function rulesToString(operator, rules) {
          var result = '';

          rules.forEach(function (ruleItem) {
            if (result.length > 0) {
              result += ' ' + operator.toLocaleLowerCase() + ' ';
            }

            if (ruleItem.data) {
              var itemString;
              if (ruleItem.field === 'Role') {
                itemString = ruleItem.condition === '=' ? 'is ' : 'is not ';
              } else {
                itemString = ruleItem.condition === '=' ? 'in ' : 'not in ';
              }

              var list = '';
              ruleItem.data.forEach(function (exprItem) {
                if (list.length > 0) {
                  list += ',';
                }
                list += exprItem.label;
              });

              itemString += list;

              result += itemString;
            } else if (ruleItem.logic) {
              var addParens = rules.length > 1 && ruleItem.logic.rules.length > 1;
              if (addParens) {
                result += '(';
              }

              result += rulesToString(ruleItem.logic.operator, ruleItem.logic.rules);

              if (addParens) {
                result += ')';
              }
            }
          });

          return result;
        }
      };
    },
  ]);
