import { useContext, useEffect } from 'react';
import { CmsContext } from '../../../context/definitions';
import { ItemLoadInitiator, PageConstants } from '../definitions';
import { Item } from '../../form/definitions';

const useLoadEditorItem = <I extends Item>(
  id: string,
  loadItem: ItemLoadInitiator,
  onItemLoaded: (i: Item) => void,
  item: I,
  pageConstants: PageConstants
) => {
  const { editorPageId } = pageConstants;

  const { growlProvider } = useContext(CmsContext);
  const { error } = growlProvider;

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          await loadItem(id);
        } catch (e) {
          error(editorPageId, e.message);
        }
      })();
    }
  }, [editorPageId, loadItem, id, error]);

  useEffect(() => {
    if (onItemLoaded) {
      onItemLoaded(item);
    }
  }, [item]);
};

export default useLoadEditorItem;
