(function () {
  angular.module('edit-subtenant', ['subtenant-api-service']).controller('EditSubtenantCtrl', [
    '$scope',
    '$log',
    '$timeout',
    'growl',
    'subtenantApiService',
    'messageService',
    'configService',
    function ($scope, $log, $timeout, growl, subtenantApiService, messageService, configService) {
      $scope.subtenant = $scope.subtenant ? angular.copy($scope.subtenant) : {};
      $scope.modeLabel = $scope.replaceMode ? 'edit' : 'add';

      $scope.showAffiliation = false;
      configService.getConfig().then(function (config) {
        $scope.showAffiliation = config.enableSubtenantAffiliation;
      });

      messageService
        .getMessage('cms.manageSubtenants.affiliationLabel')
        .then(function (affiliationLabel) {
          $scope.affiliationLabel = affiliationLabel;
        });

      $scope.cancel = function () {
        $scope.$dismiss();
      };

      $scope.save = function () {
        subtenantApiService
          .saveSubtenant($scope.subtenant)
          .success(function (data, status, headers, config) {
            if (data.status === 'error') {
              growl.error(data.error);
            } else {
              $scope.$close(data);
            }
          })
          .error(function (err, status, headers, config) {
            growl.error(err.error || 'Subtenant update failed.  Please try again later.');
          });
      };
    },
  ]);
})();
