import { useContext, useMemo } from 'react';
import { Item } from '../../form/definitions';
import { ItemApi } from '../../cms/definitions';
import { CmsContext } from '../../../context/definitions';
import useConfirmPublish from './useConfirmPublish';
import { useItemPersistence } from '../../cms/hooks/useItemPersistence';

const usePublishActionHandler = <I extends Item, R extends Item>(
  pluginId: string,
  typeName: string,
  publishPageName: string,
  returnPageId: string,
  returnPageName: string,
  returnPageLabel: string,
  growlId: string,
  itemApi: ItemApi<I, R>
) => {
  const { navigator, growlProvider } = useContext(CmsContext);
  const { success, error } = growlProvider;

  const confirmPublish = useConfirmPublish(typeName);

  const { publishItem } = useItemPersistence(itemApi);

  return useMemo(() => {
    let publishFn;

    if (publishItem) {
      publishFn = async (itemId: string, hasItemsToPublish: boolean, reload: () => void) => {
        if (hasItemsToPublish) {
          navigator.navigateToPluginPage(
            pluginId,
            publishPageName,
            itemId,
            returnPageId,
            returnPageName,
            returnPageLabel,
            growlId
          );
        } else {
          const confirmation = await confirmPublish();

          if (confirmation) {
            try {
              await publishItem(itemId);
              success(growlId, `The ${typeName} has been successfully published!`);
              await reload();
            } catch (e) {
              error(growlId, e.message);
            }
          }
        }
      };
    }

    return publishFn;
  }, [
    pluginId,
    publishItem,
    publishPageName,
    returnPageId,
    returnPageName,
    returnPageLabel,
    growlId,
    success,
    error,
  ]);
};

export default usePublishActionHandler;
