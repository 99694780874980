import React, { useCallback, useContext, useMemo } from 'react';
import { FieldPath, UnpackNestedValue, UseFormReturn } from 'react-hook-form';
import { Item } from '../definitions';
import classNames from 'classnames';
import { conditionToText } from '../../cms/cmsUtils';
import { CmsContext } from '../../../context/definitions';
import { FieldPathValue } from 'react-hook-form/dist/types/path';
import { bool } from 'aws-sdk/clients/signer';

interface AudienceSelectorProps<T extends Item> {
  label: string;
  fieldName: FieldPath<T>;
  formContext: UseFormReturn<T>;
  className?: string;
  growlId: string;
  isRequired?: bool;
}

const AudienceSelector = <T extends Item>(props: AudienceSelectorProps<T>) => {
  const {
    label,
    growlId,
    formContext,
    fieldName,
    className: providedClassName,
    isRequired = false,
  } = props;

  const { showAudienceModal, growlProvider } = useContext(CmsContext);
  const { error } = growlProvider;

  const { formState } = formContext;
  const { errors } = formState;

  const fieldError = errors[fieldName];

  if (isRequired) {
    formContext.register(fieldName as any, {
      required: true,
    });
  }

  const condition = formContext.getValues()[fieldName];

  const conditionText = useMemo(() => conditionToText(condition), [condition, conditionToText]);

  const selectCondition = useCallback(async () => {
    try {
      let newCondition = await showAudienceModal(condition);
      if (newCondition) {
        if (!newCondition?.rules || newCondition.rules.length === 0) {
          newCondition = null;
        }

        formContext.setValue(
          fieldName,
          newCondition as UnpackNestedValue<FieldPathValue<T, FieldPath<T>>>,
          {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          }
        );
      }
    } catch (err) {
      console.error(err);
      error(growlId, 'Failed to set target audience.');
    }
  }, [condition, growlId, formContext, error, showAudienceModal]);

  return (
    <div
      className={classNames('form-group', providedClassName, {
        'has-error': !!fieldError,
      })}
    >
      <label>
        {label}
        &nbsp;(
        <span className="link-button" onClick={selectCondition}>
          Select Target Condition
        </span>
        {')'}
        {isRequired && <span className="required-annotation">*</span>}
      </label>
      <div className="value-description">{conditionText}</div>
    </div>
  );
};

export default AudienceSelector;
