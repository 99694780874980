angular.module('admin-api-service', []).service('adminApiService', [
  '$q',
  '$log',
  'Upload',
  '$http',
  '$rootScope',
  '$timeout',
  'configService',
  function ($q, $log, Upload, $http, $rootScope, $timeout, configService) {
    var adminMap = {};

    function getTenantAdmins(start, number, params) {
      var sortPredicate;
      var sortReverse;
      var searchPredicate;
      if (params) {
        sortPredicate = params.sort.predicate;
        sortReverse = params.sort.reverse;
        searchPredicate = params.search.predicateObject;
        if (searchPredicate) {
          searchPredicate = searchPredicate.user_email;
        }
      }

      var httpParams = {
        params: {
          start: start,
          number: number,
          sortPredicate: sortPredicate,
          sortReverse: sortReverse,
          searchPredicate: searchPredicate,
        },
      };

      var promise = $http
        .get('/admin-api/getTenantAdmins', httpParams)
        .success(function (data, status, headers, config) {
          rebuildAdminMap(data.admins);
        })
        .error(function (data, status, headers, config) {
          $log.debug('Failed to get all admins. Response: ', data);
        });

      return promise;
    }

    function rebuildAdminMap(admins) {
      adminMap = {};
      for (var i = 0; i < admins.length; i++) {
        var admin = admins[i];
        adminMap[admin.user_email] = admin;
      }
    }

    function saveAdmin(admin) {
      var data = {
        user_email: admin.user_email,
        role: admin.role,
      };

      var promise = $http.post('/admin-api/upsertAdmin', data);

      promise.then(function (result) {
        adminMap[admin.user_email] = result.data.admin;
        broadcastAdminUpdated(result.data.admin);
      });

      return promise;
    }

    function removeAdmin(admin) {
      var data = {
        user_email: admin.user_email,
      };

      var promise = $http.post('/admin-api/deleteAdmin', data);

      promise.then(function (result) {
        delete adminMap[admin.user_email];

        broadcastAdminUpdated(admin);
      });

      return promise;
    }

    function resendInvite(admin) {
      var data = {
        user_email: admin.user_email,
      };

      var promise = $http.post('/admin-api/resendInvite', data);

      promise.then(function (result) {
        broadcastAdminUpdated(admin);
      });

      return promise;
    }

    function getRoles() {
      var deferred = $q.defer();

      $http
        .get('/admin-api/getAdminRoles')
        .success(function (result) {
          deferred.resolve(result.roles);
        })
        .error(function (data, status, headers, config) {
          deferred.reject('Failed to load admin roles');
        });

      return deferred.promise;
    }

    function broadcastAdminUpdated(admin) {
      $rootScope.$broadcast('adminUpdated', admin.user_email);
    }

    return {
      removeAdmin: removeAdmin,
      resendInvite: resendInvite,
      saveAdmin: saveAdmin,
      getTenantAdmins: getTenantAdmins,
      getRoles: getRoles,
    };
  },
]);
