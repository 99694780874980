import filter from 'lodash/filter';
import React, { useContext } from 'react';
import { FILE_KEYS, ImageFile, RejectedFile } from '../definitions';
import { ImageContext } from '../store';
import FileAlert from './FileAlert';
function Alerts() {
  const { availableFilesMap, rejectedFiles } = useContext(ImageContext);
  return (
    <>
      <div>
        {filter(availableFilesMap, (v, k) => !FILE_KEYS.includes(k)).map((file: ImageFile) => (
          <FileAlert key={file.path} file={file} variant="success" />
        ))}
      </div>
      <div>
        {rejectedFiles.map(({ file, errors }: RejectedFile) => (
          <FileAlert key={file.path} file={file} variant="danger" errors={errors} />
        ))}
      </div>
    </>
  );
}

export default Alerts;
