(function () {
  angular.module('list-users-modal', ['user-api-service']).controller('ListUsersModalCtrl', [
    '$scope',
    'userApiService',
    'growl',
    'configService',
    '$timeout',
    '$log',
    function ($scope, userApiService, growl, configService, $timeout, $log) {
      $scope.logic = $scope.logic ? angular.copy($scope.logic) : {};

      $scope.limitToPushEndpoints = false;

      $scope.close = function () {
        $scope.$dismiss();
      };

      $scope.callServer = function (tableState) {
        $scope.isLoading = true;
        $timeout(function () {
          $scope.users = [];
          $scope.displayedUsers = [];
        });

        $scope.tableState = tableState;

        var pagination = tableState.pagination;

        var start = pagination.start || 0; // This is NOT the page number, but the index of item in the list that you want to use to display the table.
        var number = pagination.number || 10; // Number of entries showed per page.

        getUsers(start, number, tableState);
      };

      $scope.onPushOptionChange = function () {
        $scope.tableState.pagination.start = 0;
        $scope.callServer($scope.tableState);
      };

      /************* Initialization ************/
      $scope.users = [];
      $scope.displayedUsers = [];
      $scope.numPages = 0;

      configService.getConfig().then(function (config) {
        $scope.userTypeMap = createUserTypeMap(config.userConfig);
      });

      function setNumberOfPages(count, start) {
        $scope.userCount = count;

        var numberOfPages = (count - (count % 10)) / 10 + 1;
        if ($scope.tableState) {
          $scope.tableState.pagination.numberOfPages = numberOfPages || 1; //set the number of pages so the pagination can update
        }

        $timeout(function () {
          $scope.tableState.pagination.start = start <= count ? start : 0;
        });
      }

      function getUsers(start, number, tableState) {
        userApiService
          .getUsersFromLogic($scope.logic, start, number, tableState, $scope.limitToPushEndpoints)
          .success(function (data) {
            $scope.users = data.appUsers;
            $scope.displayedUsers = [].concat($scope.users);

            setNumberOfPages(data.count, start);

            $scope.isLoading = false;
          })
          .error(function (data, status, headers, config) {
            growl.error('Failed to load users.');
            $log.debug('Failed to get users from logic. Response: ', data);
          });
      }

      function createUserTypeMap(userConfig) {
        var userTypeMap = {};

        for (var i = 0; i < userConfig.userTypes.length; i++) {
          var userType = userConfig.userTypes[i];
          userTypeMap[userType.value] = userType;
        }

        return userTypeMap;
      }

      $scope.getTypeLabel = function (user) {
        var userType = $scope.userTypeMap[user.userType];
        return userType ? userType.label : 'Undefined';
      };

      /********** User Groups *************/
      $scope.getGroupsList = function (user) {
        var result = '';
        for (var i = 0; i < user.userGroups.length; i++) {
          var group = user.userGroups[i];

          if (i > 0) {
            result += '\n';
          }

          result += group.name;
        }

        return result;
      };
    },
  ]);
})();
