angular.module('patch-http', []).run([
  '$http',
  function ($http) {
    var oldGet = $http.get;
    $http.get = function () {
      var promise = oldGet.apply($http, arguments);

      return patchHttpPromise(promise);
    };

    var oldPost = $http.post;
    $http.post = function () {
      var promise = oldPost.apply($http, arguments);
      return patchHttpPromise(promise);
    };

    function patchHttpPromise(promise) {
      promise.success = function (handler) {
        promise.then(function (response) {
          return handler(response.data, response.status, response.headers, response.config);
        });

        return promise;
      };

      promise.error = function (handler) {
        promise.catch(function (response) {
          return handler(response.data, response.status, response.headers, response.config);
        });

        return promise;
      };

      return promise;
    }
  },
]);
