import get from 'lodash/get';
import moment from 'moment';
import { useMemo } from 'react';
import { ItemTableColumn } from '../../shared/itemTable/definitions';
import generateSurveysApi from '../api/surveysApi';
import { Survey } from '../definitions';
const SurveyColumns: ItemTableColumn<Survey>[] = [
  { label: 'Title', valueField: 'title', sortable: true, initialSort: 'ASC' },
  {
    label: 'Last Modified',
    valueField: 'updated',
    sortable: true,
    renderer: (item) => moment(item.updated).format('MMMM DD, YYYY  hh:mm A'),
  },
  {
    label: 'Instances',
    valueField: 'instances',
    sortable: true,
  },
  {
    label: 'Archived',
    valueField: 'isArchived',
    sortable: true,
    renderer: (item) => (get(item, `isArchived`, false) ? 'Yes' : ''),
  },
];
export default function useSurveyConfigurations(includeArchived: boolean) {
  const columns = useMemo(
    () => SurveyColumns.filter(({ valueField }) => includeArchived || valueField !== 'isArchived'),
    [SurveyColumns, includeArchived]
  );
  const api = useMemo(
    () => generateSurveysApi(includeArchived),
    [includeArchived, generateSurveysApi]
  );

  return { columns, api };
}
