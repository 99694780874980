import React, { useContext, useEffect, useCallback } from 'react';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import { CmsPageContext, ItemEditorProps } from '../../shared/cmsPage/definitions';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import useLoadEditorItem from '../../shared/cmsPage/hooks/useLoadEditorItem';
import AudienceSelector from '../../shared/form/components/AudienceSelector';
import CreateableSelect from '../../shared/form/components/CreatableSelect';
import FormCheckInput from '../../shared/form/components/FormCheckInput';
import FormImage from '../../shared/form/components/FormImage';
import FormInput from '../../shared/form/components/FormInput';
import FormWysiwyg from '../../shared/form/components/FormWysiwyg';
import LinkFinder from '../../shared/form/components/LinkFinder';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import { useSpinner } from '../../shared/spinner/hooks/useSpinner';
import collectionApi, { loadCategories } from '../api/collectionApi';
import collectionItemApi from '../api/collectionItemApi';
import { Collection, CollectionEditorConstants, CollectionItemRevision } from '../definitions';

interface CollectionItemEditorProps extends ItemEditorProps<Collection> {
  collectionId: string;
  growlId: string;
}

const CollectionItemEditor: React.FC<CollectionItemEditorProps> = (
  props: CollectionItemEditorProps
) => {
  const { itemId, collectionId, growlId, onItemChange } = props;
  const { spinnerProvider } = useContext(CmsPageContext);

  const { item, loadItem, saveItem, requestPending } = useItemPersistence(
    collectionItemApi,
    collectionId
  );
  const form = useCmsForm<CollectionItemRevision>(item);
  const actions = useItemEditActions(saveItem, form, CollectionEditorConstants, collectionId);

  const { loadItem: loadCollection, requestPending: collectionRequestPending } =
    useItemPersistence(collectionApi);

  useEffect(() => {
    if (collectionId) {
      (async () => await loadCollection(collectionId))();
    }
  }, [collectionId, loadCollection]);

  useSpinner(spinnerProvider, requestPending || collectionRequestPending);
  useLoadEditorItem(itemId, loadItem, onItemChange, item, CollectionEditorConstants);
  const getCategories = useCallback(async () => {
    return loadCategories(collectionId);
  }, [collectionId]);
  return (
    <>
      <div className="row">
        <form role="form" className="form-control-margin">
          <FormInput
            label="Name"
            fieldName="label"
            formContext={form}
            formFieldOptions={{ required: 'This field is required.' }}
          />
          <LinkFinder
            formContext={form}
            label="Link Item to"
            fieldName="objectReference"
            required={true}
          />
          <FormCheckInput
            defaultChecked={true}
            label="Show Action Button"
            tooltipText=""
            fieldName="actionButtonText"
            selectedName="actionButtonSelected"
            formContext={form}
            inputPlaceholder="Run Action"
          />
          <FormCheckInput
            defaultChecked={true}
            label="Show Info Button"
            tooltipText=""
            fieldName="infoButtonText"
            selectedName="infoButtonSelected"
            formContext={form}
            inputPlaceholder="Get Info"
          />
          <FormWysiwyg
            tag="textarea"
            label=""
            formContext={form}
            fieldName="infoText"
            growlId={growlId}
          />
          <FormImage
            label="Tile to Display"
            uploadButtonText="Upload"
            existingAssetIdField="tileAssetId"
            fieldName="tileAssetUpload"
            formContext={form}
            defaultAssetId="collection-item-default.png"
            useCropper={true}
            croppedRatio={325 / 200}
          />
          <FormInput label="Description" fieldName="description" formContext={form} />
          <CreateableSelect
            label="Category"
            fieldName="category"
            formContext={form}
            optionsLoader={getCategories}
          />
          <AudienceSelector
            formContext={form}
            label="Available To"
            fieldName="condition"
            growlId={growlId}
          />
        </form>
      </div>
      <div className="row module-footer">
        <CmsPageSectionFooter actions={actions} />
      </div>
    </>
  );
};

export default CollectionItemEditor;
