import React from 'react';
import { ItemTableColumn } from '../definitions';
import { Item } from '../../form/definitions';
import classNames from 'classnames';

interface TableCellProps<I> {
  item: I;
  column: ItemTableColumn<I>;
}

const defaultColumnRenderer = <I extends Item>(item: I, field: keyof I) => {
  const value = (item as any)[field];
  return <>{value}</>;
};

const ItemTableCell = <I extends Item>(props: TableCellProps<I>) => {
  const { item, column } = props;
  const { renderer, valueField, className: columnClassName } = column;
  const columnRenderer = renderer || defaultColumnRenderer;

  const align = column.align || 'left';
  return (
    <td className={classNames('table-cell', align, columnClassName)}>
      {columnRenderer(item, valueField)}
    </td>
  );
};

export default ItemTableCell;
