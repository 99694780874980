import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useDebounce } from 'use-debounce';
import { Action } from '../../definitions';
import ActionList from '../../itemTable/components/ActionList';
import { BackPageAction } from '../definitions';
interface CmsListSectionToolbarProps<P> {
  onSearchChange: (searchString: string) => void;
  actions: Action<P>[];
  searchExpr?: string;
  backPageAction?: BackPageAction;
}

const CmsListSectionToolbar = <P extends object>(props: CmsListSectionToolbarProps<P>) => {
  const { onSearchChange, actions, searchExpr, backPageAction } = props;
  const [search, setSearch] = useState(searchExpr || '');
  const [debouncedSearch, { cancel }] = useDebounce(search, 500);

  useEffect(() => {
    onSearchChange(debouncedSearch);

    return cancel;
  }, [onSearchChange, debouncedSearch]);

  return (
    <>
      <div className="section-toolbar">
        <span className="header-input">
          <input
            className="form-control"
            placeholder="Search"
            value={search}
            autoFocus
            onChange={(e) => setSearch(e.target.value)}
          />
        </span>
        <ActionList actions={actions} />
      </div>

      {backPageAction && (
        <div style={{ marginTop: '1rem' }}>
          <Button
            bsPrefix="btn-link"
            variant="link"
            onClick={backPageAction.onClick}
          >{`< Back to ${backPageAction.pageName}`}</Button>
        </div>
      )}
    </>
  );
};

export default CmsListSectionToolbar;
