import axios from 'axios';
import { ItemApi, ListLoaderParams, ListLoaderResult } from '../../shared/cms/definitions';
import { SurveyInstance } from '../definitions';

const baseUrl = '/plugins/surveys/api';

const loadRecordsWrapper =
  (surveyId: string) => async (params: ListLoaderParams<SurveyInstance>) => {
    const {
      sort: { sortField, order },
      ...otherParams
    } = params;

    let result: ListLoaderResult<SurveyInstance>;

    try {
      const { data } = await axios.get(`${baseUrl}/getSurveyInstances`, {
        params: {
          ...otherParams,
          surveyId,
          sortField,
          sortReverse: sortField ? order === 'DESC' : undefined,
        },
        timeout: 10000,
      });
      const { surveyInstances, count, offset } = data;
      result = { records: surveyInstances, numRecords: count, offset };
    } catch (e) {
      console.error(e);
      throw new Error('Failed to load survey instances');
    }

    return result;
  };

const loadRecord = async (id: string) => {
  if (id === 'new') {
    return {
      name: '',
    };
  } else {
    try {
      const {
        data: { surveyInstance },
      } = await axios.get(`${baseUrl}/getSurveyInstance`, {
        params: {
          id,
        },
        timeout: 10000,
      });

      return surveyInstance;
    } catch (e) {
      console.error(e);
      throw new Error('Failed to load surveyInstance.');
    }
  }
};

const removeRecord = async (item: SurveyInstance) => {
  try {
    const { id } = item;
    await axios.post(`${baseUrl}/removeSurveyInstance`, {
      id,
      timeout: 10000,
    });
  } catch (e) {
    console.error(e);
    throw new Error('Failed to remove survey instance.');
  }
};

const saveRecordWrapper = (surveyId: string) => async (item: SurveyInstance) => {
  try {
    const {
      data: { surveyInstance },
    } = await axios.post(`${baseUrl}/saveSurveyInstance`, {
      ...item,
      surveyId,
      timeout: 10000,
    });

    return surveyInstance;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to save survey instance.');
  }
};

const generateSurveyInstanceApi = (surveyId: string): ItemApi<SurveyInstance> => {
  const surveyInstanceApi: ItemApi<SurveyInstance> = {
    loadRecords: loadRecordsWrapper(surveyId),
    loadRecord,
    removeRecord,
    saveRecord: saveRecordWrapper(surveyId),
  };
  return surveyInstanceApi;
};

export default generateSurveyInstanceApi;
