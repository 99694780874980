import { useCallback, useContext, useEffect } from 'react';
import { ListLoaderParams } from '../../cms/definitions';
import { Item } from '../../form/definitions';
import { ListLoadInitiator } from '../definitions';
import { CmsContext } from '../../../context/definitions';

const useLoadListItems = <T extends Item>(
  pageId: string,
  loadItems: ListLoadInitiator<T>,
  loaderParams: ListLoaderParams<T>
) => {
  const { growlProvider } = useContext(CmsContext);
  const { error } = growlProvider;

  const reload = useCallback(
    async (loaderParams) => {
      try {
        await loadItems(loaderParams);
      } catch (e) {
        error(pageId, e.message);
      }
    },
    [loadItems, pageId, error]
  );

  useEffect(() => {
    (async () => reload(loaderParams))();
  }, [reload, loaderParams]);

  return reload;
};

export default useLoadListItems;
