//Reducer to Handle Actions
import get from 'lodash/get';
import has from 'lodash/has';
import pick from 'lodash/pick';
import createReducer from '../../../../../shared/util/createReducer';
import { v4 as uuid } from 'uuid';

import { FILE_KEYS } from '../definitions';
import { removeFileMapPreviews, updateFile, removePreview } from './helpers';

export const initialState: any = {
  availableFilesMap: {},
  rejectedFiles: [],
  selectedFilePath: '',
};

export const actions: any = {
  SET_FILES: 'SET_FILES',
  SET_SELECTED_FILE: 'SET_SELECTED_FILE',
  SET_INITIAL_ASSETS: 'SET_INITIAL_ASSETS',
  SET_CROPPED_IMAGE: 'SET_CROPPED_IMAGE',
};
const { SET_FILES, SET_SELECTED_FILE, SET_INITIAL_ASSETS, SET_CROPPED_IMAGE } = actions;

export const reducer = createReducer({
  [SET_FILES]: (state: any, { setFormValue, rejectedFiles, acceptedFiles, useCropper }: any) => {
    removeFileMapPreviews(state.availableFilesMap);
    let selectedFile = get(acceptedFiles, '[0]', null);
    const selectedFilePath = get(
      selectedFile,
      'path',
      has(state.availableFilesMap, 'CURRENT_FILE') ? 'CURRENT_FILE' : 'DEFAULT_FILE'
    );
    if (selectedFile) {
      selectedFile = updateFile(selectedFile, { path: selectedFilePath, name: selectedFilePath });
      setFormValue(selectedFile);
    }
    state.availableFilesMap = {
      ...pick(state.availableFilesMap, FILE_KEYS),
      ...(selectedFile && {
        [selectedFilePath]: selectedFile,
      }),
    };
    state.rejectedFiles = rejectedFiles;
    state.selectedFilePath = selectedFilePath;
  },
  [SET_CROPPED_IMAGE]: (state: any, { croppedImage, setFormValue }: any) => {
    if (croppedImage) {
      removePreview(state.croppedImage);
      const path = uuid();
      croppedImage = updateFile(croppedImage, {
        path,
        name: `${path}.jpg`,
        type: 'image/jpeg',
      });
      state.croppedImage = croppedImage;
      setFormValue(croppedImage);
    }
  },
  [SET_SELECTED_FILE]: (state: any, { setFormValue, selectedFilePath }: any) => {
    setFormValue(get(state.availableFilesMap, selectedFilePath));
    state.selectedFilePath = selectedFilePath;
  },
  [SET_INITIAL_ASSETS]: (state: any, { defaultFile, currentFile, path }: any) => {
    state.selectedFilePath = path;
    state.availableFilesMap = {
      DEFAULT_FILE: defaultFile,
      ...(currentFile && { CURRENT_FILE: currentFile }),
    };
  },
});
