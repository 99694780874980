(function () {
  angular
    .module('new-push-notification', [
      'smart-table',
      'content-api-service',
      'user-api-service',
      'config-service',
      'confirm-dialog',
    ])
    .controller('newPushNotificationController', [
      '$scope',
      '$modal',
      '$timeout',
      '$filter',
      '$injector',
      'contentApiService',
      'growl',
      'queryExprToString',
      'confirmDialog',
      '$interval',
      function (
        $scope,
        $modal,
        $timeout,
        $filter,
        $injector,
        contentApiService,
        growl,
        queryExprToString,
        confirmDialog,
        $interval
      ) {
        $scope.RECORDS_PER_PAGE = 10;
        var refreshTimer;

        $scope.getters = {
          status: function (record) {
            switch (record.status) {
              case 'scheduled':
                return 'Scheduled';
              case 'delivered':
                return 'Delivered';
              case 'sending':
                return 'Sending';
              case 'cancelled':
                return 'Cancelled';
              default:
                return '';
            }
          },
          audience: function (record) {
            var audienceExpr = JSON.parse(record.audienceExpr);
            return queryExprToString(audienceExpr);
          },
          deviceCount: function (record) {
            if (record.status === 'scheduled') {
              return 'Pending...';
            } else {
              return record.numEndpoints;
            }
          },
        };

        $scope.loadPushNotifications = function (tableState) {
          $timeout(function () {
            setNotifications([], 0);
          });

          $scope.tableState = tableState;

          var pagination = tableState.pagination;

          var offset = pagination.start || 0; // This is NOT the page number, but the index of item in the list that you want to use to display the table.
          var limit = pagination.number || $scope.RECORDS_PER_PAGE; // Number of entries showed per page.

          getNotifications(offset, limit);
        };

        function getNotifications(offset, limit) {
          $timeout(function () {
            $scope.isLoading = true;
          });

          contentApiService
            .getPushNotifications(offset, limit)
            .success(function (data, status, headers, config) {
              setNotifications(data.notifications, data.count);

              $timeout(function () {
                $scope.tableState.pagination.start = offset;
              });
            })
            .error(function (data, status, headers, config) {
              var message = data.error ? JSON.stringify(data.error) : JSON.stringify(data);
              setNotifications([], 0);
              growl.error('Failed to load push notifications: ' + message, {
                referenceId: 'pushNotificationGrowl',
              });
            });
        }

        function setNotifications(pushNotifications, count) {
          $scope.pushNotifications = pushNotifications;
          $scope.totalCount = count;

          $scope.isLoading = false;

          setNumberOfPages(count);
        }

        function setNumberOfPages(count) {
          var numberOfPages = Math.ceil(count / $scope.RECORDS_PER_PAGE);
          if ($scope.tableState) {
            $scope.tableState.pagination.numberOfPages = numberOfPages; //set the number of pages so the pagination can update

            $scope.showPagination = numberOfPages > 1;
          }
        }

        $scope.launchNotificationModal = function (mode, notification) {
          var options = {
            templateUrl: 'modules/push/pushNotificationModal.html',
            controller: 'pushNotificationModalController',
            scope: $scope.$new(),
          };

          options.scope.mode = mode || 'new';
          options.scope.notification = notification;

          var modalInstance = $modal.open(options);
          modalInstance.result.then(
            function (result) {
              if (result) {
                getNotifications(0, $scope.RECORDS_PER_PAGE);
              }
            },
            function () {}
          );
        };

        $scope.cancelNotification = function (notification) {
          confirmDialog({
            title: 'Confirm cancel',
            body: 'Are you sure you want to cancel this push notification?',
            confirmText: 'Yes',
            cancelText: 'No',
          }).result.then(function (result) {
            contentApiService
              .cancelPushNotification(notification.id)
              .success(function (data, status, headers, config) {
                growl.success('Notification has been successfully cancelled.', {
                  referenceId: 'pushNotificationGrowl',
                });
                getNotifications($scope.tableState.pagination.start, $scope.RECORDS_PER_PAGE);
              })
              .error(function (data, status, headers, config) {
                var message = data.error ? JSON.stringify(data.error) : JSON.stringify(data);
                growl.error('Error cancelling push notification: ' + message, {
                  referenceId: 'pushNotificationGrowl',
                });
              });
          });
        };

        function refreshTableCheck() {
          var reload = false;
          if (!$scope.pushNotifications) {
            return;
          }
          var now = Date.now();
          angular.forEach($scope.pushNotifications, function (record) {
            var recordTimestamp = Date.parse(record.sendTimestamp);
            if (record.status === 'scheduled' && recordTimestamp < now) {
              reload = true;
            }
          });
          if (reload) {
            $scope.loadPushNotifications($scope.tableState);
          }
        }

        $scope.changeAutoRefresh = function () {
          if ($scope.autoFreshActive) {
            stopAutoFreshTimer();
          } else {
            startAutoRefreshTimer();
          }
        };

        function setAutoFreshButtonText() {
          if ($scope.autoFreshActive) {
            $scope.autoRefreshText = 'Auto Refresh ON';
          } else {
            $scope.autoRefreshText = 'Auto Refresh OFF';
          }
        }

        function startAutoRefreshTimer() {
          if (refreshTimer) {
            stopAutoFreshTimer();
          }

          refreshTimer = $interval(function () {
            refreshTableCheck();
          }, 15000);
          $scope.autoFreshActive = true;
          setAutoFreshButtonText();
          refreshTableCheck();
        }

        function stopAutoFreshTimer() {
          $interval.cancel(refreshTimer);
          $scope.autoFreshActive = false;
          setAutoFreshButtonText();
        }

        $scope.$on('$destroy', function () {
          if (refreshTimer) stopAutoFreshTimer();
        });

        startAutoRefreshTimer();
      },
    ])
    .controller('pushNotificationModalController', [
      '$rootScope',
      '$scope',
      '$modal',
      '$log',
      '$injector',
      'growl',
      'contentApiService',
      'userApiService',
      '$state',
      '$timeout',
      'confirmDialog',
      'configService',
      'queryExprToString',
      'audienceModalService',
      'moment',
      function (
        $rootScope,
        $scope,
        $modal,
        $log,
        $injector,
        growl,
        contentApiService,
        userApiService,
        $state,
        $timeout,
        confirmDialog,
        configService,
        queryExprToString,
        audienceModalService,
        moment
      ) {
        $scope.hasAudienceExpr = function (audienceExpr) {
          return audienceExpr && Object.keys(audienceExpr).length > 0;
        };

        $scope.audienceType = {};
        if ($scope.mode === 'view') {
          $scope.audienceType.value = $scope.hasAudienceExpr(
            JSON.parse($scope.notification.audienceExpr)
          )
            ? 'select'
            : 'all';
        }

        $scope.scheduleType = $scope.mode === 'view' ? 'later' : 'now';
        $scope.targetUrl = $scope.notification ? $scope.notification.url : '';
        $scope.linkText = $scope.notification ? $scope.notification.linkText : '';

        $scope.ttl = {
          text: $scope.notification ? $scope.notification.ttl : 10080,
        };

        $scope.sendDate =
          $scope.mode === 'view' ? moment($scope.notification.sendTimestamp) : moment();
        $scope.sendMinDate = $scope.mode === 'view' ? null : moment();

        $scope.logic = $scope.mode === 'view' ? JSON.parse($scope.notification.audienceExpr) : null;

        $scope.notification = {
          title: $scope.notification ? $scope.notification.title : '',
          text: $scope.notification ? $scope.notification.message : '',
          linkText: $scope.linkText ? $scope.notification.linkText : '',
        };

        $scope.notification.characterLimit = 1024;
        $scope.notification.charactersRemaining =
          $scope.notification.characterLimit - $scope.notification.text.length;

        $scope.changeSendDate = function (field, value) {
          $scope.sendDate = value;
        };

        $scope.$on('hidePicker', function () {
          angular.element('#sendDatePicker').blur();
        });

        $scope.titleChanged = function () {};

        $scope.messageChanged = function (textArea) {
          $scope.updateWordLength(textArea);
        };

        $scope.updateWordLength = function (textArea) {
          textArea.charactersRemaining = textArea.characterLimit - textArea.text.length;
        };

        $scope.targetUrlChanged = function () {};

        $scope.audienceSelectionChanged = function () {};

        $scope.pushNotification = function () {
          if (!$scope.ttl || !$scope.ttl.text) {
            growl.error('Notification Length must be a number greater than 0');
            return;
          }

          if (!$scope.notification || !$scope.notification.title) {
            growl.error('No Notification Title');
            return;
          }

          if (!$scope.notification || !$scope.notification.text) {
            growl.error('No Notification Text');
            return;
          }

          if (!$scope.validateTargetUrl($scope.targetUrl)) {
            growl.error('Invalid target url.');
            return;
          }

          if ($scope.targetUrl && !$scope.linkText) {
            growl.error('Invalid link text.');
            return;
          }

          if ($scope.audienceType.value === 'all') {
            $scope.logic = {};
          }

          confirmDialog({
            title: 'Send push notification?',
            body: 'Are you sure you want to schedule this notification for delivery to all selected users now?',
            confirmText: 'Yes',
            cancelText: 'Cancel',
          }).result.then(function (result) {
            var sendDate = $scope.scheduleType === 'now' ? moment() : $scope.sendDate;
            contentApiService
              .schedulePushNotification(
                $scope.notification.title,
                $scope.notification.text,
                $scope.ttl.text,
                $scope.linkText,
                $scope.targetUrl,
                $scope.logic,
                sendDate
              )
              .success(function (data, status, headers, config) {
                $log.debug(
                  'Successfully scheduled push notification for delivery. Response: ',
                  data
                );
                growl.success('Push notification scheduled successfully.', {
                  referenceId: 'pushNotificationGrowl',
                });
                $scope.close(true);
              })
              .error(function (data, status, headers, config) {
                var message = data.error ? JSON.stringify(data.error) : JSON.stringify(data);
                $log.debug('Failed to schedule push notification. Response: ', data);
                growl.error('Failed to schedule push notification: ' + message, {
                  referenceId: 'pushNotificationGrowl',
                });
                $scope.close(false);
              });
          });
        };

        $scope.close = function (messageSent) {
          $scope.$close(messageSent);
        };

        $scope.selectAudiences = async function () {
          let newCondition = await audienceModalService.showAudienceModal($scope.logic, {
            showPreview: true,
            includePushOptionForPreview: true,
          });

          if (newCondition) {
            if (!newCondition?.rules || newCondition.rules.length === 0) {
              newCondition = null;
            }

            $scope.audienceType.value = 'select';
            growl.success('Audience query updated successfully');
            $scope.logic = newCondition;
          }
        };

        $scope.previewUsers = function (logic) {
          var options = {
            templateUrl: 'modules/manageUsers/listUsersModal.html',
            controller: 'ListUsersModalCtrl',
          };

          options.scope = $scope.$new();
          options.scope.logic = logic;
          options.scope.includePushOption = true;
          options.backdrop = 'static';

          var modalInstance = $modal.open(options);
          modalInstance.result.then(
            function () {},
            function () {}
          );
        };

        $scope.validateTargetUrl = function (targetUrl) {
          return (
            !targetUrl ||
            targetUrl.indexOf('cms://') === 0 ||
            targetUrl.indexOf('http://') === 0 ||
            targetUrl.indexOf('https://') === 0
          );
        };

        $scope.hasSelectedAudiences = function () {
          return $scope.logic && $scope.logic.rules && $scope.logic.rules.length > 0;
        };

        $scope.selectAudiencesLabel = function () {
          return $scope.hasSelectedAudiences() ? 'Change/View' : 'Select';
        };

        $scope.pushStateIsInvalid = function () {
          return (
            !$scope.notification.title ||
            !$scope.notification.text ||
            ($scope.audienceType.value !== 'all' && !$scope.hasSelectedAudiences()) ||
            ($scope.targetUrl && !$scope.linkText)
          );
        };

        $scope.canSendMessage = function () {
          return !$scope.pushStateIsInvalid();
        };

        $scope.audienceExprAsString = function () {
          return $scope.logic ? queryExprToString($scope.logic) : '';
        };

        $scope.findLink = function () {
          var internalLinkService = getInternalLinkService();
          internalLinkService
            .showPopup($scope.targetUrl, 'normalizedLink', false)
            .then(function (href) {
              if (href) {
                $scope.targetUrl = href;
              }
            });
        };

        $scope.cancelLabel = $scope.mode === 'view' ? 'Close' : 'Cancel';

        function getInternalLinkService() {
          return $injector.get('internalLinkService');
        }
      },
    ]);
})();
