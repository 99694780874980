import React, { useMemo } from 'react';
import { FieldError } from 'react-hook-form';

interface FormFieldErrorProps {
  error: FieldError;
}

const FormFieldError: React.FC<FormFieldErrorProps> = (props: FormFieldErrorProps) => {
  const { error } = props;

  const allMessages = useMemo(() => {
    return !!error ? Object.values(error.types) : [];
  }, [error]);

  return (
    <>
      {allMessages.map((error, index) => (
        <div key={index} className="form-error-message">
          {error}
        </div>
      ))}
    </>
  );
};

export default FormFieldError;
