import produce from 'immer';
import get from 'lodash/get';
const createReducer: any =
  (reducers: any): any =>
  (originalState: any, action: any): any =>
    produce(originalState, (draftState: any): any => {
      const callback = get(reducers, action.type);
      if (callback && typeof callback === 'function') {
        callback(draftState, action.payload);
      }
    });

export default createReducer;
