import React, { InputHTMLAttributes } from 'react';
import { FieldPath, RegisterOptions, UseFormReturn } from 'react-hook-form';
import classNames from 'classnames';
import FormFieldError from './FormFieldError';
import { InputWidth, Item } from '../definitions';
import { validationIncludesRequired } from '../formUtils';

interface FormInputProps<T extends Item> extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  fieldName: FieldPath<T>;
  placeholder?: string;
  fieldWidth?: InputWidth;
  formFieldOptions?: RegisterOptions<T>;
  formContext: UseFormReturn<T>;
}

const FormInput = <T extends Item>(props: FormInputProps<T>) => {
  const {
    label,
    fieldName,
    placeholder,
    formFieldOptions,
    formContext,
    fieldWidth,
    type: providedType,
    className: providedClassName,
    ...inputAttributes
  } = props;

  const { formState, register } = formContext;
  const { errors } = formState;

  const fieldError = errors[fieldName];

  const inputWidth = fieldWidth || 'normal';

  const isRequired = validationIncludesRequired(formFieldOptions);

  return (
    <div
      className={classNames('form-group', providedClassName, `input-${inputWidth}`, {
        'has-error': !!fieldError,
      })}
    >
      <label>
        {label}
        {isRequired && <span className="required-annotation">*</span>}
      </label>
      <input
        type={providedType || 'text'}
        className="form-control"
        placeholder={placeholder}
        maxLength={255}
        {...inputAttributes}
        {...register(fieldName, formFieldOptions)}
      />
      <FormFieldError error={fieldError} />
    </div>
  );
};

export default FormInput;
