angular.module('subtenant-api-service', []).service('subtenantApiService', [
  '$q',
  '$log',
  'Upload',
  '$http',
  '$rootScope',
  'configService',
  function ($q, $log, Upload, $http, $rootScope, configService) {
    var subtenantMap = {};

    function getSubtenants(start, number, params) {
      var sortPredicate;
      var sortReverse;
      var searchPredicate;
      if (params) {
        sortPredicate = params.sort.predicate;
        sortReverse = params.sort.reverse;
        searchPredicate = params.search.predicateObject;
        if (searchPredicate) {
          searchPredicate = searchPredicate.name;
        }
      }

      var httpParams = {
        params: {
          start: start,
          number: number,
          sortPredicate: sortPredicate,
          sortReverse: sortReverse,
          searchPredicate: searchPredicate,
        },
      };

      var promise = $http
        .get('/subtenant-api/getSubtenants', httpParams)
        .success(function (data, status, headers, config) {
          rebuildSubtenantMap(data.subtenants);
        })
        .error(function (data, status, headers, config) {
          $log.debug('Failed to get all subtenants. Response: ', data);
        });

      return promise;
    }

    function getAllSubtenants() {
      var promise = $http
        .get('/subtenant-api/getAllSubtenants')
        .success(function (data, status, headers, config) {
          rebuildSubtenantMap(data.subtenants);
        })
        .error(function (data, status, headers, config) {
          $log.debug('Failed to get all subtenants. Response: ', data);
        });

      return promise;
    }

    function rebuildSubtenantMap(subtenants) {
      subtenantMap = {};
      for (var i = 0; i < subtenants.length; i++) {
        var subtenant = subtenants[i];
        subtenantMap[subtenant.id] = subtenant;
      }
    }

    function getSubtenantMetadata(id) {
      return subtenantMap[id];
    }

    function saveSubtenant(subtenant) {
      var data = {
        id: subtenant.id,
        name: subtenant.name,
        affiliation: subtenant.affiliation,
      };

      var promise = $http.post('/subtenant-api/upsertSubtenant', data);

      promise.then(function (result) {
        subtenantMap[subtenant.id] = result.data.subtenant;
        broadcastSubtenantUpdated(result.data.subtenant);
      });

      return promise;
    }

    function removeSubtenant(subtenant) {
      var deferred = $q.defer();
      var data = {
        id: subtenant.id,
      };

      $http
        .post('/subtenant-api/deleteSubtenant', data)
        .then(function (result) {
          delete subtenantMap[subtenant.id];

          broadcastSubtenantUpdated(subtenant);
          deferred.resolve();
        })
        .catch(function (result) {
          deferred.reject(
            new Error(
              (result && result.data && result.data.error) || 'An unknown error has occured.'
            )
          );
        });

      return deferred.promise;
    }

    function broadcastSubtenantUpdated(subtenant) {
      $rootScope.$broadcast('subtenantUpdated', subtenant.id);
    }

    return {
      removeSubtenant: removeSubtenant,
      saveSubtenant: saveSubtenant,
      getSubtenantMetadata: getSubtenantMetadata,
      getAllSubtenants: getAllSubtenants,
      getSubtenants: getSubtenants,
    };
  },
]);
