(function () {
  angular.module('plugin-service', []).service('pluginService', [
    '$http',
    '$q',
    '$ocLazyLoad',
    '$injector',
    '$log',
    function ($http, $q, $ocLazyLoad, $injector, $log) {
      var initializePromise;
      var pluginMap;

      function pluginExists(name) {
        return initialize().then(function () {
          return !!pluginMap[name];
        });
      }

      function getPlugin(name) {
        return initialize().then(function () {
          if (pluginMap[name]) {
            return pluginMap[name];
          } else {
            throw new Error('Plugin ' + name + ' not found.');
          }
        });
      }

      function initialize() {
        if (!initializePromise) {
          initializePromise = loadModuleLoader()
            .then(getPlugins)
            .then(getPluginModules)
            .then(loadPluginModules)
            .catch(function (err) {
              alert(
                'Failed to initialize CMS.  Contact your system administrator if this issue occurs again.'
              );
              $log.error('Failed to  initialize CMS. Response: ' + err);
            });
        }

        return initializePromise;
      }

      function getResourceUrl(pluginName, fileName) {
        return '/plugins/' + pluginName + '/cms/web/' + fileName;
      }

      function loadModuleLoader() {
        return $ocLazyLoad.load('/plugins/module-loader/cms/web/moduleLoader.js');
      }

      function getPlugins() {
        return loadPluginConfig().then(function (pluginInfoObject) {
          return pluginInfoObject.plugins;
        });
      }

      function getPluginModules(plugins) {
        var webModules = [];
        pluginMap = {};

        _.forEach(plugins, function (plugin) {
          pluginMap[plugin.name] = plugin;
          var pluginWebModules = _.filter(plugin.webModules, function (module) {
            return module.path !== '/plugins/module-loader/cms/web/moduleLoader.js';
          });
          Array.prototype.push.apply(webModules, pluginWebModules);
        });

        return webModules;
      }

      function loadPluginModules(webModules) {
        var webModuleLoader = $injector.get('c8sWebModuleLoader');
        return webModuleLoader.loadModules(webModules);
      }

      function loadPluginConfig() {
        var deferred = $q.defer();

        $http
          .get('/plugins/plugins.json')
          .success(function (data, status, headers, config) {
            deferred.resolve(data);
          })
          .error(function (data, status, headers, config) {
            deferred.reject('Failed to get list of plugins. Response: ' + JSON.stringify(data));
          });

        return deferred.promise;
      }

      return {
        initialize: initialize,
        getPlugin: getPlugin,
        pluginExists: pluginExists,
        getResourceUrl: getResourceUrl,
      };
    },
  ]);
})();
