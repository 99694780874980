import { useContext, useMemo } from 'react';
import { CmsContext } from '../../../context/definitions';
import { ItemApi } from '../../cms/definitions';
import { useItemPersistence } from '../../cms/hooks/useItemPersistence';
import { Action, ContextAction } from '../../definitions';
import { Item } from '../../form/definitions';
import { PageConstants, WrappedActionParams } from '../definitions';
import usePublishActionHandler from './usePublishActionHandler';

const useItemListActions = <T extends Item, R extends Item = T>(
  itemApi: ItemApi<T, R>,
  pageConstants: PageConstants
) => {
  const {
    typeName,
    pluginId,
    defaultPageId,
    defaultPageName,
    publishPageName,
    editorPageName,
    pageTitle,
  } = pageConstants;

  const { navigator, growlProvider, confirm } = useContext(CmsContext);
  const { success, error } = growlProvider;

  const { removeItem, restoreItem, requestPending } = useItemPersistence(itemApi);

  const add = useMemo(
    (): Action<WrappedActionParams<T>> => ({
      label: `+ Add ${typeName}`,
      onClick: ({ loaderParams }) => {
        navigator.navigateToChildPage(pluginId, editorPageName, defaultPageId, loaderParams, 'new');
      },
    }),
    [typeName, navigator, pluginId, defaultPageId, editorPageName]
  );

  const edit = useMemo(
    (): ContextAction<T, WrappedActionParams<T>> => ({
      icon: 'fa-solid fa-pencil',
      label: 'Edit',
      disabled: (context: T) => context.status === 'removed',
      onClick: (context: T, { loaderParams }) => {
        navigator.navigateToChildPage(
          pluginId,
          editorPageName,
          defaultPageId,
          loaderParams,
          context.id
        );
      },
    }),
    [navigator, pluginId, defaultPageId, editorPageName]
  );

  const remove = useMemo(
    (): ContextAction<T, WrappedActionParams<T>> => ({
      icon: 'fa-solid fa-trash',
      label: 'Remove',
      disabled: (context: T) => context.status === 'removed',
      onClick: async (context: T, { reload }) => {
        const confirmation = await confirm({
          title: `Remove ${typeName}`,
          message: `Are you sure you want to remove this ${typeName}?`,
        });

        if (confirmation) {
          try {
            await removeItem(context);
            success(defaultPageId, `The ${typeName} has been successfully removed!`);
            await reload();
          } catch (e) {
            error(defaultPageId, e.message);
          }
        }
      },
    }),
    [removeItem, typeName, success, error, confirm]
  );

  const restore = useMemo((): ContextAction<T, WrappedActionParams<T>> => {
    return !restoreItem
      ? undefined
      : {
          icon: 'fa-solid fa-trash-arrow-up',
          label: 'Restore',
          disabled: (context: T) =>
            context.status === 'unpublished' || context.status === 'published',
          onClick: async (context: T, { reload }) => {
            try {
              await restoreItem(context);
              success(defaultPageId, `The ${typeName} has been successfully restored!`);
              await reload();
            } catch (e) {
              error(defaultPageId, e.message);
            }
          },
        };
  }, [restoreItem, typeName, success, error]);

  const publishHandler = usePublishActionHandler(
    pluginId,
    typeName,
    publishPageName,
    defaultPageId,
    defaultPageName,
    pageTitle,
    defaultPageId,
    itemApi
  );

  const publish = useMemo((): ContextAction<R, WrappedActionParams<R>> => {
    return !publishHandler
      ? undefined
      : {
          icon: 'fa-solid fa-upload',
          label: 'Publish',
          disabled: (context: R) => context.status === 'published',
          onClick: async (context: R, { reload }) => {
            await publishHandler(
              context.id,
              context.liveItemsHash !== context.sandboxItemsHash,
              reload
            );
          },
        };
  }, [publishHandler]);

  return {
    add,
    edit,
    remove,
    requestPending,
    restore,
    publish,
  };
};

export default useItemListActions;
