import React from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import ItemEditorModal from '../../shared/cmsPage/components/ItemEditorModal';
import useItemEditorModal from '../../shared/cmsPage/hooks/useItemEditorModal';
import ItemPublishStatus from '../../shared/itemTable/components/ItemPublishStatus';
import Timestamp from '../../shared/itemTable/components/Timestamp';
import { ItemTableColumn } from '../../shared/itemTable/definitions';
import useArray from '../../shared/util/hooks/useArray';
import collectionApi from '../api/collectionApi';
import { Collection, CollectionConstants } from '../definitions';
import useCollectionListActions from '../hooks/useCollectionListActions';
import CollectionEditorForm from './CollectionEditorForm';

const CollectionColumns: ItemTableColumn<Collection>[] = [
  {
    label: 'Name',
    valueField: 'name',
    sortable: true,
    initialSort: 'ASC',
    renderer: (collection) => <span>{collection.name}</span>,
  },
  {
    label: 'Display Label',
    valueField: 'label',
    sortable: true,
  },
  {
    label: 'Status',
    valueField: 'status',
    sortable: false,
    renderer: (collection) => <ItemPublishStatus status={collection.status} />,
  },
  {
    label: 'Last Modified',
    valueField: 'lastUpdated',
    sortable: true,
    renderer: (item, field) => <Timestamp date={item[field] as Date} />,
  },
];

const CollectionListPage = () => {
  const { defaultPageId, typeName } = CollectionConstants;

  const { showItemEditorModal, editorModalProps } = useItemEditorModal(
    CollectionConstants.typeName,
    'name',
    collectionApi,
    CollectionEditorForm
  );

  const { add, edit, remove, restore, publish, requestPending } =
    useCollectionListActions(showItemEditorModal);

  const toolbarActions = useArray(add);
  const itemActions = useArray(edit, publish, remove, restore);

  return (
    <CmsPage title="Collections" requestPending={requestPending}>
      <CmsItemListSection
        pageId={defaultPageId}
        className="nav-manager"
        typeName={typeName}
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        columns={CollectionColumns}
        itemApi={collectionApi}
      />
      <ItemEditorModal {...editorModalProps} />
    </CmsPage>
  );
};

export default CollectionListPage;
