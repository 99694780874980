import { useMemo } from 'react';
import { clearPageState, getPageState } from '../cmsPageUtils';

const useSavedPageState = <T>(pageId: string) => {
  return useMemo(() => {
    const result = getPageState(pageId);
    clearPageState(pageId);

    return result as T;
  }, [pageId]);
};

export default useSavedPageState;
