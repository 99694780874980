/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuidv4 } from 'uuid';

export interface WysiwygListener {
  onImageInserted(e: any, editor: any): void;
  onVideoInserted(e: any, editor: any): void;
  onInitialized(e: any, editor: any): void;
  onCodeViewChanged(active: any, editor: any): void;
}

export const addWysiwygEvents = (options: any, wysiwygListener: WysiwygListener) => {
  options.events = {};

  options.events['froalaEditor.video.beforeUpload'] = (e: any, editor: any, videos: any) => {
    let name: string = uuidv4();
    const extn: string = videos[0].name.split('.').pop();
    name += '.' + extn;

    const data = editor.opts.videoUploadParams.data
      ? JSON.parse(editor.opts.videoUploadParams.data)
      : {};

    const group: string = data && data.asset && data.asset.group ? data.asset.group : 'default';
    editor.opts.videoUploadParams.data = JSON.stringify({
      asset: {
        type: 'file',
        group: group,
        id: name,
        ignoreRevisionNumber: true,
        publishLive: true,
      },
    });
    return true;
  };

  options.events['froalaEditor.image.beforeUpload'] = (e: any, editor: any, images: any) => {
    let name: string = uuidv4();
    const extn: string = images[0].name.split('.').pop();
    name += '.' + extn;

    const data = editor.opts.imageUploadParams.data ? editor.opts.imageUploadParams.data : {};
    const group: string = data && data.asset && data.asset.group ? data.asset.group : 'default';
    editor.opts.imageUploadParams.data = JSON.stringify({
      asset: {
        type: 'file',
        group: group,
        id: name,
        ignoreRevisionNumber: true,
        publishLive: true,
      },
    });
  };

  const insertImageFunc = (e: any, editor: any, $img: any, response: any) => {
    const res = JSON.parse(response);

    if (res && res.asset && res.asset.id) {
      $img.attr('ng-src', 'cms://' + res.asset.id);
      clearImageDataAttrs($img);
    } else if ($img.data('id')) {
      $img.attr('ng-src', 'cms://' + $img.data('id'));
      clearImageDataAttrs($img);
    } else {
      $img.remove();
    }

    editor.events.trigger('contentChanged', [], true);
    wysiwygListener.onImageInserted(e, editor);

    return true;
  };

  const insertVideoFunc = (e: any, editor: any, $videoContainer: any, response: any) => {
    const $video = $videoContainer.find('video');
    const res = JSON.parse(response);

    if (res && res.asset && res.asset.id) {
      $video.attr('ng-src', 'cms://' + res.asset.id);
      clearImageDataAttrs($video);
    } else if ($video.data('id')) {
      $video.attr('ng-src', 'cms://' + $video.data('id'));
      clearImageDataAttrs($video);
    } else {
      $video.remove();
    }

    editor.events.trigger('contentChanged', [], true);
    wysiwygListener.onVideoInserted(e, editor);

    return true;
  };

  const clearImageDataAttrs = ($img: any) => {
    for (const key in $img.data()) {
      if (key !== 'id') {
        $img.removeData(key);
        $img.removeAttr('data-' + key);
      }
    }
  };

  options.events['froalaEditor.image.inserted'] = insertImageFunc;
  options.events['froalaEditor.image.replaced'] = insertImageFunc;

  options.events['froalaEditor.video.inserted'] = insertVideoFunc;
  options.events['froalaEditor.video.replaced'] = insertVideoFunc;

  options.events['froalaEditor.initialized'] = function (e: any, editor: any) {
    wysiwygListener.onInitialized(e, editor);
  };

  options.events['froalaEditor.imageManager.beforeDeleteImage'] = (
    e: any,
    editor: any,
    $img: any
  ) => {
    const assetId = $img.data('id');
    editor.opts.imageManagerDeleteParams = editor.opts.imageManagerDeleteParams
      ? editor.opts.imageManagerDeleteParams
      : {};
    editor.opts.imageManagerDeleteParams.assetId = assetId;
  };

  options.events['froalaEditor.commands.after'] = function (e: any, editor: any, cmd: any) {
    if (cmd === 'html') {
      wysiwygListener.onCodeViewChanged(editor.codeView.isActive(), editor);
    }
  };

  options.getHTMLContent = () => {
    let retVal = '';
    if (typeof options.froalaEditor === 'function') {
      retVal = options.froalaEditor('html.get');
    }
    return retVal ? retVal : '';
  };

  return options;
};
