import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export interface ConfirmArgs {
  title?: string;
  message?: string;
  warning?: string;
}

export interface ConfirmModalProps {
  confirmArgs: ConfirmArgs;
  onClose?: (result: boolean) => void;
  visible: boolean;
}

const ConfirmModal: React.FC<ConfirmModalProps> = (props: ConfirmModalProps) => {
  const { visible, confirmArgs, onClose } = props;
  const { title, message, warning } = confirmArgs;

  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(visible);
  }, [visible]);

  const closeModal = useCallback(
    (result) => {
      if (onClose) {
        onClose(result);
      } else {
        setShow(false);
      }
    },
    [setShow, onClose]
  );

  return (
    <Modal show={show} animation={false} backdrop="static">
      <Modal.Header>
        <Modal.Title>{title || 'Confirm'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-message">{message || 'Are you sure?'}</div>
        {warning && <div className="modal-warning">{warning}</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => closeModal(true)}>
          Confirm
        </Button>
        <Button variant="cancel" onClick={() => closeModal(false)}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
