import { PageConstants } from '../shared/cmsPage/definitions';
import { Item } from '../shared/form/definitions';
import createPageConstants from '../shared/util/createPageConstants';

const pluginId = 'quizzes';

export const QuizConstants: PageConstants = createPageConstants(pluginId, 'Quiz', {
  defaultDefault: 'quizzesListPage',
});

export const QuizInstanceConstants: PageConstants = createPageConstants(pluginId, 'Quiz Instance', {
  default: 'quizInstancesPage',
  editor: 'quizInstanceEditorPage',
});

export interface Quiz extends Item {
  id?: string;
  updated?: Date;
  title?: string;
  editable?: boolean;
  isArchived?: boolean;
}

export interface QuizInstance extends Item {
  id?: string;
  name?: string;
}

export interface QuizUpdateOptions extends Item {
  isArchived?: boolean;
}
