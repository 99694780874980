import React, { useContext } from 'react';
import { CmsContext } from '../../context/definitions';

interface SampleComponentProps {}

const SampleComponent: React.FC<SampleComponentProps> = (_props: SampleComponentProps) => {
  const { cmsConfig } = useContext(CmsContext);
  const userEmail = cmsConfig?.loggedInUser?.email || '';

  // Default branding makes this transparent.  This is just here to demonstrate how to use context
  return userEmail && <div className="welcome-message">Welcome {userEmail}</div>;
};

export default SampleComponent;
