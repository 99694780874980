import { Item } from '../form/definitions';

export type SortOrder = 'ASC' | 'DESC' | 'NONE';
export interface SortState<I extends Item> {
  sortField: keyof I;
  order: SortOrder;
}

export const EMPTY_SORT = {} as SortState<Item>;

export type ItemRenderer<I extends Item> = (item: I, field?: keyof I) => JSX.Element | string;
export type ColumnAlign = 'left' | 'center' | 'right';

export interface ItemTableColumn<I extends Item> {
  label: string;
  valueField: keyof I;
  sortable?: boolean;
  renderer?: ItemRenderer<I>;
  initialSort?: SortOrder;
  align?: ColumnAlign;
  className?: string;
}

export interface PaginationState {
  currentPage: number;
  pageCount: number;
}
