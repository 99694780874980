import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ItemTableColumn } from '../definitions';
import ItemTableHeaderCell from './ItemTableHeaderCell';
import ItemTableRow from './ItemTableRow';
import OrderedItemTableRowSelector from './OrderedItemTableRowSelector';
import { Item } from '../../form/definitions';
import { ItemContextAction, ItemReorderInitiator } from '../../cmsPage/definitions';
import { CmsContext } from '../../../context/definitions';
import { ReactSortable } from 'react-sortablejs';

interface OrderedItemTableProps<I extends Item> {
  items: I[];
  columns: ItemTableColumn<I>[];
  emptyListMessage: string;
  itemActions?: ItemContextAction<I>[];
  reorder: ItemReorderInitiator;
  parentItemId: string;
  growlId: string;
}

const OrderedItemTable = <I extends Item>(props: OrderedItemTableProps<I>) => {
  const { items, columns, itemActions, reorder, parentItemId, growlId, emptyListMessage } = props;
  const [orderedItems, setOrderedItems] = useState([]);

  const { growlProvider } = useContext(CmsContext);
  const { error } = growlProvider;

  const reorderItems = useCallback(async () => {
    try {
      const ids = orderedItems.map(({ id }: { id: string }) => id);
      await reorder(parentItemId, ids);
    } catch (e) {
      error(growlId, e.message);
      setOrderedItems(items);
    }
  }, [reorder, orderedItems, parentItemId, error]);

  useEffect(() => {
    setOrderedItems(items);
  }, [items]);

  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th />
          {columns.map((column, index) => (
            <ItemTableHeaderCell key={index} column={column} />
          ))}
          {itemActions && <th />}
        </tr>
      </thead>
      {items.length > 0 ? (
        <ReactSortable
          tag="tbody"
          list={orderedItems}
          setList={setOrderedItems}
          onEnd={reorderItems}
        >
          {orderedItems.map((item: any, itemIndex: number) => (
            <tr key={item.id}>
              <OrderedItemTableRowSelector />
              <ItemTableRow
                key={itemIndex}
                item={item}
                columns={columns}
                itemActions={itemActions}
              />
            </tr>
          ))}
        </ReactSortable>
      ) : (
        <tbody>
          <tr>
            <td />
            <td className="table-message" colSpan={columns.length}>
              {emptyListMessage}
            </td>
            {itemActions && <td />}
          </tr>
        </tbody>
      )}
    </table>
  );
};

export default OrderedItemTable;
