import React from 'react';
import classNames from 'classnames';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import targetAudienceApiWrapped from '../api/targetAudienceApi';
import useArray from '../../shared/util/hooks/useArray';
import { TargetAudience, TargetAudienceConstants } from '../definitions';
import useTargetAudienceActions from '../hooks/useTargetAudienceActions';
import { Item } from '../../shared/form/definitions';
import { ItemTableColumn } from '../../shared/itemTable/definitions';
import Timestamp from '../../shared/itemTable/components/Timestamp';
import ItemPublishStatus from '../../shared/itemTable/components/ItemPublishStatus';
import { conditionToText } from '../../shared/cms/cmsUtils';

const TargetAudiencesListPage: React.FC = () => {
  const { defaultPageId, typeName } = TargetAudienceConstants;

  const { add, archive, archiveCheckbox, edit, includeArchived, publish } =
    useTargetAudienceActions(TargetAudienceConstants);
  const targetAudienceApi = targetAudienceApiWrapped(includeArchived);

  const columns: ItemTableColumn<TargetAudience>[] = [
    {
      label: 'Name',
      valueField: 'name',
      sortable: true,
      initialSort: 'ASC',
      renderer: (item: Item) => <span>{item.name}</span>,
    },
    {
      label: 'Target Condition',
      valueField: 'condition',
      align: 'center',
      renderer: (item) => {
        const className = classNames('fa', 'fa-solid', { 'fa-bullseye': !!item.condition });
        const objCondition =
          typeof item.condition === 'string' ? JSON.parse(item.condition) : item.condition;
        return (
          item.condition && (
            <span className={'tooltip-container'}>
              <span className={className} />
              <span className={'tooltip-text'}>{conditionToText(objCondition)}</span>
            </span>
          )
        );
      },
    },
    {
      label: 'Status',
      valueField: 'status',
      sortable: false,
      renderer: (item: Item) => <ItemPublishStatus status={item.status} />,
    },
    {
      label: 'Last Modified',
      valueField: 'updated',
      sortable: true,
      renderer: (item: Item, field: string) => <Timestamp date={item[field] as Date} />,
    },
  ];
  if (includeArchived)
    columns.push({
      label: 'Archived',
      valueField: 'isArchived',
      sortable: false,
      renderer: (item: Item) => (item.isArchived ? 'Yes' : 'No'),
    });
  const toolbarActions = useArray(archiveCheckbox, add);
  const itemActions = useArray(edit, archive, publish);

  return (
    <CmsPage title={'Target Audiences'}>
      <CmsItemListSection
        pageId={defaultPageId}
        typeName={typeName}
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        columns={columns}
        itemApi={targetAudienceApi}
      />
    </CmsPage>
  );
};

export default TargetAudiencesListPage;
