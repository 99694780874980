import debounce from 'lodash/debounce';
import React, { useCallback, useState } from 'react';
import ReactEasyCrop from 'react-easy-crop';

function ImageCrop(props: any) {
  const { src, croppedRatio, setCroppedImage } = props;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  console.log({ src });

  const onCropComplete = useCallback(
    async (croppedArea: any, croppedAreaPixels: any) => {
      try {
        if (src) {
          await setCroppedImage(src, croppedAreaPixels);
        }
      } catch (e) {
        console.log('Error setting cropped image', e);
      }
    },
    [src]
  );

  return (
    <div style={{ position: 'absolute', height: '20rem', width: '20rem' }}>
      <div style={{ position: 'relative', height: '20rem', width: '20rem' }}>
        <ReactEasyCrop
          image={src}
          crop={crop}
          zoom={zoom}
          aspect={croppedRatio}
          onCropChange={setCrop}
          onCropComplete={debounce(onCropComplete, 500)}
          onZoomChange={setZoom}
        />
      </div>
    </div>
  );
}

export default ImageCrop;
