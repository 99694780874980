import React, { useContext } from 'react';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import { CmsPageContext, ItemEditorProps } from '../../shared/cmsPage/definitions';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import useLoadEditorItem from '../../shared/cmsPage/hooks/useLoadEditorItem';
import FormInput from '../../shared/form/components/FormInput';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import { useSpinner } from '../../shared/spinner/hooks/useSpinner';
import { SurveyInstance, SurveyInstanceConstants } from '../definitions';
import useSurveyInstanceConfigurations from '../hooks/useSurveyInstanceConfigurations';

interface SurveyInstanceEditor extends ItemEditorProps<SurveyInstance> {
  surveyId: string;
}
type SurveyInstanceEditorProps = SurveyInstanceEditor;

const SurveyInstanceEditor: React.FC<SurveyInstanceEditorProps> = (
  props: SurveyInstanceEditorProps
) => {
  const { itemId, onItemChange, surveyId } = props;
  const { spinnerProvider } = useContext(CmsPageContext);
  const { api } = useSurveyInstanceConfigurations(surveyId);
  const { item, loadItem, saveItem, requestPending } = useItemPersistence(api);
  const form = useCmsForm<SurveyInstance>(item);
  const actions = useItemEditActions(saveItem, form, SurveyInstanceConstants, surveyId);
  useSpinner(spinnerProvider, requestPending);
  useLoadEditorItem(itemId, loadItem, onItemChange, item, SurveyInstanceConstants);

  return (
    <>
      <div className="row">
        <form role="form">
          <FormInput
            label="Name"
            fieldName="name"
            formContext={form}
            formFieldOptions={{ required: 'This field is required.' }}
          />
        </form>
      </div>
      <div className="row module-footer">
        <CmsPageSectionFooter actions={actions} />
      </div>
    </>
  );
};

export default SurveyInstanceEditor;
