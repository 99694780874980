angular.module('audience-modal-service', []).service('audienceModalService', [
  '$rootScope',
  '$modal',
  '$q',
  'growl',
  'userApiService',
  function ($rootScope, $modal, $q, growl, userApiService) {
    async function getAudienceOptions() {
      try {
        const userConfig = await userApiService.getUserConfig();

        const groupOptions = {
          name: 'Group',
          options: (userConfig.userGroups || [])
            .sort((group1, group2) => group1.name.localeCompare(group2.name))
            .map((group) => ({ label: group.name, id: group.id })),
        };

        const options = [groupOptions];

        if (userConfig.cohorts) {
          options.push(
            {
              name: 'Cohort',
              options: userConfig.cohorts.map((cohort) => ({ label: cohort.name, id: cohort.id })),
            },
            {
              name: 'Cohort (Via Code)',
              options: userConfig.cohorts.map((cohort) => ({ label: cohort.name, id: cohort.id })),
            }
          );
        }

        return options;
      } catch (error) {
        console.log(error);
        throw new Error('Failed to load groups');
      }
    }

    async function showAudienceModal(
      audienceExpr,
      config = {
        showPreview: true,
        includePushOptionForPreview: false,
        modalContext: undefined,
      }
    ) {
      const options = {
        templateUrl: 'modules/push/editLogicModal.html',
        controller: 'EditLogicCtrl',
      };

      const audienceOptions = await getAudienceOptions();

      options.scope = $rootScope.$new();
      options.scope.logic = audienceExpr;
      options.scope.fields = audienceOptions;
      options.scope.preview = previewHandler(config);
      options.scope.modalContext = config.modalContext;
      options.backdrop = 'static';

      const modalInstance = $modal.open(options);
      let result;
      try {
        result = await modalInstance.result;
      } catch (e) {
        // Undefined is thrown on dismiss.  Eat that and return undefined as result.  If it's a
        // real exception, rethrow.
        if (e) {
          throw e;
        }
      }

      return result;
    }

    function previewHandler({ showPreview, includePushOptionForPreview }) {
      return showPreview
        ? (audienceExpr) => {
            previewUsers(audienceExpr, includePushOptionForPreview);
          }
        : false;
    }

    function previewUsers(audienceExpr, includePushOption) {
      const options = {
        templateUrl: 'modules/manageUsers/listUsersModal.html',
        controller: 'ListUsersModalCtrl',
      };

      options.scope = $rootScope.$new();
      options.scope.logic = audienceExpr;
      options.scope.includePushOption = includePushOption;
      options.backdrop = 'static';

      const modalInstance = $modal.open(options);
      modalInstance.result.then(
        function () {},
        function () {}
      );
    }

    return {
      showAudienceModal,
    };
  },
]);
