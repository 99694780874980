import { useEffect } from 'react';
import { DefaultValues, useForm, UseFormReturn } from 'react-hook-form';
import { Item } from '../definitions';

export const useCmsForm = <T extends Item>(defaultValues: DefaultValues<T>): UseFormReturn<T> => {
  const form = useForm<T>({
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  return form;
};
