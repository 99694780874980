import { useCallback, useState } from 'react';

const useApiWithPendingStatus = <R>(apiCallback: () => Promise<R>, initialValue: R) => {
  const [requestPending, setRequestPending] = useState(false);
  const [result, setResult] = useState(initialValue);

  const callApi = useCallback(async () => {
    setRequestPending(true);

    try {
      const apiResult = await apiCallback();
      setResult(apiResult);
    } finally {
      setRequestPending(false);
    }
  }, [apiCallback]);

  return { callApi, requestPending, result };
};

export default useApiWithPendingStatus;
