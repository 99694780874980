import 'ui-select/dist/select.css';
import 'ng-tags-input/build/ng-tags-input.css';
import 'froala-editor/css/froala_editor.pkgd.css';
import 'bootstrap/dist/css/bootstrap.css';

import './css/main.css';
import './css/simple-sidebar.css';

// Unfortunate hack to ensure that dynamically loaded CMS modules have access
// to the same jQuery instance as webpack modules.  Note the 'j' + 'Query' is used to prevent the
// webpack ProvidePlugin from replacing window.jQuery or window['jQuery'] with generated code.
const $ = require('jquery');
// @ts-ignore
window['j' + 'Query'] = $;

const moment = require('moment');
(window as any).moment = moment;

import './app';
