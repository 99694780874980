import React, { PropsWithChildren } from 'react';
import CmsPageSection from './CmsPageSection';
import GrowlContainer from '../../growl/components/GrowlContainer';

export type CmsItemEditSectionProps = PropsWithChildren<{
  pageId: string;
  className?: string;
}>;

const CmsItemEditSection = (props: CmsItemEditSectionProps) => {
  const { className, pageId, children } = props;

  return (
    <CmsPageSection className={className} isDetail={true}>
      <GrowlContainer groupId={pageId} />
      {children}
    </CmsPageSection>
  );
};

export default CmsItemEditSection;
