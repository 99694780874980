(function () {
  angular.module('field-error-service', []).service('fieldErrorService', [
    '$q',
    'growl',
    '$timeout',
    function ($q, growl, $timeout) {
      var currentErrors = [];

      var errorDescriptions = {
        required: 'This field is required.',
      };

      function getErrorDescription(name, key) {
        return errorDescriptions[key]
          ? name + ' ' + errorDescriptions[key]
          : name + ' has an error: ' + key + '.';
      }

      function alertFieldErrors(form, growlOptions) {
        var timeout = currentErrors.length && $('.growl-message').length ? 500 : 0;
        for (var i = 0; i < currentErrors.length; i++) {
          currentErrors[i].destroy();
        }

        $timeout(function () {
          var errors = form ? form.$error : {};
          var $form = $("[name='" + form.$name + "']");
          for (var key in errors) {
            var errorLists = errors[key];
            for (var i = 0; i < errorLists.length; i++) {
              var name = errorLists[i].$name;
              var labelName;
              if ($form && $form.length) {
                labelName = $form.find("label[for='" + name + "']").text();
              } else {
                labelName = $("label[for='" + name + "']").text();
              }
              name = labelName ? labelName : name;
              currentErrors.push(growl.error(getErrorDescription(name, key), growlOptions));
            }
          }
        }, timeout);
      }
      return {
        alertFieldErrors: alertFieldErrors,
      };
    },
  ]);
})();
