import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ListLoaderParams } from '../cms/definitions';
import { Action, ContextAction } from '../definitions';
import { Item } from '../form/definitions';
import { SpinnerProvider } from '../spinner/definitions';

export type ListLoadInitiator<T extends Item> = (params: ListLoaderParams<T>) => Promise<void>;
export type SubItemsLoadInitiator = (parentItemId: string) => Promise<void>;
export type ItemLoadInitiator = (id: string) => Promise<void>;
export type ItemReorderInitiator = (id: string, itemIds: string[]) => Promise<void>;

export interface ICmsPageContext {
  spinnerProvider: SpinnerProvider;
}

export const CmsPageContext = React.createContext({} as ICmsPageContext);

export interface PageConstants {
  pageTitle?: string;
  defaultPageId: string;
  defaultPageName: string;
  editorPageId?: string;
  editorPageName?: string;
  publishPageId?: string;
  publishPageName?: string;
  itemsPageId?: string;
  itemsPageName?: string;
  parentPageId?: string;
  parentPageName?: string;
  pluginId: string;
  typeName: string;
}

export interface WrappedActionParams<I extends Item> {
  reload: () => void;
  loaderParams?: ListLoaderParams<I>;
}

export type ItemAction<T> = Action<WrappedActionParams<T>>;
export type ItemContextAction<T> = ContextAction<T, WrappedActionParams<T>>;
export type AnyItemAction<T> = ItemAction<T> | ItemContextAction<T>;

export interface BackPageAction {
  onClick: () => void;
  pageName: string;
}

export interface ItemEditorProps<I extends Item> {
  itemId: string;
  onItemChange: (item: I) => void;
}

export interface ItemEditorFormProps<I extends Item> {
  form: UseFormReturn<I>;
}

export type ItemEditorForm<I extends Item> = React.FC<ItemEditorFormProps<I>>;
