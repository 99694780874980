import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
interface IconTooltipProps {
  className: string;
  tooltipPlacement: string;
  tooltipText: string;
}

const IconTooltip = (iconTooltipProps: IconTooltipProps) => {
  const [hovering, setHovering] = useState(false);
  const { className, tooltipPlacement, tooltipText } = iconTooltipProps;
  return (
    <OverlayTrigger
      //eslint-disable-next-line
      // @ts-ignore
      placement={tooltipPlacement}
      overlay={<Tooltip style={{ opacity: hovering ? 1 : 0 }}>{tooltipText}</Tooltip>}
    >
      {/* @TODO: remove opacity and state once upgraded to bootstrap 5 */}
      <i
        onMouseEnter={() => setHovering(true)}
        onMouseOut={() => setHovering(false)}
        className={className}
      />
    </OverlayTrigger>
  );
};

export default IconTooltip;
