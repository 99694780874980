import { useCallback, useState } from 'react';
import { ConfirmArgs, ConfirmModalProps } from '../components/ConfirmModal';

const EMPTY_MODAL: ConfirmModalProps = { visible: false, confirmArgs: {} };

const useConfirmModal = () => {
  const [modalProps, setModalProps] = useState(EMPTY_MODAL);

  const confirm = useCallback(
    (args: ConfirmArgs) => {
      return new Promise<boolean>((resolve, reject) => {
        setModalProps({
          visible: true,
          confirmArgs: args,
          onClose: (result) => {
            setModalProps(EMPTY_MODAL);
            resolve(result);
          },
        });
      });
    },
    [EMPTY_MODAL]
  );

  return {
    confirm,
    confirmProps: modalProps,
  };
};

export default useConfirmModal;
