import get from 'lodash/get';
import isBoolean from 'lodash/isBoolean';
import React, { InputHTMLAttributes, useCallback, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { FieldPath, RegisterOptions, UseFormReturn } from 'react-hook-form';
import IconTooltip from '../../generalComponents/IconTooltip';
import { Item } from '../definitions';
import { validationIncludesRequired } from '../formUtils';
import FormFieldError from './FormFieldError';

interface FormInputProps<T extends Item> extends InputHTMLAttributes<HTMLInputElement> {
  defaultChecked?: boolean;
  fieldName: FieldPath<T>;
  formContext: UseFormReturn<T>;
  formFieldOptions?: RegisterOptions<T>;
  iconClassName?: FieldPath<T>;
  inputPlaceholder?: FieldPath<T>;
  label: FieldPath<T>;
  selectedName?: FieldPath<T>;
  tooltipPlacement?: FieldPath<T>;
  tooltipText?: FieldPath<T>;
}

const FormCheck = <T extends Item>(props: FormInputProps<T>) => {
  const {
    defaultChecked,
    fieldName,
    formContext,
    formFieldOptions,
    iconClassName,
    inputPlaceholder,
    label,
    selectedName,
    tooltipPlacement,
    tooltipText,
  } = props;

  const { formState, register, trigger, watch, setValue } = formContext;
  const isRequired = validationIncludesRequired(formFieldOptions);
  const watchIsChecked = watch(selectedName);
  const [checked, setChecked] = useState(defaultChecked);
  const validate = useCallback((value) => !checked || Boolean(value), [checked]);
  useEffect(() => {
    (async () => {
      await trigger(fieldName);
    })();
  }, [trigger, fieldName, checked]);

  useEffect(() => {
    if (isBoolean(watchIsChecked)) setChecked(watchIsChecked);
  }, [watchIsChecked, selectedName, setChecked, isBoolean]);

  const onChange = useCallback(() => {
    const currentChecked = checked;
    setChecked((current) => !current);
    if (currentChecked) {
      //eslint-disable-next-line
      //@ts-ignore
      setValue(fieldName, '');
    }
  }, [checked, setChecked, setValue, fieldName]);

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
      <Form.Check type="checkbox" style={{ display: 'flex' }}>
        <Form.Check.Input onChange={onChange} checked={checked} />
        <Form.Check.Label>
          <div style={{ display: 'flex' }}>
            <span>{label}</span>
            {isRequired && <span className="required-annotation">*</span>}
            {tooltipText && (
              <div style={{ marginLeft: '1rem' }}>
                <IconTooltip
                  className={iconClassName || 'fas fa-info-circle icn-primary'}
                  tooltipText={tooltipText}
                  tooltipPlacement={tooltipPlacement || 'right'}
                />
              </div>
            )}
          </div>
        </Form.Check.Label>
      </Form.Check>
      <div style={{ marginLeft: '1rem' }}>
        <input
          defaultValue={inputPlaceholder}
          className="form-control"
          disabled={!checked}
          maxLength={255}
          {...register(fieldName, {
            ...(formFieldOptions || {}),
            validate,
          })}
          type="text"
        />
      </div>
      <FormFieldError error={get(formState, `errors.${fieldName}`)} />
    </div>
  );
};

export default FormCheck;
