import React, { useCallback } from 'react';
import classNames from 'classnames';
import { PaginationState } from '../definitions';
import { useVisiblePages } from '../hooks/useVisiblePages';

const MAX_VISIBLE_PAGES = 10;

interface PaginationControlProps extends PaginationState {
  onPageSelected: (page: number) => void;
}

const PaginationControl: React.FC<PaginationControlProps> = (props: PaginationControlProps) => {
  const { pageCount, currentPage, onPageSelected } = props;

  const pages = useVisiblePages(pageCount, currentPage, MAX_VISIBLE_PAGES);

  const selectPage = useCallback(
    (page) => {
      if (page > 0 && page !== currentPage && page <= pageCount) {
        onPageSelected(page);
      }
    },
    [onPageSelected, currentPage, pageCount]
  );

  return (
    pages.length > 1 && (
      <nav>
        <ul className="pagination">
          <li>
            <a onClick={() => selectPage(currentPage - 1)}>«</a>
          </li>
          {pages.map((page, index) => (
            <li key={index} className={classNames({ active: currentPage === page })}>
              <a onClick={() => selectPage(page)}>{page}</a>
            </li>
          ))}
          <li>
            <a onClick={() => selectPage(currentPage + 1)}>»</a>
          </li>
        </ul>
      </nav>
    )
  );
};

export default PaginationControl;
