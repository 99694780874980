import React, { useContext } from 'react';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import { CmsPageContext, ItemEditorProps } from '../../shared/cmsPage/definitions';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import useLoadEditorItem from '../../shared/cmsPage/hooks/useLoadEditorItem';
import FormInput from '../../shared/form/components/FormInput';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import { useSpinner } from '../../shared/spinner/hooks/useSpinner';
import { QuizInstance, QuizInstanceConstants } from '../definitions';
import useQuizInstanceConfigurations from '../hooks/useQuizInstanceConfigurations';

interface QuizInstanceEditor extends ItemEditorProps<QuizInstance> {
  quizId: string;
}
type QuizInstanceEditorProps = QuizInstanceEditor;

const QuizInstanceEditor: React.FC<QuizInstanceEditorProps> = (props: QuizInstanceEditorProps) => {
  const { itemId, onItemChange, quizId } = props;
  const { spinnerProvider } = useContext(CmsPageContext);
  const { api } = useQuizInstanceConfigurations(quizId);
  const { item, loadItem, saveItem, requestPending } = useItemPersistence(api);
  const form = useCmsForm<QuizInstance>(item);
  const actions = useItemEditActions(saveItem, form, QuizInstanceConstants, quizId);
  useSpinner(spinnerProvider, requestPending);
  useLoadEditorItem(itemId, loadItem, onItemChange, item, QuizInstanceConstants);

  return (
    <>
      <div className="row">
        <form role="form">
          <FormInput
            label="Name"
            fieldName="name"
            formContext={form}
            formFieldOptions={{ required: 'This field is required.' }}
          />
        </form>
      </div>
      <div className="row module-footer">
        <CmsPageSectionFooter actions={actions} />
      </div>
    </>
  );
};

export default QuizInstanceEditor;
