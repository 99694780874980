import { PageConstants } from '../shared/cmsPage/definitions';
import { Item } from '../shared/form/definitions';
import createPageConstants from '../shared/util/createPageConstants';
const pluginId = 'surveys';

export const SurveyConstants: PageConstants = createPageConstants(pluginId, 'Survey', {
  defaultDefault: 'surveysListPage',
});
export const SurveyInstanceConstants: PageConstants = createPageConstants(
  pluginId,
  'Survey Instance',
  {
    default: 'surveyInstancesPage',
    editor: 'surveyInstanceEditorPage',
  }
);

export interface Survey extends Item {
  id?: string;
  updated?: Date;
  title?: string;
  editable?: boolean;
  isArchived?: boolean;
}

export interface SurveyInstance extends Item {
  id?: string;
  name?: string;
}

export interface SurveyUpdateOptions extends Item {
  isArchived?: boolean;
}
