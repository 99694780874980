import { ListLoaderParams, ListLoaderResult } from '../../shared/cms/definitions';
import {
  EMPTY_TARGET_AUDIENCE_REVISION,
  TargetAudience,
  TargetAudienceApi,
  TargetAudienceRevision,
} from '../definitions';
import axios from 'axios';

const baseUrl = '/plugins/target-audience/api';

const loadRecordsWrapped =
  (includeArchived = true) =>
  async (params: ListLoaderParams<TargetAudience>) => {
    const { sort, ...otherParams } = params;
    const { sortField, order } = sort;

    const sortReverse = sortField ? order === 'DESC' : undefined;

    let result: ListLoaderResult<TargetAudience>;

    try {
      const { data } = await axios.get(`${baseUrl}/getTargetAudiences`, {
        params: {
          ...otherParams,
          includeArchived,
          sortField,
          sortReverse,
        },
        timeout: 10000,
      });
      const { targetAudiencesWithStatus, count, offset } = data;
      result = { records: targetAudiencesWithStatus, numRecords: count, offset };
    } catch (e) {
      console.error(e);
      throw new Error('Failed to load targetAudiences');
    }

    return result;
  };

const loadRecord = async (id: string) => {
  if (id === 'new') {
    return EMPTY_TARGET_AUDIENCE_REVISION;
  } else {
    try {
      const { data } = await axios.get(`${baseUrl}/getTargetAudience`, {
        params: {
          id,
        },
        timeout: 10000,
      });
      let parsedCondition;
      try {
        parsedCondition = data && data.targetAudience && JSON.parse(data.targetAudience.condition);
        if (parsedCondition) data.targetAudience.condition = parsedCondition;
      } catch (e) {
        parsedCondition = '';
      }
      return data.targetAudience;
    } catch (e) {
      console.error(e);
      throw new Error('Failed to load targetAudience.');
    }
  }
};

const saveRecord = async (item: TargetAudienceRevision) => {
  try {
    //eslint-disable-next-line
    const { id, ...revisionProps } = item;
    const { data } = await axios.post(
      `${baseUrl}/saveTargetAudience`,
      {
        ...revisionProps,
        id,
      },
      {
        timeout: 10000,
      }
    );

    return data.targetAudience;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to save targetAudience.');
  }
};

const archiveRecord = async (id: string) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/updateTargetAudienceOptions`,
      {
        isArchived: true,
        id,
      },
      {
        timeout: 10000,
      }
    );

    return data.targetAudience;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to save targetAudience.');
  }
};

const unarchiveRecord = async (id: string) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/updateTargetAudienceOptions`,
      {
        isArchived: false,
        id,
      },
      {
        timeout: 10000,
      }
    );

    return data.targetAudience;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to save targetAudience.');
  }
};

const removeRecord = async (item: TargetAudience) => {
  try {
    const { id } = item;

    await axios.post(
      `${baseUrl}/removeTargetAudience`,
      {
        id,
      },
      {
        timeout: 10000,
      }
    );
  } catch (e) {
    console.error(e);
    throw new Error('Failed to remove targetAudience.');
  }
};

const restoreRecord = async (item: TargetAudience) => {
  try {
    const { id } = item;

    await axios.post(
      `${baseUrl}/restoreTargetAudience`,
      {
        id,
      },
      {
        timeout: 10000,
      }
    );
  } catch (e) {
    console.error(e);
    throw new Error('Failed to restore targetAudience.');
  }
};

const publishRecord = async (id: string) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/publishTargetAudience`,
      {
        id,
      },
      {
        timeout: 10000,
      }
    );

    return data.targetAudience;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to publish targetAudience.');
  }
};

const targetAudienceApiWrapped = (includeArchived = true) => {
  const targetAudienceApi: TargetAudienceApi = {
    loadRecords: loadRecordsWrapped(includeArchived),
    loadRecord,
    saveRecord,
    removeRecord,
    restoreRecord,
    publishRecord,
    archiveRecord,
    unarchiveRecord,
  };
  return targetAudienceApi;
};

export default targetAudienceApiWrapped;
