import React from 'react';
import CmsItemEditSection from '../../shared/cmsPage/components/CmsItemEditSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import { useEditPageTitle } from '../../shared/cmsPage/hooks/useEditPageTitle';
import { SurveyInstanceConstants } from '../definitions';
import SurveyInstanceEditor from './SurveyInstanceEditor';

interface SurveyInstancesEditorPageProps {
  surveyId: string;
  surveyInstanceId: string;
}

const SurveyInstanceEditorPage: React.FC<SurveyInstancesEditorPageProps> = (
  props: SurveyInstancesEditorPageProps
) => {
  const { surveyId, surveyInstanceId } = props;
  const { editorPageId, typeName } = SurveyInstanceConstants;
  const { pageTitle, onItemChange } = useEditPageTitle(surveyInstanceId, typeName, 'name');
  return (
    <CmsPage title={pageTitle}>
      <CmsItemEditSection pageId={editorPageId}>
        <SurveyInstanceEditor
          itemId={surveyInstanceId}
          surveyId={surveyId}
          onItemChange={onItemChange}
        />
      </CmsItemEditSection>
    </CmsPage>
  );
};

export default SurveyInstanceEditorPage;
