(function () {
  angular.module('sidebar-controller', ['config-service']).controller('SideBarCtrl', [
    '$state',
    '$scope',
    'configService',
    '$sce',
    'pluginService',
    '$log',
    'navigationService',
    function ($state, $scope, configService, $sce, pluginService, $log, navigationService) {
      configService.getConfig().then(function (config) {
        $scope.sideMenuItems = [];
        setupPreview(config);

        for (var i = 0; i < config.sideMenu.length; i++) {
          var menuItem = angular.copy(config.sideMenu[i]);

          if (menuItem.navigation) {
            if (menuItem.navigation.state) {
              menuItem.uiSref = menuItem.navigation.state;

              if (menuItem.navigation.stateParams) {
                menuItem.uiSref += '(' + JSON.stringify(menuItem.navigation.stateParams) + ')';
              }
            }
          }

          $scope.sideMenuItems.push(menuItem);
        }

        $scope.navigateToMenuItem = function (menuItem) {
          navigationService.navigateToMenuItem(menuItem);
        };
      });

      function setupPreview(config) {
        $scope.showPreview = config.canPreviewAppInCMS && !config.cmsOptions.previewInMenu;
        $scope.previewSchemes = config.schemes;
      }
    },
  ]);
})();
