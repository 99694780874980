import { useCallback, useContext, useMemo, useState } from 'react';
import { Item } from '../../form/definitions';
import { BaseItemApi } from '../definitions';
import { CmsContext } from '../../../context/definitions';

export const useItemPersistence = <I extends Item, R = I>(
  itemApi: BaseItemApi<I, R>,
  parentItemId?: string,
  isContentItem = true
) => {
  const [requestPending, setRequestPending] = useState(false);
  const [item, setItem] = useState(null as R);

  const { notifyContentUpdated } = useContext(CmsContext);

  const { loadRecord, saveRecord, removeRecord, restoreRecord, reorderRecords, publishRecord } =
    itemApi;

  const loadItem = useCallback(
    async (id: string) => {
      setRequestPending(true);

      try {
        const item = await loadRecord(id, parentItemId);
        setItem(item);
      } finally {
        setRequestPending(false);
      }
    },
    [loadRecord]
  );

  const saveItem = useCallback(
    async (itemRevision: R) => {
      setRequestPending(true);

      try {
        const item = await saveRecord(itemRevision);
        setItem(item);

        if (isContentItem) {
          notifyContentUpdated();
        }

        return item;
      } finally {
        setRequestPending(false);
      }
    },
    [saveRecord]
  );

  const removeItem = useMemo(() => {
    let removeFn;
    if (removeRecord) {
      removeFn = async (itemToRemove: I) => {
        setRequestPending(true);

        try {
          await removeRecord(itemToRemove);
          setItem(undefined);

          if (isContentItem) {
            notifyContentUpdated();
          }
        } finally {
          setRequestPending(false);
        }
      };
    }

    return removeFn;
  }, [removeRecord]);

  const restoreItem = useMemo(() => {
    let restoreFn;
    if (restoreRecord) {
      restoreFn = async (itemToRemove: I) => {
        setRequestPending(true);

        try {
          await restoreRecord(itemToRemove);
          setItem(undefined);

          if (isContentItem) {
            notifyContentUpdated();
          }
        } finally {
          setRequestPending(false);
        }
      };
    }

    return restoreFn;
  }, [restoreRecord]);

  const reorderItems = useMemo(() => {
    let reorderFn;
    if (reorderRecords) {
      reorderFn = async (parentId: string, itemIds: string[]) => {
        setRequestPending(true);

        try {
          await reorderRecords(parentId, itemIds);

          if (isContentItem) {
            notifyContentUpdated();
          }
        } finally {
          setRequestPending(false);
        }
      };
    }

    return reorderFn;
  }, [reorderRecords]);

  const publishItem = useMemo(() => {
    let publishFn;
    if (publishRecord) {
      publishFn = async (id: string, itemIds?: string[]) => {
        setRequestPending(true);

        try {
          const item = await publishRecord(id, itemIds);
          setItem(item);
        } finally {
          setRequestPending(false);
        }
      };
    }

    return publishFn;
  }, [publishRecord]);

  return {
    requestPending,
    item,
    loadItem,
    saveItem,
    removeItem,
    restoreItem,
    reorderItems,
    publishItem,
  };
};
