import React from 'react';
import ItemTableCell from './ItemTableCell';
import ItemActionList from './ItemActionList';
import { ItemTableColumn } from '../definitions';
import { ContextAction } from '../../definitions';
import { Item } from '../../form/definitions';

interface ItemTableRowProps<I> {
  item: I;
  columns: ItemTableColumn<I>[];
  itemActions?: ContextAction<I>[];
}

const ItemTableRow = <I extends Item>(props: ItemTableRowProps<I>) => {
  const { item, columns, itemActions } = props;
  const showActionList = itemActions?.length > 0;

  return (
    <>
      {columns.map((column, index) => (
        <ItemTableCell key={index} item={item} column={column} />
      ))}
      {showActionList && (
        <td>
          <ItemActionList item={item} actions={itemActions} />
        </td>
      )}
    </>
  );
};

export default ItemTableRow;
