import React from 'react';
import moment from 'moment';

interface TimestampProps {
  date: Date;
}

const Timestamp: React.FC<TimestampProps> = (props: TimestampProps) => {
  const { date } = props;
  const formatted = moment(date).format('MMMM DD, YYYY hh:mm:ss A z');
  return <>{formatted}</>;
};

export default Timestamp;
