import React, { useEffect, useMemo } from 'react';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import { useEditPageTitle } from '../../shared/cmsPage/hooks/useEditPageTitle';
import { ItemTableColumn } from '../../shared/itemTable/definitions';
import cohortApi from '../api/cohortApi';
import { CohortConstants, CohortMember } from '../definitions';
import CmsListSectionToolbar from '../../shared/cmsPage/components/CmsListSectionToolbar';
import GrowlContainer from '../../shared/growl/components/GrowlContainer';
import CmsPageSection from '../../shared/cmsPage/components/CmsPageSection';
import { useListLoaderParams } from '../../shared/cms/hooks/useListLoaderParams';
import ItemTable from '../../shared/itemTable/components/ItemTable';
import { useListLoader } from '../../shared/cms/hooks/useListLoader';
import useLoadListItems from '../../shared/cmsPage/hooks/useLoadListItems';
import generateCohortMemberMatchesApi from '../api/cohortMemberMatchesApi';
import useCohortMatchesActions from '../hooks/useCohortMatchesActions';

interface CohortMatchesPageProps {
  cohortId: string;
  credentialCode: string;
}

const CohortMatchesColumns: ItemTableColumn<CohortMember>[] = [
  {
    label: 'First Name',
    valueField: 'firstName',
    sortable: true,
  },
  {
    label: 'Last Name',
    valueField: 'lastName',
    sortable: true,
  },
  {
    label: 'Email Address',
    valueField: 'email',
    sortable: true,
  },
];

const CohortMatchesPage: React.FC<CohortMatchesPageProps> = (props: CohortMatchesPageProps) => {
  const { cohortId, credentialCode } = props;

  const cohortMembersApi = useMemo(() => generateCohortMemberMatchesApi(cohortId, credentialCode), [cohortId, credentialCode]);

  const { item, loadItem, requestPending: loadItemPending } = useItemPersistence(cohortApi);
  const {
    loadItems: loadMembers,
    loading: loadMembersPending,
    records: members,
    pagination,
    sort,
  } = useListLoader(cohortMembersApi.loadRecords);

  const requestPending = loadItemPending || loadMembersPending;

  const { loaderParams, onPageSelected, onSearchChange, onSortChange } = useListLoaderParams(
    CohortConstants.itemsPageId,
    10,
    CohortMatchesColumns
  );

  const reloadMembers = useLoadListItems<CohortMember>(
    CohortConstants.itemsPageId,
    loadMembers,
    loaderParams
  );

  useEffect(() => {
    if (cohortId) {
      (async () => await loadItem(cohortId))();
    }
  }, [cohortId, loadItem]);

  const { pageTitle, onItemChange } = useEditPageTitle(cohortId, 'Cohort', 'name');
  useEffect(() => onItemChange(item), [item, onItemChange]);

  const { back } = useCohortMatchesActions({
    pluginId: CohortConstants.pluginId,
    parentPageTitle: pageTitle,
    parentPageName: CohortConstants.editorPageName,
    parentPageId:CohortConstants.editorPageId,
    parentPageArgs: [cohortId],
  });

  return (
    <CmsPage title={pageTitle} requestPending={requestPending}>
      <CmsPageSection className="cohort-matches">
        <div className="row">
          <CmsListSectionToolbar
            searchExpr={loaderParams.search}
            onSearchChange={onSearchChange}
            actions={[]}
            backPageAction={back}
            />
        </div>
        <GrowlContainer groupId={CohortConstants.itemsPageId} />
        <div className="row">
          <ItemTable<CohortMember>
            columns={CohortMatchesColumns}
            items={members}
            itemActions={[]}
            pagination={pagination}
            sort={sort}
            onPageSelected={onPageSelected}
            onSortChange={onSortChange}
          />
        </div>
      </CmsPageSection>
    </CmsPage>
  );
};

export default CohortMatchesPage;
