import React, { useMemo } from 'react';
import { PublishStatus } from '../../cms/definitions';

interface ItemPublishStatusProps {
  status: PublishStatus;
}

const ItemPublishStatus: React.FC<ItemPublishStatusProps> = (props: ItemPublishStatusProps) => {
  const { status } = props;

  const statusLabel = useMemo(() => {
    switch (status) {
      case 'pending':
        return 'Published (Changes Pending)';
      case 'published':
        return 'Published';
      case 'removed':
        return 'Removed (Changes Pending)';
      case 'unpublished':
        return 'Unpublished (Changes Pending)';
      default:
        return status;
    }
  }, [status]);

  return <>{statusLabel}</>;
};

export default ItemPublishStatus;
