import { useMemo } from 'react';
import { ItemTableColumn } from '../../shared/itemTable/definitions';
import generateQuizInstanceApi from '../api/quizInstanceApi';
import { QuizInstance } from '../definitions';
const QuizInstanceColumns: ItemTableColumn<QuizInstance>[] = [
  { label: 'Name', valueField: 'name', sortable: true, initialSort: 'ASC' },
];
export default function useQuizInstanceConfigurations(quizId: string) {
  const columns = useMemo(() => QuizInstanceColumns, [QuizInstanceColumns]);
  const api = useMemo(() => generateQuizInstanceApi(quizId), [quizId, generateQuizInstanceApi]);

  return { columns, api };
}
