import React from 'react';
import { ItemTableColumn, SortOrder } from '../definitions';
import classNames from 'classnames';
import { Item } from '../../form/definitions';

interface ItemTableHeaderCellProps<I> {
  column: ItemTableColumn<I>;
  sortOrder?: SortOrder;
  onClick?: () => void;
}

const ItemTableHeaderCell = <I extends Item>(props: ItemTableHeaderCellProps<I>) => {
  const { column, sortOrder, onClick } = props;

  const { sortable, label, className: columnClassName } = column;
  const clickHandler = sortable ? onClick : undefined;

  let indicatorClass = '';
  if (sortOrder && sortOrder !== 'NONE') {
    indicatorClass = sortOrder === 'ASC' ? 'st-sort-ascent' : 'st-sort-descent';
  }

  const align = column.align || 'left';

  const className = classNames(
    'table-header',
    align,
    { 'no-pointer': !sortable || !clickHandler },
    indicatorClass,
    columnClassName
  );
  return (
    <th className={className} onClick={clickHandler}>
      {label}
    </th>
  );
};

export default ItemTableHeaderCell;
