angular.module('data-record-service', []).service('dataRecordService', [
  '$http',
  '$q',
  'Upload',
  '$log',
  '$rootScope',
  function ($http, $q, Upload, $log, $rootScope) {
    var baseUrl = '/data-api/';

    function getDataRecord(group, key) {
      var deferred = $q.defer();
      if (group) {
        var url = baseUrl + 'get?group=' + group + '&timestamp=' + Date.now();
        if (key) {
          url += '&key=' + key;
        }
        $http.get(url).then(
          function (result) {
            deferred.resolve(result);
          },
          function (error) {
            deferred.reject(error);
          }
        );
      } else {
        deferred.reject('No group name supplied for getDataRecord');
      }
      return deferred.promise;
    }

    function upsertTemporaryFile(file, assetId) {
      var url = baseUrl + 'uploadTemporaryFiles';

      var promise = Upload.upload({
        url: url,
        method: 'POST',
        data: {
          assetData: Upload.rename(file, assetId),
        },
      });

      promise
        .progress(function (evt) {
          $log.debug('progress: ' + parseInt((100.0 * evt.loaded) / evt.total) + '%');
        })
        .success(function (data, status, headers, config) {
          $log.debug('File was added successfully.  Response: ', data);
          $rootScope.$broadcast('fileUploaded');
        })
        .error(function (err, status, headers, config) {
          $log.debug('error : status = ' + status, err);
        });

      return promise;
    }

    function upsertFileAndUpdateDataRecord(
      files,
      assetIds,
      overwrite,
      pluginName,
      group,
      key,
      data,
      version
    ) {
      var url = baseUrl + 'uploadFileAndUpdateData';
      var fileOverwrite = false;
      if (overwrite) {
        fileOverwrite = true;
      }

      var assets = [];
      let assetData = [];
      if (angular.isArray(files)) {
        if (angular.isArray(files) && assetIds.length === files.length) {
          url = baseUrl + 'uploadFilesAndUpdateData';
          for (var i = 0; i < files.length; i++) {
            var asset = {
              type: 'file',
              group: 'pluginData/' + pluginName,
              id: assetIds[i],
            };
            assets.push(asset);
            assetData.push(Upload.rename(files[i], assetIds[i]));
          }
        } else {
          throw new Error('List of files and filenames do not match');
        }
      } else {
        assets = {
          type: 'file',
          group: 'pluginData/' + pluginName,
          id: assetIds,
        };
        assetData = Upload.rename(files, assetIds);
      }

      var promise = Upload.upload({
        url: url,
        method: 'POST',
        data: {
          assetData,
          data: Upload.json({
            asset: assets,
            overwrite: fileOverwrite,
            group: group,
            value: data,
            key: key,
            version: version,
          }),
        },
      });

      promise
        .progress(function (evt) {
          $log.debug('progress: ' + parseInt((100.0 * evt.loaded) / evt.total) + '%');
        })
        .success(function (data, status, headers, config) {
          $log.debug('Asset was added successfully.  Response: ', data);
          $rootScope.$broadcast('dataUpdated');
        })
        .error(function (err, status, headers, config) {
          $log.debug('error : status = ' + status, err);
        });

      return promise;
    }

    function updateDataRecord(group, key, data, version, suppressAppSync) {
      var deferred = $q.defer();
      if (group) {
        var url = baseUrl + 'update';
        var dataToSend = {
          group: group,
          value: data,
          version: version,
          suppressAppSync: suppressAppSync,
        };
        if (key) {
          dataToSend.key = key;
        }
        $http.post(url, dataToSend).then(
          function (result) {
            deferred.resolve(result);
            $rootScope.$broadcast('dataUpdated');
            $log.debug('Data was updated successfully.  Response: ', result);
          },
          function (error) {
            deferred.reject(error);
          }
        );
      } else {
        deferred.reject('No group name supplied for getDataRecord');
      }
      return deferred.promise;
    }

    function addDataRecord(group, key, data, suppressAppSync) {
      var deferred = $q.defer();
      if (group) {
        var url = baseUrl + 'create';
        var dataToSend = {
          group: group,
          value: data,
          suppressAppSync: suppressAppSync,
        };
        if (key) {
          dataToSend.key = key;
        }
        $http.post(url, dataToSend).then(
          function (result) {
            deferred.resolve(result);
            $rootScope.$broadcast('dataUpdated');
            $log.debug('Data was added successfully.  Response: ', result);
          },
          function (error) {
            deferred.reject(error);
          }
        );
      } else {
        deferred.reject('No group name supplised for getDataRecord');
      }
      return deferred.promise;
    }

    function removeDataRecord(group, key) {
      var deferred = $q.defer();
      if (group) {
        var url = baseUrl + 'remove';
        var dataToSend = {
          group: group,
        };
        if (key) {
          dataToSend.key = key;
        }
        $http.post(url, dataToSend).then(
          function (result) {
            deferred.resolve(result);
            $rootScope.$broadcast('dataUpdated');
            $log.debug('Data was removed successfully.  Response: ', result);
          },
          function (error) {
            deferred.reject(error);
          }
        );
      } else {
        deferred.reject('No group name supplied for getDataRecord');
      }
      return deferred.promise;
    }

    return {
      getDataRecord: getDataRecord,
      removeDataRecord: removeDataRecord,
      addDataRecord: addDataRecord,
      updateDataRecord: updateDataRecord,
      upsertFileAndUpdateDataRecord: upsertFileAndUpdateDataRecord,
      upsertTemporaryFile: upsertTemporaryFile,
    };
  },
]);
