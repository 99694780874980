import useItemListActions from '../../shared/cmsPage/hooks/useItemListActions';
import menuApi from '../api/menuApi';
import { Menu, MenuConstants, MenuRevision } from '../definitions';
import useMigrateMenusAction from './useMigrateMenusAction';
import { useContext, useMemo } from 'react';
import { WrappedActionParams } from '../../shared/cmsPage/definitions';
import { isSystemMenu } from '../menuUtils';
import { CmsContext } from '../../context/definitions';

type ShowMenuModalCallback = (menu: MenuRevision) => Promise<MenuRevision>;

const useMenuListActions = (showMenuEditorModal: ShowMenuModalCallback) => {
  const { defaultPageId } = MenuConstants;

  const { navigator } = useContext(CmsContext);

  const {
    add,
    edit,
    remove,
    restore,
    publish,
    requestPending: itemActionRequestPending,
  } = useItemListActions(menuApi, MenuConstants);

  const { migrateMenus, requestPending: migrationRequestPending } = useMigrateMenusAction(
    defaultPageId,
    menuApi
  );

  const addOverride = useMemo(() => {
    return {
      ...add,
      onClick: async ({ loaderParams }: WrappedActionParams<Menu>) => {
        const newMenu = await menuApi.loadRecord('new');
        const savedItem = await showMenuEditorModal(newMenu);
        if (savedItem) {
          navigator.navigateToChildPage(
            MenuConstants.pluginId,
            MenuConstants.editorPageName,
            MenuConstants.defaultPageId,
            loaderParams,
            savedItem.menu_id
          );
        }
      },
    };
  }, [add, menuApi, navigator, showMenuEditorModal]);

  const removeOverride = useMemo(() => {
    return {
      ...remove,
      disabled: (context: Menu) => isSystemMenu(context),
    };
  }, [remove]);

  const requestPending = useMemo(() => {
    return itemActionRequestPending || migrationRequestPending;
  }, [itemActionRequestPending, migrationRequestPending]);

  return useMemo(() => {
    return {
      add: addOverride,
      edit,
      remove: removeOverride,
      restore,
      publish,
      migrateMenus,
      requestPending,
    };
  }, [addOverride, edit, removeOverride, restore, publish, migrateMenus, requestPending]);
};

export default useMenuListActions;
