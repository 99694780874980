(function () {
  angular
    .module('new-plain-editor', ['smart-table', 'content-api-service', 'confirm-dialog'])

    .controller('newPlainEditFileController', [
      '$rootScope',
      '$scope',
      '$modal',
      '$log',
      'growl',
      'contentApiService',
      '$state',
      '$transition$',
      'confirmDialog',
      'instanceService',
      function (
        $rootScope,
        $scope,
        $modal,
        $log,
        growl,
        contentApiService,
        $state,
        $transition$,
        confirmDialog,
        instanceService
      ) {
        const stateParams = $transition$.params();
        var originalfileId;
        var originalGroup;
        var originalContents;
        var overwrite = false;

        initializeAssetData();

        function initializeAssetData() {
          overwrite = false;
          $scope.group = {};
          $scope.revision = {};
          $scope.fileId = {
            text: '',
          };
          $scope.contents = {
            text: '',
          };
          $scope.group = { text: 'default' };

          if (stateParams.assetId) {
            overwrite = true;
            $scope.fileId = {
              text: stateParams.assetId,
            };
            contentApiService.getAssetMetadata(stateParams.assetId).then(
              function (assetMetadata) {
                $scope.asset = angular.copy(assetMetadata);
                $scope.revision = angular.copy(getActiveRevision(assetMetadata));
                contentApiService
                  .getAsset(stateParams.assetId, $scope.revision.id, true)
                  .success(function (data, status, headers, config) {
                    $scope.contents = {
                      text: data,
                    };
                    originalContents = angular.copy($scope.contents);
                  })
                  .error(function (data, status, headers, config) {
                    $log.debug('Failed to load asset metadata. Response: ', data);
                    $scope.contents = {
                      text: '',
                    };
                    $scope.group = { text: 'default' };
                  });
                originalGroup = $scope.revision.group;
                $scope.group = { text: originalGroup };
              },
              function (error) {
                growl.error(
                  'An error occured while trying to load the data.  Please try again later'
                );
              }
            );
          }

          if (typeof stateParams.editable == 'undefined') {
            $scope.editable = $state.current.data.defaultEditable == 1;
          } else {
            $scope.editable = stateParams.editable == 1;
          }

          $scope.groups = contentApiService.getGroups();

          originalfileId = angular.copy($scope.fileId);
          originalContents = angular.copy($scope.contents);
        }

        $scope.save = function (closeWhenSaved) {
          var asset = {};
          if (!$scope.fileId || !$scope.fileId.text) {
            growl.error('No Id Specified');
            return;
          }

          if (!$scope.group || !$scope.group.text) {
            growl.error('No Group Specified');
            return;
          }

          if (!$scope.needsSave()) {
            if (closeWhenSaved) {
              $scope.close();
            } else {
              growl.error('No changes to save');
            }
            return;
          }

          function endsWith(str, suffix) {
            return str.match(suffix + '$') == suffix;
          }

          asset.type = 'file';
          if ($scope.assetType) {
            asset.type = $scope.assetType;
            if (
              asset.type != 'menu' &&
              asset.type != 'page' &&
              !endsWith($scope.fileId.text, '.' + asset.type)
            ) {
              $scope.fileId.text = $scope.fileId.text + '.' + asset.type;
            }
          }
          asset.id = contentApiService.scrubAssetId($scope.fileId.text);
          asset.group = $scope.group.text;
          asset.latestRevision = $scope.revision;

          if ($scope.asset && $scope.asset.instanceMappings) {
            asset.instanceMappings = $scope.asset.instanceMappings;
          }

          contentApiService
            .saveAsset($scope.contents.text, asset, overwrite)
            .success(function (data, status, headers, config) {
              $log.debug('Successfully saved asset. Response: ', data);
              if (closeWhenSaved) {
                $scope.close(true);
              } else {
                growl.success('Asset saved successfully');
                initializeAssetData();
              }
            })
            .error(function (data, status, headers, config) {
              $log.debug('Failed to save. Response: ', data);
              growl.error(data.error);
            });
        };

        $scope.reset = function () {
          $scope.fileId = angular.copy(originalfileId);
          $scope.contents = angular.copy(originalContents);
        };

        $scope.needsSave = function () {
          return (
            !angular.equals($scope.contents, originalContents) ||
            !angular.equals($scope.fileId, originalfileId) ||
            $scope.group.text != originalGroup
          );
        };

        $scope.close = function (forceClose) {
          if (!forceClose && $scope.needsSave()) {
            confirmDialog({
              title: 'Close Without Saving?',
              body: 'You have unsaved changes.  Are you sure you want to close without saving?',
              confirmText: 'Close Without Saving',
              cancelText: 'Continue Editing',
            }).result.then(function (result) {
              $state.go('main.manage-assets');
            });
          } else {
            $state.go('main.manage-assets');
          }
        };

        $scope.saveAndClose = function () {
          $scope.save(true);
        };

        $scope.setFileType = function (type) {
          if (type) {
            $scope.assetType = type;
          }
        };

        $scope.applyTemplate = function (template) {};

        function getActiveRevision(asset) {
          return contentApiService.getRevisionForInstance(
            asset,
            instanceService.getActiveInstance().id,
            true
          );
        }
      },
    ]);
})();
