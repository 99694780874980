import React from 'react';
import ActionButton from '../../actionButton/components/ActionButton';
import { ContextAction } from '../../definitions';
import { Item } from '../../form/definitions';
import { checkFlagOrFunction } from '../../util/checkFlagOrFunction';
import useItemActionListVisible from '../hooks/useItemActionListVisible';

interface ItemActionListProps<I> {
  item: I;
  actions: ContextAction<I>[];
}

const ItemActionList = <I extends Item>(props: ItemActionListProps<I>) => {
  const { item, actions } = props;
  const { ref, isVisible, setIsVisible } = useItemActionListVisible(false);

  const ellipsisClick = () => {
    setIsVisible((isVisible) => !isVisible);
  };

  return (
    <div ref={ref} className="action-buttons pull-right">
      <div
        className="action-ellipsis fa-regular fa-ellipsis-vertical"
        onClick={ellipsisClick}
      ></div>
      <div className={isVisible ? 'action-menu' : 'hidden'}>
        {actions.map((action, index) => {
          const { disabled, visible } = action;
          const itemDisabled = checkFlagOrFunction(disabled, item);
          const itemVisible = typeof visible === 'undefined' || checkFlagOrFunction(visible, item);

          return (
            itemVisible && (
              <ActionButton
                key={index}
                icon={action.icon}
                label={typeof action.label === 'function' ? action.label(item) : action.label}
                disabled={itemDisabled}
                onClick={() => {
                  setIsVisible(false);
                  action.onClick(item);
                }}
              />
            )
          );
        })}
      </div>
    </div>
  );
};

export default ItemActionList;
