import { getWysiwygOptions } from '../common/getWysiwygOptions';

angular.module('wysiwyg-config-service', ['config-service']).service('wysiwygConfigService', [
  '$http',
  '$q',
  'uuid2',
  'configService',
  '$rootScope',
  'WYSIWYG_OPTIONS',
  '$timeout',
  function ($http, $q, uuid2, configService, $rootScope, WYSIWYG_OPTIONS, $timeout) {
    let configDeferred;
    let wysiwygOptions;

    function getOptionsWithPluginName(pluginName) {
      var tempOptions = angular.copy(wysiwygOptions);

      if (pluginName) {
        if (
          tempOptions.imageUploadParams &&
          tempOptions.imageUploadParams.data &&
          tempOptions.imageUploadParams.data.asset
        ) {
          tempOptions.imageUploadParams.data.asset.group = 'pluginData/' + pluginName;
        }

        if (
          tempOptions.videoUploadParams &&
          tempOptions.videoUploadParams.data &&
          tempOptions.videoUploadParams.data.asset
        ) {
          tempOptions.videoUploadParams.data.asset.group = 'pluginData/' + pluginName;
        }

        tempOptions.imageManagerLoadParams = tempOptions.imageManagerLoadParams
          ? tempOptions.imageManagerLoadParams
          : {};
        tempOptions.imageManagerLoadParams.group = 'pluginData/' + pluginName;

        tempOptions.videoManagerLoadParams = tempOptions.videoManagerLoadParams
          ? tempOptions.videoManagerLoadParams
          : {};
        tempOptions.videoManagerLoadParams.group = 'pluginData/' + pluginName;
      }

      return tempOptions;
    }

    async function getWysiwygOptionsOnce() {
      if (!configDeferred) {
        configDeferred = $q.defer();

        const cmsConfig = await configService.getConfig();
        wysiwygOptions = await getWysiwygOptions(cmsConfig, createWysiwygListener());
        configDeferred.resolve(wysiwygOptions);
      }

      return configDeferred.promise;
    }

    function createWysiwygListener() {
      return {
        onCodeViewChanged(active, editor) {
          $rootScope.$broadcast('froala.codeView.changed', active, editor);
        },
        onImageInserted(e, editor) {
          $rootScope.$broadcast('froalaEditor.imageInserted', e, editor);
        },
        onInitialized(e, editor) {
          $rootScope.$broadcast('froalaEditor.initialized', e, editor);
        },
        onVideoInserted(e, editor) {
          $rootScope.$broadcast('froalaEditor.videoInserted', e, editor);
        },
      };
    }

    return {
      getWysiwygOptions: getWysiwygOptionsOnce,
      getOptionsWithPluginName: getOptionsWithPluginName,
    };
  },
]);
