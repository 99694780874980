angular
  .module('common-directives', ['common-filters', 'message-service'])
  .directive('redir', [
    '$http',
    function ($http) {
      return {
        restrict: 'A',
        link: function (scope, element, attrs) {
          element.on('click', function (e) {
            e.preventDefault();
            window.location = attrs.href;
          });
        },
      };
    },
  ])
  .directive('bindHtmlCompile', [
    '$compile',
    function ($compile) {
      return {
        restrict: 'AE',
        link: function (scope, element, attrs) {
          scope.$watch(
            function () {
              return scope.$eval(attrs.bindHtmlCompile);
            },
            function (value) {
              // In case value is a TrustedValueHolderType, sometimes it
              // needs to be explicitly called into a string in order to
              // get the HTML string.
              element.html(value && value.toString());
              // If scope is provided use it, otherwise use parent scope
              var compileScope = scope;
              if (attrs.bindHtmlScope) {
                compileScope = scope.$eval(attrs.bindHtmlScope);
              }
              $compile(element.contents())(compileScope);
            }
          );
        },
      };
    },
  ])
  .directive('growlItem', [
    '$http',
    '$location',
    '$anchorScroll',
    function ($http, $location, $anchorScroll) {
      return {
        restrict: 'C',
        link: function (scope, element, attrs) {
          $(element).addClass('ng-animate-enabled');
          $location.hash(attrs.containerId);
          $anchorScroll();
        },
      };
    },
  ])
  .directive('message', [
    'messageService',
    function (messageService) {
      return {
        restrict: 'E',
        scope: { key: '@' },
        link: function (scope, element, attrs) {
          messageService.getMessage(scope.key).then(function (messageText) {
            element.replaceWith(messageText || scope.key);
          });
        },
      };
    },
  ])
  .directive('messageKey', [
    'messageService',
    function (messageService) {
      return {
        restrict: 'A',
        scope: { messageKey: '@' },
        link: function (scope, element, attrs) {
          messageService.getMessage(scope.messageKey).then(function (messageText) {
            element.text(messageText || scope.messageKey);
          });
        },
      };
    },
  ])
  .directive('includeReplace', function () {
    return {
      require: 'ngInclude',
      restrict: 'A' /* optional */,
      link: function (scope, el, attrs) {
        el.replaceWith(el.children());
      },
    };
  })
  .directive('qrCode', function () {
    return {
      restrict: 'A',
      scope: {
        value: '@',
        width: '@',
        height: '@',
      },
      link: function (scope, element, attrs) {
        var qrCode = new QRCode(element[0], {
          text: scope.value,
          width: scope.width || 256,
          height: scope.height || 256,
        });

        scope.$watch(
          'value',
          _.debounce(function (val) {
            qrCode.clear();
            qrCode.makeCode(val);
          }, 300)
        );
      },
    };
  });
