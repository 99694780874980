import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

interface CmsPageSectionProps extends PropsWithChildren<any> {
  title?: string;
  className?: string;
  isDetail?: true;
}

const CmsPageSection: React.FC<CmsPageSectionProps> = (props: CmsPageSectionProps) => {
  const { title, className, isDetail, children } = props;

  return (
    <div className={classNames('module', 'row', className, { red: !isDetail })}>
      {title && (
        <div className="module-header">
          <span className="module-title">{title}</span>
        </div>
      )}
      <div className="col-xs-12">
        <div className="module-body">{children}</div>
      </div>
    </div>
  );
};

export default CmsPageSection;
