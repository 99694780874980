import React, { useContext } from 'react';
import ItemTable from '../../itemTable/components/ItemTable';
import { useListLoaderParams } from '../../cms/hooks/useListLoaderParams';
import { useListLoader } from '../../cms/hooks/useListLoader';
import { ItemApi } from '../../cms/definitions';
import { ItemTableColumn } from '../../itemTable/definitions';
import CmsPageSection from './CmsPageSection';
import { Item } from '../../form/definitions';
import { CmsPageContext, ItemAction, ItemContextAction, BackPageAction } from '../definitions';
import { useSpinner } from '../../spinner/hooks/useSpinner';
import GrowlContainer from '../../growl/components/GrowlContainer';
import useWrapPageActions from '../hooks/useWrapPageActions';
import useLoadListItems from '../hooks/useLoadListItems';
import CmsListSectionToolbar from './CmsListSectionToolbar';

interface CmsItemListSectionProps<I extends Item, R = I> {
  toolbarActions: ItemAction<I>[];
  itemActions: ItemContextAction<I>[];
  columns: ItemTableColumn<I>[];
  typeName: string;
  pageId: string;
  itemApi: ItemApi<I, R>;
  className?: string;
  pageSize?: number;
  backPageAction?: BackPageAction;
}

const CmsItemListSection = <T extends Item, R = T>(props: CmsItemListSectionProps<T, R>) => {
  const {
    pageSize,
    pageId,
    columns,
    toolbarActions,
    itemActions,
    itemApi,
    className,
    backPageAction,
  } = props;

  const { spinnerProvider } = useContext(CmsPageContext);

  const { loadRecords } = itemApi;
  const { loadItems, loading, records, pagination, sort } = useListLoader(loadRecords);
  useSpinner(spinnerProvider, loading);

  const { loaderParams, onSearchChange, onPageSelected, onSortChange } = useListLoaderParams(
    pageId,
    pageSize,
    columns
  );
  const reload = useLoadListItems(pageId, loadItems, loaderParams);

  const wrappedToolbarActions = useWrapPageActions(
    pageId,
    toolbarActions,
    reload,
    loaderParams
  ) as ItemAction<T>[];
  const wrappedItemActions = useWrapPageActions(
    pageId,
    itemActions,
    reload,
    loaderParams
  ) as ItemContextAction<T>[];

  return (
    <>
      <CmsPageSection className={className}>
        <div className="row">
          <CmsListSectionToolbar
            actions={wrappedToolbarActions}
            searchExpr={loaderParams.search}
            onSearchChange={onSearchChange}
            backPageAction={backPageAction}
          />
        </div>
        <GrowlContainer groupId={pageId} />
        <div className="row">
          <ItemTable<T>
            columns={columns}
            items={records}
            itemActions={wrappedItemActions}
            pagination={pagination}
            sort={sort}
            onPageSelected={onPageSelected}
            onSortChange={onSortChange}
          />
        </div>
      </CmsPageSection>
    </>
  );
};

export default CmsItemListSection;
