import classNames from 'classnames';
import React, { SelectHTMLAttributes, useEffect } from 'react';
import { FieldPath, RegisterOptions, UseFormReturn } from 'react-hook-form';
import Spinner from '../../spinner/components/Spinner';
import useApiWithPendingStatus from '../../util/hooks/useApiWithPendingStatus';
import { InputWidth, Item } from '../definitions';
import { validationIncludesRequired } from '../formUtils';
import FormFieldError from './FormFieldError';

export interface SelectOption {
  label: string;
  value: string;
}

export interface SelectProps<T extends Item> extends SelectHTMLAttributes<HTMLSelectElement> {
  label: string;
  fieldName: FieldPath<T>;
  placeholder?: string;
  fieldWidth?: InputWidth;
  formFieldOptions?: RegisterOptions<T>;
  formContext: UseFormReturn<T>;
  options?: SelectOption[];
  optionsLoader?: () => Promise<SelectOption[]>;
}

const Select = <T extends Item>(props: SelectProps<T>) => {
  const {
    label,
    fieldName,
    placeholder,
    formFieldOptions,
    formContext,
    fieldWidth,
    className: providedClassName,
    options,
    optionsLoader,
    ...selectAttributes
  } = props;

  const { formState, register } = formContext;
  const { errors } = formState;

  const {
    requestPending,
    result: selectOptions,
    callApi,
  } = useApiWithPendingStatus(optionsLoader, options);

  useEffect(() => {
    if (callApi) {
      (async () => callApi())();
    }
  }, [callApi]);

  const fieldError = errors[fieldName];
  const inputWidth = fieldWidth || 'normal';

  const value = formContext.getValues()[fieldName];

  const isRequired = validationIncludesRequired(formFieldOptions);

  return (
    <div
      className={classNames('form-group', providedClassName, `input-${inputWidth}`, {
        'has-error': !!fieldError,
      })}
    >
      <label>
        {label}
        {isRequired && <span className="required-annotation">*</span>}
      </label>

      {requestPending ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <select
          className="form-control"
          {...register(fieldName, formFieldOptions)}
          {...selectAttributes}
          defaultValue={value || ''}
        >
          {placeholder && (
            <option key="placeholder" value="" disabled>
              {placeholder}
            </option>
          )}
          {selectOptions &&
            selectOptions.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
        </select>
      )}

      <FormFieldError error={fieldError} />
    </div>
  );
};

export default Select;
