import React, { useCallback, useContext } from 'react';
import { FieldPath, UnpackNestedValue, UseFormReturn } from 'react-hook-form';
import { FieldPathValue } from 'react-hook-form/dist/types/path';
import { CmsContext } from '../../../context/definitions';
import { Item } from '../definitions';
import FormInput from './FormInput';

interface LinkFinderProps<I extends Item> {
  label: string;
  fieldName: FieldPath<I>;
  formContext: UseFormReturn<I>;
  required?: boolean;
  allowEmbeddedMenus?: boolean;
}

const LinkFinder = <I extends Item>(props: LinkFinderProps<I>) => {
  const { label, fieldName, formContext, required, allowEmbeddedMenus } = props;
  const formFieldOptions = required ? { required: 'This field is required.' } : {};

  const { showLinkSearch } = useContext(CmsContext);

  const findLink = useCallback(async () => {
    const currentValue = formContext.getValues()[fieldName];

    const linkExpr = (await showLinkSearch(currentValue, allowEmbeddedMenus)) as UnpackNestedValue<
      FieldPathValue<I, FieldPath<I>>
    >;

    formContext.setValue(fieldName, linkExpr || currentValue, {
      shouldDirty: !!linkExpr,
      shouldTouch: true,
      shouldValidate: true,
    });
  }, [formContext, fieldName, showLinkSearch, allowEmbeddedMenus]);

  return (
    <div className="link-finder">
      <FormInput
        label={label}
        fieldName={fieldName}
        formContext={formContext}
        formFieldOptions={formFieldOptions}
      />
      <i className="fa fa-search clickable" onClick={findLink} />
    </div>
  );
};

export default LinkFinder;
