import { ConditionConjunction, ConditionExpr, ConditionRule } from './definitions';

export function conditionToText(condition: ConditionExpr) {
  const result = condition ? rulesToString(condition.operator, condition.rules || []) : '';

  return result ? `Users ${result}` : 'All Users';

  function rulesToString(operator: ConditionConjunction, rules: ConditionRule[]) {
    let result = '';

    rules.forEach(function (ruleItem) {
      if (result.length > 0) {
        result += ' ' + operator.toLocaleLowerCase() + ' ';
      }

      if (ruleItem.data) {
        let itemString =
          ruleItem.condition === '=' ? `in ${ruleItem.field} ` : `not in ${ruleItem.field} `;
        let list = '';
        ruleItem.data.forEach(function (exprItem) {
          if (list.length > 0) {
            list += ',';
          }
          list += exprItem.label;
        });

        itemString += list;

        result += itemString;
      } else if (ruleItem.logic) {
        const addParens = rules.length > 1 && ruleItem.logic.rules.length > 1;
        if (addParens) {
          result += '(';
        }

        result += rulesToString(ruleItem.logic.operator, ruleItem.logic.rules);

        if (addParens) {
          result += ')';
        }
      }
    });

    return result || 'None Specified';
  }
}
