import React from 'react';
import Spinner from './Spinner';

interface MultiSpinnerProps {
  spinners: Set<string>;
}

const MultiSpinner: React.FC<MultiSpinnerProps> = (props: MultiSpinnerProps) => {
  const { spinners } = props;

  return spinners.size > 0 && <Spinner />;
};

export default MultiSpinner;
