import { PageConstants } from '../shared/cmsPage/definitions';
import { Item } from '../shared/form/definitions';
import createPageConstants from '../shared/util/createPageConstants';

export const MeetConstants: PageConstants = createPageConstants('meets', 'Meet', {
  defaultDefault: 'meetsList',
  editor: 'meetsEditor',
});

export interface Meet extends Item {
  id?: string;
  updated?: Date;
  title?: string;
  type?: string;
  group?: Group;
}

export interface MeetTypes {
  meeting: string;
  broadcast: string;
  vod: string;
}

export interface Group extends Item {
  canAssign: boolean;
  canEdit: boolean;
  createdInSubtenantId: number;
  defaultAccessible: boolean;
  id: number;
  isDefault: boolean;
  isSystemGroup: true;
  key: string;
  name: string;
}

export const EMPTY_MEET: Meet = {
  title: '',
  type: '',
};

export const meetTypeOptions: MeetTypes = {
  meeting: 'Meeting',
  broadcast: 'Broadcast',
  vod: 'Video on Demand',
};

export const meetTypeTitles: MeetTypes = {
  meeting: 'Meetings',
  broadcast: 'Broadcasts',
  vod: 'Videos on Demand',
};
