import { useCallback, useState } from 'react';
import { Item } from '../../form/definitions';

export const useEditPageTitle = <I extends Item>(
  id: string,
  typeName: string,
  labelField: keyof I
) => {
  const [pageTitle, setPageTitle] = useState('');

  const onItemChange = useCallback(
    (item: I) => {
      let newTitle;

      // Showing edit page for new item
      if (!item?.id && id === 'new') {
        newTitle = `Add ${typeName}`;
      } else {
        // Showing edit page for existing item which may not yet have been loaded.
        const itemName = item && (item as any)[labelField];
        newTitle = itemName ? `Edit '${itemName}'` : `Edit ${typeName}`;
      }

      setPageTitle(newTitle);
    },
    [id, typeName, labelField, setPageTitle]
  );

  return {
    pageTitle,
    onItemChange,
  };
};
