angular.module('instance-service', []).service('instanceService', [
  '$rootScope',
  'CMS_INSTANCES',
  function ($rootScope, CMS_INSTANCES) {
    var instances = [];
    for (var instanceId in CMS_INSTANCES) {
      instances.push(CMS_INSTANCES[instanceId]);
    }

    var activeInstance = getInstanceById(window.localStorage.cms_instance) || CMS_INSTANCES.sandbox;

    function setActiveInstance(instance) {
      activeInstance = instance;
      window.localStorage.cms_instance = instance.id;
      $rootScope.$broadcast('cmsInstanceChanged');
    }

    function getActiveInstance() {
      return activeInstance;
    }

    function getInstanceById(instanceId) {
      var result;

      if (instanceId) {
        for (var i = 0; i < instances.length; i++) {
          var instance = instances[i];
          if (instance.id == instanceId) {
            result = instance;
          }
        }
      }

      return result;
    }

    return {
      instances: instances,
      setActiveInstance: setActiveInstance,
      getActiveInstance: getActiveInstance,
    };
  },
]);
