(function () {
  angular.module('edit-group', ['group-api-service']).controller('EditGroupCtrl', [
    '$scope',
    '$log',
    '$timeout',
    'growl',
    'groupApiService',
    function ($scope, $log, $timeout, growl, groupApiService) {
      $scope.group = $scope.group ? angular.copy($scope.group) : {};
      $scope.modeLabel = $scope.replaceMode ? 'Edit' : 'Add';

      $scope.cancel = function () {
        $scope.$dismiss();
      };

      $scope.nameUpdated = function () {
        if (!$scope.replaceMode) {
          $timeout(function () {
            $scope.group.key = computeKey($scope.group.name);
          });
        }
      };

      $scope.save = function () {
        groupApiService
          .saveGroup($scope.group, !$scope.replaceMode)
          .success(function (data, status, headers, config) {
            if (data.status == 'error') {
              growl.error(data.error);
            } else {
              $scope.$close(data);
            }
          })
          .error(function (err, status, headers, config) {
            growl.error(err.error || 'Group update failed.  Please try again later.');
          });
      };

      function computeKey(groupName) {
        return groupName.toLowerCase().replace(new RegExp('[^a-zA-Z_0-9]', 'g'), '_');
      }
    },
  ]);
})();
