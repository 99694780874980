import kebabCase from 'lodash/kebabCase';
import map from 'lodash/map';
import { PageConstants } from '../../shared/cmsPage/definitions';

interface Pages {
  default?: string;
  defaultDefault?: string;
  editor?: string;
  editorDefault?: string;
  parent?: string;
  parentDefault?: string;
  publish?: string;
  publishDefault?: string;
}

export default function createPageConstants(
  pluginId: string,
  typeName: string,
  pages: Pages,
  pageTitle?: string
): PageConstants {
  return {
    pluginId,
    typeName,
    ...(pageTitle && { pageTitle }),
    ...map(pages, (value: string, key: string) => {
      let defaultValues = {};
      if (key.includes('Default')) {
        key = key.replace('Default', '');
        defaultValues = { [`${key}PageName`]: 'default' };
      }

      return {
        [`${key}PageId`]: kebabCase(value),
        [`${key}PageName`]: value,
        ...defaultValues,
      };
    }).reduce(
      (a: any, values: any) => ({
        ...a,
        ...values,
      }),
      {}
    ),
  };
}
