import { useCallback, useEffect, useState } from 'react';

export const useMultiSelect = () => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [items, setItems] = useState([]);

  const handleSelectAll = useCallback(() => {
    setSelectedItems((selectedItems) => (selectedItems.length > 0 ? [] : items));
  }, [items, setSelectAll, setSelectedItems]);

  const handleSelect = useCallback(
    (event: any) => {
      const { id, checked } = event.target;
      checked
        ? setSelectedItems((selectedItems) => [...selectedItems, id])
        : setSelectedItems((selectedItems) => selectedItems.filter((item) => item !== id));
    },
    [setSelectedItems]
  );

  useEffect(() => {
    if (items.length && items.length === selectedItems.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [items, selectedItems]);

  return {
    selectAll,
    selectedItems,
    handleSelectAll,
    handleSelect,
    setItems,
  };
};

export default useMultiSelect;
