angular
  .module('social-inputs-directives', [])
  .directive('socialInputs', function () {
    return {
      restrict: 'E',
      templateUrl: 'shared/social-directive-template.html',
    };
  })
  .service('socialValueService', [
    '$filter',
    '$browser',
    function ($filter, $browser) {
      return function ($scope, $element, $attrs, ngModelCtrl, url) {
        var replaceString = new RegExp(url, 'g');
        var listener = function () {
          var value = $element.val() ? $element.val().replace(replaceString, '') : '';
          $element.val(value);
        };

        // This runs when we update the text field
        ngModelCtrl.$parsers.push(function (viewValue) {
          return viewValue ? url + viewValue.replace(replaceString, '') : '';
        });

        // This runs when the model gets updated on the scope directly and keeps our view in sync
        ngModelCtrl.$render = function () {
          $element.val(
            ngModelCtrl.$viewValue ? ngModelCtrl.$viewValue.replace(replaceString, '') : ''
          );
        };

        var keys = [];
        $element.bind('change', listener);
        $element.bind('keydown', function (event) {
          var key = event.keyCode;
          keys[key] = true;
          // If the keys include the CTRL, SHIFT, ALT, or META keys, or the arrow keys, do nothing.
          // This lets us support copy and paste too
          if (
            key == 91 ||
            (15 < key && key < 19) ||
            (37 <= key && key <= 40) ||
            ((keys[17] || keys[91]) && keys[65])
          ) {
            return;
          }
          $browser.defer(listener); // Have to do this or changes don't get picked up properly
        });
        $element.bind('keyup', function (event) {
          var key = event.keyCode;
          keys[key] = false;
        });

        $element.bind('paste cut', function () {
          $browser.defer(listener);
        });
      };
    },
  ])
  .directive('twitterInput', [
    'socialValueService',
    function (socialValueService) {
      return {
        require: 'ngModel',
        link: function ($scope, $element, $attrs, ngModelCtrl) {
          socialValueService($scope, $element, $attrs, ngModelCtrl, 'https://twitter.com/');
        },
      };
    },
  ])
  .directive('googleplusInput', [
    'socialValueService',
    function (socialValueService) {
      return {
        require: 'ngModel',
        link: function ($scope, $element, $attrs, ngModelCtrl) {
          socialValueService($scope, $element, $attrs, ngModelCtrl, 'https://plus.google.com/');
        },
      };
    },
  ])
  .directive('linkedinInput', [
    'socialValueService',
    function (socialValueService) {
      return {
        require: 'ngModel',
        link: function ($scope, $element, $attrs, ngModelCtrl) {
          socialValueService($scope, $element, $attrs, ngModelCtrl, 'https://www.linkedin.com/');
        },
      };
    },
  ])
  .directive('facebookInput', [
    'socialValueService',
    function (socialValueService) {
      return {
        require: 'ngModel',
        link: function ($scope, $element, $attrs, ngModelCtrl) {
          socialValueService($scope, $element, $attrs, ngModelCtrl, 'https://www.facebook.com/');
        },
      };
    },
  ]);
