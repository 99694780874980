import { useCallback, useState } from 'react';
import { Item } from '../../form/definitions';
import { SubItemsLoader } from '../definitions';

export const useSubItemsLoader = <T extends Item>(subItemsLoader: SubItemsLoader<T>) => {
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([] as T[]);

  const loadItems = useCallback(
    async (parentItemId: string) => {
      setLoading(true);

      try {
        const records: T[] = await subItemsLoader(parentItemId);
        setRecords(records);
      } catch (e) {
        setRecords([]);

        throw e;
      } finally {
        setLoading(false);
      }
    },
    [subItemsLoader, setLoading, setRecords]
  );

  return {
    loadItems,
    loading,
    records,
  };
};
