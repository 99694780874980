import React, { useContext, useEffect } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import useWysiwygOptions from '../hooks/useWysiwygOptions';
import { Item } from '../definitions';
import { Controller, FieldPath, RegisterOptions, UseFormReturn } from 'react-hook-form';
import { validationIncludesRequired } from '../formUtils';
import FormFieldError from './FormFieldError';
import classNames from 'classnames';
import { CmsContext } from '../../../context/definitions';

interface FormWysiwygProps<T extends Item> {
  label: string;
  tag: string;
  fieldName: FieldPath<T>;
  formContext: UseFormReturn<T>;
  formFieldOptions?: RegisterOptions<T>;
  growlId: string;
}

const FormWysiwyg = <T extends Item>(props: FormWysiwygProps<T>) => {
  const { label, tag, fieldName, formContext, formFieldOptions, growlId } = props;

  const isRequired = validationIncludesRequired(formFieldOptions);

  const { growlProvider } = useContext(CmsContext);
  const { error } = growlProvider;

  const { formState, control } = formContext;
  const { errors } = formState;
  const fieldError = errors[fieldName];

  const { wysiwygOptions, error: loadOptionsError } = useWysiwygOptions();

  useEffect(() => {
    if (loadOptionsError) {
      error(growlId, loadOptionsError);
    }
  }, [loadOptionsError, error]);

  return (
    <div className={classNames('form-group', { 'has-error': !!fieldError })}>
      <label>
        {label}
        {isRequired && <span className="required-annotation">*</span>}
      </label>

      {wysiwygOptions && (
        <div className="reset-this">
          <Controller
            name={fieldName}
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FroalaEditor
                  tag={tag}
                  config={wysiwygOptions}
                  model={value}
                  onModelChange={onChange}
                />
              );
            }}
            {...formFieldOptions}
          />
        </div>
      )}
      <FormFieldError error={fieldError} />
    </div>
  );
};

export default FormWysiwyg;
