import classNames from 'classnames';
import get from 'lodash/get';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import { useSubItemsLoader } from '../../shared/cms/hooks/useSubItemsLoader';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import CmsSubItemListSection from '../../shared/cmsPage/components/CmsSubItemListSection';
import ItemEditorModal from '../../shared/cmsPage/components/ItemEditorModal';
import { useEditPageTitle } from '../../shared/cmsPage/hooks/useEditPageTitle';
import useItemEditorModal from '../../shared/cmsPage/hooks/useItemEditorModal';
import useSubItemListActions from '../../shared/cmsPage/hooks/useSubItemListActions';
import ItemPublishStatus from '../../shared/itemTable/components/ItemPublishStatus';
import Timestamp from '../../shared/itemTable/components/Timestamp';
import { ItemTableColumn } from '../../shared/itemTable/definitions';
import useArray from '../../shared/util/hooks/useArray';
import menuApi from '../api/menuApi';
import menuItemApi from '../api/menuItemApi';
import { MenuConstants, MenuEditorConstants, MenuItem } from '../definitions';
import useMenuEditorActions from '../hooks/useMenuEditorActions';
import MenuEditorForm from './MenuEditorForm';

interface MenuEditorPageProps {
  menuId: string;
}

const MenuItemColumns: ItemTableColumn<MenuItem>[] = [
  {
    label: 'Title (Label)',
    valueField: 'label',
  },
  /*  {
      label: 'Target',
      valueField: 'referenceLabel'
    },*/
  {
    label: 'Status',
    valueField: 'status',
    renderer: (item) => <ItemPublishStatus status={item.status} />,
  },
  {
    label: 'Target Condition',
    valueField: 'condition',
    align: 'center',
    renderer: (item) => {
      const className = classNames('fa', 'fa-solid', { 'fa-bullseye': !!item.condition });
      return <span className={className} />;
    },
  },
  {
    label: 'Last Modified',
    valueField: 'lastUpdated',
    sortable: true,
    renderer: (item, field) => <Timestamp date={item[field] as Date} />,
  },
];

const MenuEditorPage: React.FC<MenuEditorPageProps> = (props: MenuEditorPageProps) => {
  const { menuId } = props;
  const [hasReordered, setHasReordered] = useState(false);
  const {
    add,
    edit,
    remove,
    restore,
    requestPending: itemActionRequestPending,
  } = useSubItemListActions(menuItemApi, menuId, MenuEditorConstants);

  const {
    item,
    loadItem,
    requestPending: loadItemPending,
    reorderItems,
  } = useItemPersistence(menuApi);
  const reorderItemsWrapper = useCallback(
    async (parentId: string, itemIds: string[]) => {
      setHasReordered(true);
      await reorderItems(parentId, itemIds);
    },
    [setHasReordered, reorderItems]
  );
  useEffect(() => {
    if (menuId) {
      (async () => await loadItem(menuId))();
    }
  }, [menuId, loadItem]);

  const { showItemEditorModal, editorModalProps } = useItemEditorModal(
    MenuConstants.typeName,
    'name',
    menuApi,
    MenuEditorForm
  );

  const emptyListMessage = useMemo(
    () =>
      item
        ? `There are currently no menu items for '${item.name}.'`
        : `There are currently no menu items.`,
    [item]
  );

  const { loadRecords } = menuItemApi;
  const { loadItems, records } = useSubItemsLoader(loadRecords);

  const requestPending = itemActionRequestPending || loadItemPending;
  const { pageTitle, onItemChange } = useEditPageTitle(menuId, 'Menu', 'name');
  useEffect(() => onItemChange(item), [item, onItemChange]);

  const hasItemsPendingPublish = records.some((r) => r && r.status && r.status !== 'published');
  const hasSettingsPendingPublish = item && item.status && item.status !== 'published';
  const publishDisabled = !hasSettingsPendingPublish && !hasItemsPendingPublish && !hasReordered;

  const itemName = get(item, 'name', 'this menu');
  const alert = {
    condition: !publishDisabled,
    message: `You have unpublished changes to ${itemName} which may include name, label and/or sort order. Select Publish Menu to begin the publish process.`,
    alertType: 'info',
  };

  const { publish, editSettings } = useMenuEditorActions({
    hasItemsPendingPublish,
    item,
    loadItem,
    loadItems,
    menuId,
    pageTitle,
    publishDisabled,
    setHasReordered,
    showItemEditorModal,
  });
  const toolbarActions = useArray(publish, editSettings, add);
  const itemActions = useArray(edit, remove, restore);

  return (
    <CmsPage title={pageTitle} requestPending={requestPending}>
      <CmsSubItemListSection
        parentItemId={menuId}
        parentPageTitle="Navigation"
        className="nav-manager"
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        reorder={reorderItemsWrapper}
        emptyListMessage={emptyListMessage}
        columns={MenuItemColumns}
        subItemApi={menuItemApi}
        pageConstants={MenuEditorConstants}
        alert={alert}
      />
      <ItemEditorModal {...editorModalProps} />
    </CmsPage>
  );
};

export default MenuEditorPage;
