(function () {
  'use strict';
  angular
    .module('root', ['wysiwyg-config-service', 'preview-service', 'audience-modal-service'])
    .controller('RootCtrl', [
      '$rootScope',
      '$scope',
      '$modal',
      '$q',
      '$timeout',
      '$state',
      '$location',
      'growl',
      'configService',
      'contentApiService',
      'instanceService',
      'pluginService',
      'wysiwygConfigService',
      'previewService',
      'internalLinkService',
      'audienceModalService',
      'importService',
      function (
        $rootScope,
        $scope,
        $modal,
        $q,
        $timeout,
        $state,
        $location,
        growl,
        configService,
        contentApiService,
        instanceService,
        pluginService,
        wysiwygConfigService,
        previewService,
        internalLinkService,
        audienceModalService,
        importService
      ) {
        $scope.navigateToState = $state.go;
        $scope.showAudienceModal = audienceModalService.showAudienceModal;
        $scope.notifyContentUpdated = _.debounce(() => {
          $rootScope.$broadcast('contentUpdated');
        }, 1000);
        $scope.showImportDialog = importService.showImportDialog;

        wysiwygConfigService.getWysiwygOptions().then(function (wysiwygOptions) {
          $rootScope.wysiwygOptions = wysiwygOptions;

          $timeout(() => {
            $scope.ready = true;
          });
        });

        configService.getConfig().then(function (config) {
          $scope.cmsConfig = config;
          $scope.tenants = config.accessibleTenants;

          $scope.showMultiTenant =
            $scope.tenants.length > 1 || Object.values($scope.tenants[0].subtenants).length > 1;
        });

        // Side bar support
        $scope.collapseSideBar = false;
        $scope.toggleSideBar = function () {
          $scope.collapseSideBar = !$scope.collapseSideBar;
        };

        $scope.launchPreview = previewService.launchPreview;

        $scope.showLinkSearch = (currentHref, allowEmbeddedMenus) => {
          return internalLinkService.showPopup(currentHref, 'normalizedLink', allowEmbeddedMenus);
        };

        window.onbeforeunload = function () {
          previewService.closePreviewWindow();
        };

        $rootScope.$on('defaultContentUpdated', previewService.reloadPreview);
        $rootScope.$on('contentUpdated', previewService.reloadPreview);
        $rootScope.$on('assetUpdated', previewService.reloadPreview);
        $rootScope.$on('dataUpdated', previewService.reloadPreview);
        $rootScope.$on('cmsInstanceChanged', function () {
          if (previewService.previewWindowIsValid()) {
            previewService.initPreviewWindow();
          }
        });
      },
    ])
    .controller('RootInitCtrl', [
      'navigationService',
      function (navigationService) {
        navigationService.navigateToFirstMenuItem();
      },
    ]);
})();
