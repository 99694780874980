import React, { useMemo } from 'react';
import { Menu } from '../definitions';
import { isSystemMenu } from '../menuUtils';

interface MenuNameProps {
  menu: Menu;
}

const MenuName: React.FC<MenuNameProps> = (props: MenuNameProps) => {
  const { menu } = props;
  const { name } = menu;

  const isSystem = useMemo(() => isSystemMenu(menu), [menu]);

  return (
    <>
      <span>{name}</span>
      {isSystem && <span className="value-detail">(System Menu)</span>}
    </>
  );
};

export default MenuName;
