angular
  .module('common-filters', [])
  .filter('orderObjectBy', [
    '$filter',
    function ($filter) {
      return function (items, field, reverse) {
        var filtered = [];
        for (var objectKey in items) {
          filtered.push(items[objectKey]);
        }

        filtered.sort(function (a, b) {
          var aValue = a[field] || '';
          var bValue = b[field] || '';
          if (aValue.localeCompare) {
            return aValue.localeCompare(bValue);
          } else {
            return aValue > bValue ? 1 : -1;
          }
        });

        if (reverse) filtered.reverse();
        return filtered;
      };
    },
  ])
  .filter('tel', function () {
    return function (tel) {
      if (!tel) {
        return '';
      }

      var value = tel.toString().trim().replace(/^\+/, '');

      if (value.match(/[^0-9]/)) {
        return tel;
      }

      var country, city, number;

      switch (value.length) {
        case 1:
        case 2:
        case 3:
          city = value;
          break;

        default:
          city = value.slice(0, 3);
          number = value.slice(3);
      }

      if (number) {
        if (number.length > 3) {
          number = number.slice(0, 3) + '-' + number.slice(3, 7);
        } else {
          number = number;
        }

        return ('(' + city + ') ' + number).trim();
      } else {
        return '(' + city;
      }
    };
  });
