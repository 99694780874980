import React from 'react';
import { CollectionRevision } from '../definitions';
import FormInput from '../../shared/form/components/FormInput';
import Select from '../../shared/form/components/Select';
import { ItemEditorForm, ItemEditorFormProps } from '../../shared/cmsPage/definitions';

type CollectionEditorFormProps = ItemEditorFormProps<CollectionRevision>;

const CollectionEditorForm: ItemEditorForm<CollectionRevision> = (
  props: CollectionEditorFormProps
) => {
  const { form } = props;

  return (
    <div className="row">
      <form role="form">
        <FormInput
          label="Name"
          fieldName="name"
          formContext={form}
          formFieldOptions={{ required: 'This field is required.' }}
        />
        <FormInput label="Title to Display" fieldName="label" formContext={form} />
        <Select
          label="Default View"
          options={[
            { label: 'List View', value: 'list' },
            { label: 'Grid View', value: 'grid' },
          ]}
          fieldWidth="short"
          formContext={form}
          fieldName="defaultView"
        />
      </form>
    </div>
  );
};

export default CollectionEditorForm;
