import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Item } from '../../form/definitions';
import { useSpinnerProvider } from '../../spinner/hooks/useSpinnerProvider';
import { CmsContext } from '../../../context/definitions';
import { useItemPersistence } from '../../cms/hooks/useItemPersistence';
import { useSpinner } from '../../spinner/hooks/useSpinner';
import { useCmsForm } from '../../form/hooks/useCmsForm';
import Modal from 'react-bootstrap/Modal';
import MultiSpinner from '../../spinner/components/MultiSpinner';
import GrowlContainer from '../../growl/components/GrowlContainer';
import Button from 'react-bootstrap/Button';
import { ItemApi } from '../../cms/definitions';
import { DeepPartial, FieldPath, UnpackNestedValue } from 'react-hook-form';
import { ItemEditorForm } from '../definitions';

export interface ItemEditorModalProps<I extends Item, R extends Item = I> {
  typeName: string;
  labelField: FieldPath<R>;
  itemApi: ItemApi<I, R>;
  FormComponent: ItemEditorForm<R>;
  visible: boolean;
  item?: R;
  onClose?: (item?: R) => void;
}

const ItemEditorModal = <I extends Item, R extends Item = I>(props: ItemEditorModalProps<I, R>) => {
  const { typeName, item, visible, onClose, FormComponent, itemApi, labelField } = props;
  const [show, setShow] = useState(false);
  const [growlId, setGrowlId] = useState('');

  useEffect(() => {
    setGrowlId(`${Date.now()}`);
  }, []);

  useEffect(() => {
    setShow(visible);
  }, [visible]);

  const { spinnerProvider, spinners } = useSpinnerProvider();
  const { growlProvider } = useContext(CmsContext);
  const { error } = growlProvider;

  const form = useCmsForm<R>(item as UnpackNestedValue<DeepPartial<R>>);
  const { handleSubmit, formState } = form;
  const { isValid, isDirty } = formState;

  const { saveItem, requestPending } = useItemPersistence(itemApi);
  useSpinner(spinnerProvider, requestPending);

  const closeHandler = useCallback(
    (savedItem?: R) => {
      if (onClose) {
        onClose(savedItem);
      }

      setShow(false);
    },
    [onClose]
  );

  const onSave = useCallback(
    async (item) => {
      try {
        const savedItem = await saveItem(item);
        closeHandler(savedItem);
      } catch (e) {
        error(growlId, e.message);
      }
    },
    [saveItem, error, closeHandler]
  );

  const title = useMemo(() => {
    const label = item && item[labelField];
    return label ? `${typeName} Settings for ${label}` : `${typeName} Settings`;
  }, [item, typeName]);

  const allowSubmit = isDirty && isValid;

  useSpinner(spinnerProvider, requestPending);

  return (
    <Modal show={show} animation={false} backdrop="static">
      <Modal.Header>
        <Modal.Title>
          {title}
          <span className="page-spinner">
            <MultiSpinner spinners={spinners} />
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GrowlContainer groupId={growlId} />
        <FormComponent form={form} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" disabled={!allowSubmit} onClick={handleSubmit(onSave)}>
          Save
        </Button>
        <Button variant="cancel" onClick={() => closeHandler(null)}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ItemEditorModal;
