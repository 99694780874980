import { useCallback, useState } from 'react';
import { ItemEditorForm } from '../definitions';
import { ItemApi } from '../../cms/definitions';
import { ItemEditorModalProps } from '../components/ItemEditorModal';
import { Item } from '../../form/definitions';
import { FieldPath } from 'react-hook-form';

const useItemEditorModal = <I extends Item, R = I>(
  typeName: string,
  labelField: FieldPath<R>,
  itemApi: ItemApi<I, R>,
  FormComponent: ItemEditorForm<R>
) => {
  const defaultProps = {
    typeName,
    labelField,
    visible: false,
    itemApi,
    FormComponent,
  } as ItemEditorModalProps<I, R>;

  const [editorModalProps, setEditorModalProps] = useState(defaultProps);

  const showItemEditorModal = useCallback(
    (item: R) => {
      return new Promise<R>((resolve) => {
        setEditorModalProps((prevState) => ({
          ...prevState,
          visible: true,
          item,
          onClose: (item: R) => {
            setEditorModalProps(defaultProps);
            resolve(item);
          },
        }));
      });
    },
    [defaultProps, setEditorModalProps]
  );

  return {
    showItemEditorModal,
    editorModalProps,
  };
};

export default useItemEditorModal;
