import React from 'react';
import FormInput from '../../shared/form/components/FormInput';
import Select from '../../shared/form/components/Select';
import { ItemEditorForm, ItemEditorFormProps } from '../../shared/cmsPage/definitions';
import { Cohort } from '../definitions';

type CohortEditorFormProps = ItemEditorFormProps<Cohort>;

const CohortEditorForm: ItemEditorForm<Cohort> = (props: CohortEditorFormProps) => {
  const { form } = props;

  return (
    <div className="row">
      <form role="form">
        <FormInput
          label="Name"
          fieldName="name"
          formContext={form}
          formFieldOptions={{ required: 'This field is required.' }}
        />
        <FormInput
          label="Credentials Prompt"
          fieldName="credentialsPromptText"
          formContext={form}
          placeholder="Please enter your invitation code."
        />
        <Select
          label="Credentials Entry"
          options={[
            { label: 'Manual Entry', value: 'manual' },
            { label: 'Choose From List', value: 'list' },
          ]}
          formContext={form}
          fieldName="credentialsEntry"
        />
      </form>
    </div>
  );
};

export default CohortEditorForm;
