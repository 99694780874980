import { CohortMember } from '../definitions';
import axios from 'axios';
import { ItemApi, ListLoaderParams, ListLoaderResult } from '../../shared/cms/definitions';

const baseUrl = '/plugins/cohorts/api';

const loadRecordsWrapper = (cohortId: string, credentialCode: string) => async (params: ListLoaderParams<CohortMember>) => {
  const { sort, ...otherParams } = params;
  const { sortField, order } = sort;

  const sortReverse = sortField ? order === 'DESC' : undefined;
  let result: ListLoaderResult<CohortMember>;

  try {
    const { data } = await axios.get(`${baseUrl}/getCohortExplictMembers`, {
      params: {
        cohortId,
        credentialCode,
        ...otherParams,
        sortField,
        sortReverse,
      },
      timeout: 10000,
    });

    const { cohortMembers, count, offset } = data;
    result = { records: cohortMembers, numRecords: count, offset };
  } catch (e) {
    console.error(e);
    throw new Error('Failed to load cohort members');
  }

  return result;
};

const generateCohortMemberMatchesApi = (cohortId: string, credentialCode: string): ItemApi<CohortMember> => {
  return {
    loadRecords: loadRecordsWrapper(cohortId, credentialCode),
  };
};

export default generateCohortMemberMatchesApi;
