import axios from 'axios';
import assign from 'lodash/assign';
export const getFileAssetPreview = async (assetId: string) => {
  const { data } = await axios.get(`/preview/asset/${assetId}`, {
    params: {
      instanceName: 'live',
      reload: Date.now(),
    },
    responseType: 'blob',
  });
  return data
    ? assign(new File([data], assetId, { type: data.type }), {
        preview: URL.createObjectURL(data),
        path: assetId,
      })
    : null;
};
