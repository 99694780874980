import { useCallback } from 'react';
import { getFileAssetPreview } from '../../../../../shared/api';
import { getLocalDefaultTileImage, updateFile } from './helpers';
import { actions } from './reducer';
import { getCroppedImg } from './helpers';

const { SET_FILES, SET_SELECTED_FILE, SET_INITIAL_ASSETS, SET_CROPPED_IMAGE } = actions;

export default function useDispatchFunctions(
  setFormValue: any,
  dispatch: any,
  defaultAssetId: any,
  useCropper: boolean
) {
  const setSelectedFilePath = useCallback(
    (selectedFilePath: any) => {
      dispatch({ type: SET_SELECTED_FILE, payload: { selectedFilePath, setFormValue } });
    },
    [dispatch, SET_SELECTED_FILE, setFormValue]
  );

  const setFiles = useCallback(
    (acceptedFiles = [], rejectedFiles = []) => {
      dispatch({
        type: SET_FILES,
        payload: { rejectedFiles, acceptedFiles, setFormValue, useCropper },
      });
    },
    [dispatch, SET_FILES, setFormValue, useCropper]
  );

  const getCurrentAsset = useCallback(
    async (assetId) => {
      let file;
      if (assetId && assetId !== 'default' && assetId !== defaultAssetId) {
        try {
          file = await getFileAssetPreview(assetId);
          if (file) {
            file = updateFile(file, {
              path: 'CURRENT_FILE',
              name: `CURRENT_FILE-${assetId}`,
            });
          }
        } catch (e) {
          console.log('Error getting asset', e);
        }
      }
      return file;
    },

    [getFileAssetPreview, updateFile, defaultAssetId]
  );

  const getDefaultAsset = useCallback(
    async (defaultAssetId) => {
      let file;
      if (defaultAssetId) {
        try {
          file = await getFileAssetPreview(defaultAssetId);
          if (file) {
            file = updateFile(file, {
              name: 'DEFAULT_FILE',
              path: 'DEFAULT_FILE',
            });
          }
        } catch (e) {
          console.info('No default asset found getting asset');
        }
      }
      if (!file) file = getLocalDefaultTileImage();
      return file;
    },
    [getFileAssetPreview, getLocalDefaultTileImage, updateFile]
  );
  const initializeAssets = useCallback(
    async (defaultAssetId, assetId) => {
      const defaultFile = await getDefaultAsset(defaultAssetId);
      const currentFile = await getCurrentAsset(assetId);
      setFormValue(currentFile ? currentFile : defaultFile, false);
      dispatch({
        type: SET_INITIAL_ASSETS,
        payload: {
          defaultFile,
          currentFile,
          path: currentFile ? 'CURRENT_FILE' : 'DEFAULT_FILE',
          useCropper,
        },
      });
    },
    [getCurrentAsset, getDefaultAsset, dispatch, setFormValue, useCropper]
  );

  const setCroppedImage = useCallback(
    async (src, croppedAreaPixes: any, rotation = 0) => {
      const croppedImage = await getCroppedImg(src, croppedAreaPixes, rotation);
      dispatch({
        type: SET_CROPPED_IMAGE,
        payload: { croppedImage, setFormValue },
      });
    },
    [getCroppedImg, SET_CROPPED_IMAGE, setFormValue]
  );

  return { initializeAssets, setCroppedImage, setFiles, setSelectedFilePath };
}
