import { useContext, useMemo } from 'react';
import { CmsContext } from '../../context/definitions';
import { BackPageAction } from '../../shared/cmsPage/definitions';

interface UseCohortMatchesActionsProps {
  pluginId: string;
  parentPageTitle: string;
  parentPageName: string;
  parentPageId: string;
  parentPageArgs?: string[];
}

export default function useCohortMatchesActions(props: UseCohortMatchesActionsProps) {
  const { pluginId, parentPageTitle, parentPageName, parentPageId, parentPageArgs } = props;

  const { navigator } = useContext(CmsContext);

  const back = useMemo(
    (): BackPageAction => ({
      pageName: parentPageTitle,
      onClick: () => {
        navigator.navigateToParentPage(
          pluginId,
          parentPageName,
          parentPageId,
          ...(parentPageArgs || [])
        );
      },
    }),
    [navigator, parentPageTitle, pluginId, parentPageName, parentPageId, parentPageArgs]
  );

  return { back };
}
