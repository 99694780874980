import { ConditionExpr, ItemApi, PublishStatus } from '../shared/cms/definitions';
import { PageConstants } from '../shared/cmsPage/definitions';
import { Item } from '../shared/form/definitions';
import createPageConstants from '../shared/util/createPageConstants';
const pluginId = 'collections';

export const CollectionConstants: PageConstants = createPageConstants(pluginId, 'Collection', {
  defaultDefault: 'collectionListPage',
  editor: 'collectionEditorPage',
  publish: 'collectionItemPublishPage',
});

export const CollectionEditorConstants: PageConstants = createPageConstants(
  pluginId,
  'Collection Item',
  {
    default: 'collectionEditorPage',
    editor: 'collectionItemEditorPage',
    parentDefault: 'collectionListPage',
    publish: 'collectionItemPublishPage',
  }
);

export const CollectionPublishPageConstants: PageConstants = createPageConstants(
  pluginId,
  'Collection',
  {
    default: 'collectionItemPublishPage',
  }
);

export interface CollectionRevision extends Item {
  id?: string;
  collection_id?: string;
  name: string;
  label: string;
}

export interface Collection extends Item {
  id: string;
  name: string;
  label: string;
  lastUpdated: Date;
  status: PublishStatus;
  sandboxRevision?: CollectionRevision;
  liveRevision?: CollectionRevision;
}

export const EMPTY_COLLECTION_REVISION: CollectionRevision = {
  name: '',
  label: '',
};

export interface CollectionItemRevision extends Item {
  collection_id?: string;
  id?: string;
  collection_item_id?: string;
  label: string;
  objectReference?: string;
  condition?: ConditionExpr;
  referenceLabel?: string;
  actionButtonText?: string;
  infoButtonText?: string;
  infoText?: string;
  tileAssetId?: string;
}

export interface CollectionItem extends Item {
  id: string;
  lastUpdated: Date;
  label: string;
  objectReference: string;
  status: PublishStatus;
  position: number;
  condition?: ConditionExpr;
  actionButtonText?: string;
  infoButtonText?: string;
  infoText?: string;
  tileAssetId?: string;
  referenceLabel?: string;
  sandboxRevisionId?: string;
  liveRevisionId?: string;
}

export const EMPTY_COLLECTION_ITEM_REVISION: CollectionItemRevision = {
  label: '',
};

export type CollectionApi = ItemApi<Collection, CollectionRevision>;
