import { useContext, useMemo } from 'react';
import { CmsContext } from '../../context/definitions';
import {
  BackPageAction,
  PageConstants,
  WrappedActionParams,
} from '../../shared/cmsPage/definitions';
import { Action, ContextAction } from '../../shared/definitions';
import { Item } from '../../shared/form/definitions';
export default function useSurveyActions<T extends Item>(
  surveyId: string,
  pageConstants: PageConstants
) {
  const { navigator } = useContext(CmsContext);
  const { pluginId, editorPageName, defaultPageId, typeName } = pageConstants;
  const add = useMemo(
    (): Action<WrappedActionParams<T>> => ({
      label: `+ Add ${typeName}`,
      onClick: ({ loaderParams }) => {
        navigator.navigateToChildPage(
          pluginId,
          editorPageName,
          defaultPageId,
          loaderParams,
          surveyId,
          'new'
        );
      },
    }),
    [typeName, navigator, pluginId, defaultPageId, editorPageName, surveyId]
  );

  const edit = useMemo(
    (): ContextAction<T, WrappedActionParams<T>> => ({
      icon: 'fa-solid fa-pencil',
      label: 'Edit',
      disabled: (context: T) => context.status === 'removed',
      onClick: (context: T, { loaderParams }) => {
        navigator.navigateToChildPage(
          pluginId,
          editorPageName,
          defaultPageId,
          loaderParams,
          surveyId,
          context.id
        );
      },
    }),
    [navigator, pluginId, defaultPageId, editorPageName, surveyId]
  );

  const backToSurveys = useMemo(
    (): BackPageAction => ({
      pageName: 'Surveys',
      onClick: () => {
        navigator.navigateToChildPage(pluginId, 'default', 'surveys-list', {
          search: '',
          limit: 25,
          offset: 0,
          sort: { sortField: 'title', order: 'ASC' },
        });
      },
    }),
    [navigator, pluginId]
  );

  return { backToSurveys, add, edit };
}
