import { useCallback, useState } from 'react';
import { Item } from '../../form/definitions';

export const usePublishPageTitle = <I extends Item>(
  id: string,
  typeName: string,
  labelField: keyof I
) => {
  const [pageTitle, setPageTitle] = useState('');

  const onItemChange = useCallback(
    (item: I) => {
      const itemName = item && item[labelField];
      const newTitle = itemName ? `Publish '${itemName}'` : `Publish ${typeName}`;

      setPageTitle(newTitle);
    },
    [id, typeName, labelField, setPageTitle]
  );

  return {
    pageTitle,
    onItemChange,
  };
};
